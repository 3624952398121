import { useCallback } from "react";
import { Formik, FormikProps } from "formik";
import { Row, Col } from "antd";
import { Form, Input, SubmitButton } from "formik-antd";
import { useTranslation } from "react-i18next";
import { Setting as SettingType } from "/app/src/models";
import { settingService } from "/app/src/services";
import { settingSchema } from "/app/src/schemas/settingsSchema";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { buildParams } from "/app/src/helpers";
import { handlePromiseError } from "/app/src/helpers/api";

/**
 * Component to display the forms for updating the URL
 */
export default function Url() {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  //useQuery for getting the ItemPath URL
  const { data: urlSetting } = useQuery({
    queryKey: ["urlSetting"],
    queryFn: () => {
      return settingService
        .getAll(buildParams({ number: 101 }))
        .then((response) => {
          return response.settings[0];
        });
    },
    initialData: { value: "" },
    select: (data: SettingType) => {
      return data;
    },
  });

  //useMutation for updating the ItemPath URL
  const { mutateAsync: updateURL } = useMutation({
    mutationFn: (updateURL: SettingType) => {
      return settingService
        .updateSingle(updateURL.id, { value: updateURL.value })
        .then(handlePromiseError);
    },
    onSuccess: (data) => {
      queryClient.setQueryData(["urlSetting"], data.setting);
    },
  });

  //useMutation for creating the ItemPath URL
  const { mutateAsync: createURL } = useMutation({
    mutationFn: (createURL: SettingType) => {
      return settingService
        .createSingle({
          value: createURL.value,
          number: 101,
          name: "URL",
        })
        .then(handlePromiseError);
    },
    onSuccess: (data) => {
      queryClient.setQueryData(["urlSetting"], data.setting);
    },
  });

  //onSubmit handler for creating/updating the ItemPath URL
  const onSubmitURL = useCallback(
    async (
      {
        value,
      }: {
        value: string;
      },
      {
        setFieldError,
      }: { setFieldError: (field: string, value: string) => void },
    ): Promise<void> => {
      if (urlSetting?.id) {
        await updateURL({ id: urlSetting.id, value }).catch((error) => {
          return setFieldError("value", error.errors[0]);
        });
      } else {
        await createURL({ value }).catch((error) => {
          return setFieldError("value", error.errors[0]);
        });
      }
    },
    [createURL, updateURL, urlSetting.id],
  );

  /**
   * Update URL form
   */
  const updateURLForm: (
    props: FormikProps<{ value: string | undefined }>,
  ) => JSX.Element = useCallback(
    ({ dirty }) => (
      <Form layout="vertical">
        <Row justify="start" gutter={16}>
          <Col span={8}>
            <h3>{`${t("translation:itempath")} ${t("translation:url")}`}</h3>
            <p>{t("translation:url_description")}</p>
          </Col>
          <Col span={13}>
            <Form.Item
              className="no-label" // skipcq: JS-0394
              name="value"
            >
              <Input suffix name="value" size="large" />
            </Form.Item>
          </Col>
          <Col span={3}>
            <SubmitButton
              className="no-label" // skipcq: JS-0394
              type="primary"
              size="large"
              block
              disabled={!dirty}
            >
              {t("translation:save")}
            </SubmitButton>
          </Col>
        </Row>
      </Form>
    ),
    [t],
  );
  return (
    <Formik
      component={updateURLForm}
      enableReinitialize
      initialValues={{
        value: urlSetting?.value,
      }}
      validationSchema={settingSchema}
      onSubmit={onSubmitURL}
    />
  );
}
