import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useStatusContext } from "/app/src/contexts/hooks/useStatusContext";
import { useLocation, useNavigate } from "react-router-dom";
import { StatusType } from "/app/src/contexts/types";
import { useQueryClient } from "@tanstack/react-query";

/**
 * Component to display the status of the ItemPath connection in the header
 */
export default function ConnectionHeaderStatus() {
  const { connected } = useStatusContext();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const location = useLocation();

  useEffect(() => {
    if (!connected) {
      queryClient.setQueryData(["status"], (oldData: StatusType) => {
        return {
          ...oldData,
          message: "restarting",
        };
      });
    }
  }, [connected, queryClient]);

  useEffect(() => {
    let timer;

    if (!connected) {
      timer = setTimeout(() => {
        navigate("/no-connection", { state: { from: location.pathname } });
      }, 30000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [connected, location.pathname, navigate]);

  if (!connected)
    return (
      <div className="statusMessage warning">
        {t("translation:itempath_api_connection_failed")}
      </div>
    );
}
