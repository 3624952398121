import { useCallback } from "react";
import { useFormikContext } from "formik";
import { Warehouse } from "/app/src/models";
import { Form, Input, Select, SubmitButton } from "formik-antd";
import { useTranslation } from "react-i18next";
import { Col, DatePicker, Row } from "antd";
import { SearchFilter } from "/app/src/components/generic/components/searchFilter";
import { genericMapper } from "../../../../generic/formatting/generic";

interface FormValues {
  name: string | undefined;
  type: "Put" | "Pick" | undefined;
  priority: "High" | "Medium" | "Low" | "Hot" | undefined;
  deadline: string | undefined;
  warehouseName: string | undefined;
  Info1: string | undefined;
  Info2: string | undefined;
}

/**
 *
 * @param param0 warehouses
 * @returns OrderHeaderForm
 */
export default function OrderHeaderForm({
  warehouses,
}: {
  warehouses: Warehouse[];
}) {
  const { setFieldValue, setFieldError, dirty, isValid } =
    useFormikContext<FormValues>();
  const { t } = useTranslation();

  const onChangeError = useCallback(() => {
    setFieldError("name", undefined);
  }, [setFieldError]);

  const onChangeDeadline = useCallback(
    (e) => {
      setFieldValue("deadline", e);
    },
    [setFieldValue],
  );

  return (
    <Form layout="vertical">
      <h3>{t("translation:order_details")}</h3>
      <Row justify="start" gutter={16}>
        <Col span={24}>
          <Form.Item name="name" label={t("translation:name")}>
            <Input
              suffix
              name="name"
              className="name"
              placeholder={t("translation:order_name_number")}
              size="large"
              onChange={onChangeError}
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item name="type" label={t("translation:type")}>
            <Select name="type" size="large">
              <Select.Option key="1" value="Put">
                {t("translation:put")}
              </Select.Option>
              <Select.Option key="2" value="Pick">
                {t("translation:pick")}
              </Select.Option>
            </Select>
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item name="priority" label={t("translation:priority")}>
            <Select name="priority" size="large">
              <Select.Option key="1" value="Low">
                {t("translation:low")}
              </Select.Option>
              <Select.Option key="2" value="Medium">
                {t("translation:medium")}
              </Select.Option>
              <Select.Option key="3" value="High">
                {t("translation:high")}
              </Select.Option>
              <Select.Option key="4" value="Hot">
                {t("translation:hot")}
              </Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="deadline" label={t("translation:deadline")}>
            <DatePicker
              size="large"
              name="deadline"
              showTime
              onChange={onChangeDeadline}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="warehouseName"
            label={t("translation:warehouse_name")}
          >
            <SearchFilter
              list={warehouses}
              name="warehouseName"
              placeholder={t("translation:select_warehouse")}
              sort
              mapOptionsFn={genericMapper}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name="Info1" label={t("translation:info1")}>
            <Input
              suffix
              name="Info1"
              placeholder={t("translation:description")}
              size="large"
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name="Info2" label={t("translation:info2")}>
            <Input
              suffix
              name="Info2"
              placeholder={t("translation:description")}
              size="large"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="start" gutter={16}>
        <Col span={24}>
          <SubmitButton
            type="primary"
            size="large"
            block
            disabled={!(dirty || isValid)}
          >
            {t("translation:create")} {t("translation:order")}
          </SubmitButton>
        </Col>
      </Row>
    </Form>
  );
}
