import React from "react";
import { Modal } from "@nextui-org/react";

interface NextModalProps {
  children?: React.ReactNode;
  size?:
    | "xs"
    | "sm"
    | "md"
    | "lg"
    | "xl"
    | "2xl"
    | "3xl"
    | "4xl"
    | "5xl"
    | "full";
  radius?: "none" | "sm" | "md" | "lg";
  shadow?: "sm" | "md" | "lg";
  backdrop?: "transparent" | "opaque" | "blur";
  scrollBehavior?: "normal" | "inside" | "outside";
  placement?: "auto" | "top" | "center" | "bottom";
  isOpen?: boolean;
  defaultOpen?: boolean;
  isDismissable?: boolean;
  isKeyboardDismissDisabled?: boolean;
  shouldBlockScroll?: boolean;
  hideCloseButton?: boolean;
  closeButton?: React.ReactNode;
  motionProps?: object;
  portalContainer?: HTMLElement;
  disableAnimation?: boolean;
  classNames?: { [key: string]: string };
  className?: string;
  onOpenChange?: (isOpen: boolean) => void;
  onClose?: () => void;
}

const NextModal: React.FC<NextModalProps> = ({
  children,
  size = "md",
  radius = "lg",
  shadow = "lg",
  backdrop = "opaque",
  scrollBehavior = "normal",
  placement = "auto",
  isOpen,
  defaultOpen,
  isDismissable = true,
  isKeyboardDismissDisabled = false,
  shouldBlockScroll = true,
  hideCloseButton = false,
  closeButton,
  motionProps,
  portalContainer = document.body,
  disableAnimation = true,
  classNames,
  className,
  onOpenChange,
  onClose,
}) => {
  return (
    <Modal
      size={size}
      radius={radius}
      shadow={shadow}
      backdrop={backdrop}
      scrollBehavior={scrollBehavior}
      placement={placement}
      isOpen={isOpen}
      defaultOpen={defaultOpen}
      isDismissable={isDismissable}
      isKeyboardDismissDisabled={isKeyboardDismissDisabled}
      shouldBlockScroll={shouldBlockScroll}
      hideCloseButton={hideCloseButton}
      closeButton={closeButton}
      motionProps={motionProps}
      portalContainer={portalContainer}
      disableAnimation={disableAnimation}
      classNames={classNames}
      className={className}
      onOpenChange={onOpenChange}
      onClose={onClose}
    >
      {children}
    </Modal>
  );
};

export default NextModal;
