import { Formik, FormikProps } from "formik";
import { Integration } from "/app/src/models";
import { useCallback } from "react";
import { Form, Input, Select, SubmitButton } from "formik-antd";
import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import NextButton from "/app/src/components/NextUi/Button";
import { IconBuilder } from "/app/src/components/icons/IconBuilder";
import { Delete, ArrowRight } from "/app/src/components/icons/icons";
import { useToggle } from "/app/src/hooks";

interface SQLFilteringFormValues {
  key: string;
  operator: string;
  value: string;
}

/**
 *
 * @param param0 integration
 * @returns SQL Filtering Setting component for the integration
 */
export default function SQLFiltering({
  integration,
}: {
  integration: Integration;
}) {
  const { t } = useTranslation();
  const [toggled, setToggled] = useToggle();

  const onSubmit = useCallback((values: SQLFilteringFormValues) => {
    console.log(values);
  }, []);

  const updateSQLFilteringForm: (
    props: FormikProps<SQLFilteringFormValues>,
  ) => JSX.Element = useCallback(
    ({ dirty, isSubmitting }) => (
      <Form layout="vertical">
        <Row className="pb-3">
          <Col className="text-lg font-semibold" span={8}>
            {t("translation:sql_filtering")}
          </Col>
        </Row>
        <Row justify="start" gutter={16} className="flex items-center">
          <Col span={8}>
            <Form.Item name="key" label="Key" hasFeedback={false}>
              <Input name="key" size="large" />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="operator" label=" " hasFeedback={false}>
              <Select name="operator" size="large">
                <Select.Option value="=">
                  {t("translation:equals")}
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="value" label="Value" hasFeedback={false}>
              <Input name="value" size="large" />
            </Form.Item>
          </Col>
          <Col span={3}>
            <SubmitButton
              className="mt-[5px]"
              disabled={!dirty || isSubmitting}
              loading={isSubmitting}
              type="primary"
              size="large"
              block
            >
              {t("translation:save")}
            </SubmitButton>
          </Col>
          <Col span={1}>
            <NextButton
              size="md"
              variant="bordered"
              color="default"
              isIconOnly
              className="hover:border-red-500 hover:text-blue-500 bg-white mt-[5px]"
            >
              <IconBuilder size={18} color="#e00000" icon={Delete} />
            </NextButton>
          </Col>
        </Row>
      </Form>
    ),
    [t],
  );

  const createSQLFilteringForm: (
    props: FormikProps<SQLFilteringFormValues>,
  ) => JSX.Element = useCallback(
    ({ dirty, isSubmitting }) => (
      <Form layout="vertical">
        <Row justify="start" gutter={16} className="flex items-center ">
          <Col span={8}>
            <Form.Item name="key" label="Key" hasFeedback={false}>
              <Input name="key" size="large" />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="operator" label=" " hasFeedback={false}>
              <Select name="operator" size="large">
                <Select.Option value="=">
                  {t("translation:equals")}
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="value" label="Value" hasFeedback={false}>
              <Input name="value" size="large" />
            </Form.Item>
          </Col>
          <Col span={4}>
            <SubmitButton
              className="mt-[5px]"
              disabled={!dirty || isSubmitting}
              loading={isSubmitting}
              type="primary"
              size="large"
              block
            >
              {t("translation:save")}
            </SubmitButton>
          </Col>
        </Row>
      </Form>
    ),
    [t],
  );

  return (
    <>
      <Formik
        component={updateSQLFilteringForm}
        initialValues={{
          key: "",
          operator: "",
          value: "",
        }}
        onSubmit={onSubmit}
      />
      {toggled && (
        <Formik
          component={createSQLFilteringForm}
          initialValues={{
            key: "",
            operator: "",
            value: "",
          }}
          onSubmit={onSubmit}
        />
      )}
      <NextButton
        variant={!toggled ? "solid" : "bordered"}
        color="primary"
        onClick={setToggled}
      >
        {!toggled ? (
          <span className="flex justify-between items-center">
            {t("translation:new_filter")}
            <IconBuilder
              className="ml-1"
              icon={ArrowRight}
              color="#ffffff"
              size={16}
            />
          </span>
        ) : (
          t("translation:cancel")
        )}
      </NextButton>
    </>
  );
}
