import { Select } from "antd";
import { useFormikContext } from "formik";
import { useCallback, useState } from "react";
import { Mapping } from "/app/src/models";
import { useTranslation } from "react-i18next";

interface FormValues {
  value: string | undefined;
}

/**
 * Component returning ANTD Select component for updating the value of the path setting
 */
export default function DynamicMappedPath({
  mappings,
}: {
  mappings: Mapping[];
}) {
  const { setFieldValue, values } = useFormikContext<FormValues>();
  const { t } = useTranslation();
  const [selectValue, setSelectValue] = useState<string | undefined>(undefined);
  /**
   * Function to add mapping key to the value
   */
  const onChange = useCallback(
    (value) => {
      const currentValue = values.value ?? "";
      setFieldValue("value", `${currentValue}{${value}}`);
      setSelectValue(undefined);
    },
    [setFieldValue, values.value],
  );

  return (
    <div>
      <Select
        value={selectValue}
        onChange={onChange}
        style={{ width: "100%" }}
        size="large"
        placeholder={t("translation:dynamic_mapping_path_placeholder")}
      >
        {mappings.map((mapping) => (
          <Select.Option key={mapping.id} value={mapping.key}>
            {mapping.key}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
}
