import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { logService, taskService } from "/app/src/services";
import getOrderByQuery from "/app/src/helpers/table";

import DateTime from "/app/src/components/generic/formatting/dateTime";
import { Integration, Task as TaskType, Log as LogType } from "/app/src/models";
import { buildParams } from "/app/src/helpers/params";
import { useNumberId } from "/app/src/hooks/useNumberId";
import { createColumnHelper } from "@tanstack/react-table";
import TableUpgrade from "/app/src/components/generic/tables/tableUpgrade";
import { useSortUpgrade } from "/app/src/hooks/useSortUpgrade";
import Box from "/app/src/components/generic/components/box";

export default function Logs({ integration }: { integration: Integration }) {
  const { t } = useTranslation();
  const { taskId } = useNumberId();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [sort, setSort] = useSortUpgrade([]);

  const columnHelper = createColumnHelper<LogType>();
  const columns = useMemo(
    () => [
      columnHelper.accessor("description", {
        id: "description",
        cell: (info) => info.getValue(),
        header: t("translation:description"),
      }),
      columnHelper.accessor("error", {
        id: "error",
        cell: (info) => info.getValue(),
        header: t("translation:errors"),
      }),
      columnHelper.accessor("creationDate", {
        id: "creationDate",
        cell: (info) => <DateTime date={info.getValue()} />,
        header: t("translation:date"),
      }),
    ],
    [columnHelper, t],
  );

  //Get integration
  const { data: task } = useQuery({
    queryKey: ["task", taskId],
    queryFn: () => {
      return taskService.getSingle(Number(taskId));
    },
    initialData: { task: {} },
    select: (data: { task: TaskType }) => ({
      task: data.task,
    }),
  });

  // Get number of logs
  const { data: logsCount } = useQuery({
    queryKey: ["logsCount", taskId],
    queryFn: () => {
      return logService.getCount(buildParams({ taskId }));
    },
    initialData: { count: 0 },
    select: (data: { count: number }) => {
      return data.count;
    },
  });

  const { data: logs, isFetching } = useQuery({
    queryKey: ["logs", taskId, page, pageSize, sort, task],
    queryFn: () => {
      return logService.getAll(
        buildParams({
          limit: pageSize,
          page,
          taskId,
          orderby: getOrderByQuery(sort),
        }),
      );
    },
    initialData: { logs: [] },
    select: (data: { logs: LogType[] }) => {
      return data.logs;
    },
  });

  return (
    <div className="logs">
      <Box>
        <h1>
          <Link
            to={`/apps/${integration.appId}/integrations/${integration.id}/tasks`}
          >
            {t("translation:logs")}
          </Link>{" "}
          / {t("translation:log")}
        </h1>
      </Box>
      <TableUpgrade
        loading={isFetching}
        rows={logs}
        tableColumns={columns}
        length={logsCount}
        sort={sort}
        setSort={setSort}
        paginationEnabled={{
          currentPage: page,
          pageSize,
          setPage,
          setPageSize,
        }}
        enableRowSelection
        emptyText={t("translation:no_tasks_found")}
        rowClicked={() => {}}
      />
    </div>
  );
}
