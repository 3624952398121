import React from "react";
import { Helmet } from "react-helmet";

import "antd/dist/antd.min.css";

import { StatusProvider } from "./contexts/StatusContext";
import { ConnectionsStatusProvider } from "./contexts/ConnectionsStatusContext";
import { TimeZoneProvider } from "./contexts/TimeZoneContext";
import { LicenseProvider } from "./contexts/LicenseContext";
import { PermissionProvider } from "./contexts/PermissionContext";
import "./stylesheets/index.scss";
import Header from "./components/header";
import SystemTypeIndicator from "./components/header/systemTypeIndicator";
import StatusMessage from "./components/header/statusMessage";
import Footer from "./components/footer";
import Routes from "./routes";
import AppBodyHook from "./appBodyHook";
import ConnectionHeaderStatus from "./components/header/connectionHeaderStatus";
import NewVersionStatus from "./components/header/newVersionStatus";
/**
 * App Body conatins status, connection status, and timezone providers, connectionTest,
 * StatusMessage, SystemTypeIndicator, Header, Routes, and Footer
 * @returns The main app body
 */
export default function AppBody() {
  const {
    statusQuery,
    powerpickConnectionQuery,
    timeZone,
    permissionsQuery,
    license,
    versionChanged,
  } = AppBodyHook();
  return (
    <StatusProvider value={{ ...statusQuery.data }}>
      <ConnectionsStatusProvider value={powerpickConnectionQuery.data}>
        <TimeZoneProvider value={timeZone}>
          <LicenseProvider value={license}>
            <Helmet>
              <meta charSet="utf-8" />
              <title>ItemPath</title>
            </Helmet>
            {!statusQuery.data.loading && (
              <>
                <NewVersionStatus versionChanged={versionChanged} />
                <ConnectionHeaderStatus />
                <StatusMessage />
                <SystemTypeIndicator />
                <div className="itemPath ">
                  <Header />
                  <PermissionProvider
                    value={{
                      isLoading: permissionsQuery.isFetching,
                      ...permissionsQuery.data,
                    }}
                  >
                    <Routes />
                  </PermissionProvider>
                  <Footer />
                </div>
              </>
            )}
          </LicenseProvider>
        </TimeZoneProvider>
      </ConnectionsStatusProvider>
    </StatusProvider>
  );
}
