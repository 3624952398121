import { useCallback } from "react";
import { Formik, FormikProps } from "formik";
import { Row, Col } from "antd";
import { Form, SubmitButton } from "formik-antd";
import { useTranslation } from "react-i18next";
import { settingService, warehouseService } from "/app/src/services";
import { Warehouse } from "/app/src/models";
import { SearchFilter } from "/app/src/components/generic/components/searchFilter";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { buildParams } from "/app/src/helpers";
import { genericMapper } from "../../generic/formatting/generic";

/**
 * Component to display the forms for updating the default warehouse
 */
export default function DefaultWarehouse() {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  //useQuery for getting the default warehouse
  const { data: defaultWarehouse } = useQuery({
    queryKey: ["defaultWarehouse"],
    queryFn: () => {
      return settingService
        .getAll(buildParams({ number: 106 }))
        .then((response) => {
          return response.settings[0];
        });
    },
  });

  //useQuery for getting the list of warehouses
  const { data: warehouses } = useQuery({
    queryKey: ["warehouses"],
    queryFn: () => {
      return warehouseService.getAll();
    },
    initialData: { warehouses: [] },
    select: (data: { warehouses: Warehouse[] }) => {
      return data.warehouses;
    },
  });
  //useMutation for updating the default warehouse
  const { mutateAsync: updateDefaultWarehouse } = useMutation({
    mutationFn: (values: { defaultWarehouse: string }) => {
      return settingService
        .updateSettings({ defaultWarehouse: values.defaultWarehouse })
        .then((response) => {
          if (response?.errors) {
            return Promise.reject(response);
          }
          response.defaultWarehouse = values.defaultWarehouse;
          return Promise.resolve(response);
        });
    },
    onSuccess: (data) => {
      queryClient.setQueryData(["defaultWarehouse"], {
        value: data.defaultWarehouse,
      });
    },
  });

  //onSubmit handler for updating the default warehouse
  const onSubmitDefaultWarehouse = useCallback(
    async (
      { value: defaultWarehouse }: { value: string },
      {
        setFieldError,
      }: { setFieldError: (field: string, value: string) => void },
    ): Promise<void> => {
      await updateDefaultWarehouse({ defaultWarehouse }).catch((error) => {
        return setFieldError("defaultWarehouse", error.errors[0]);
      });
    },
    [updateDefaultWarehouse],
  );

  /**
   * Form to update the default warehouse setting
   */
  const updateDefaultWarehouseForm: (
    props: FormikProps<{ value: string }>,
  ) => JSX.Element = useCallback(
    ({ dirty }) => (
      <Form layout="vertical">
        <Row justify="start" gutter={16}>
          <Col span={8}>
            <h3>{t("translation:default_warehouse")}</h3>
            <p>{t("translation:default_warehouse_description")}</p>
          </Col>
          <Col span={13}>
            <Form.Item name="value" label={t("translation:default_warehouse")}>
              <SearchFilter
                list={warehouses}
                name="value"
                placeholder={t("translation:select_default_warehouse")}
                sort
                mapOptionsFn={genericMapper}
              />
            </Form.Item>
          </Col>
          <Col span={3}>
            <SubmitButton
              className="no-label" // skipcq: JS-0394
              type="primary"
              size="large"
              block
              disabled={!dirty}
            >
              {t("translation:save")}
            </SubmitButton>
          </Col>
        </Row>
      </Form>
    ),
    [t, warehouses],
  );

  return (
    <Formik
      component={updateDefaultWarehouseForm}
      initialValues={{
        value: defaultWarehouse ? defaultWarehouse.value : "",
      }}
      enableReinitialize
      onSubmit={onSubmitDefaultWarehouse}
    />
  );
}
