import { makeAPIRequest } from "../helpers/apiCall";
import { APICallBreakdown } from "/app/src/components/status/charts/apiCallBreakdown";
import { Restart, License, Status } from "../types";

/**
 * Gets the status of ItemPath
 * @param params URLSearchParams
 * @returns Promise<Status>
 */
function getStatus(params: URLSearchParams = null): Promise<Status> {
  return makeAPIRequest({ url: "/status", params, method: "get" })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

/**
 * Restarts ItemPath
 *
 * @returns {Promise<Restart>}
 *
 */
function restart(): Promise<Restart> {
  return makeAPIRequest({ url: "/restart", method: "get" })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

/**
 * Retrieves license and PowerPick version
 *
 * @returns {Promise<License>}
 */
function getLicense(): Promise<License> {
  return makeAPIRequest({ url: "/license", method: "get" })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

/**
 * Retrieves breakdown of API calls
 *
 */
function getAPICallsBreakdown(
  params: URLSearchParams = null,
): Promise<APICallBreakdown[]> {
  return makeAPIRequest({ url: "/status/calls", params, method: "get" })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export const servicesService = {
  getStatus,
  restart,
  getLicense,
  getAPICallsBreakdown,
};
