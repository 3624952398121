import React, { useCallback } from "react";
import { Cascader } from "antd";
import { useTranslation } from "react-i18next";
import {
  httpErrorCodes,
  httpSuccessCodes,
} from "/app/src/constants/httpStatusCodes";
const { SHOW_CHILD } = Cascader;

interface Option {
  value: string | number;
  label: string;
  children?: Option[];
}

//combine success and error codes into one array
const options: Option[] = [
  {
    label: "Success",
    value: "Success",
    children: httpSuccessCodes.map((code) => ({
      label: code.name,
      value: code.value,
    })),
  },
  {
    label: "Error",
    value: "Error",
    children: httpErrorCodes.map((code) => ({
      label: code.name,
      value: code.value,
    })),
  },
];

/**
 * Component for displaying the controls for the logs table
 * Controls are search, date range, and status
 */
export default function StatusFilter({
  setStatus,
  statusOptions = options,
  onChange = null,
}: {
  setStatus: React.Dispatch<React.SetStateAction<string>>;
  statusOptions?: Option[];
  onChange?: (value) => void;
}) {
  /**
   * Handles the change event for the cascader. When a change is made, the value is
   * turned into a semi-colon separated string and set to the status state
   * @param value the value of the cascader
   */
  const defaultOnChange = useCallback(
    (value: string[][]) => {
      const codes: string[] = [];
      value.forEach((val) => {
        if (val.length === 2) {
          codes.push(val[1]);
        }
      });
      // turn the codes into a semi-colon separated string
      let status = codes.join(";");
      //add [or] to the beginning of semi-colon separated string
      if (status.length > 0) {
        status = `[or]${status}`;
      }
      setStatus(status);
    },
    [setStatus],
  );
  const { t } = useTranslation();
  return (
    <Cascader
      style={{ width: "100%" }}
      options={statusOptions}
      onChange={onChange ? onChange : defaultOnChange}
      multiple
      size="large"
      showCheckedStrategy={SHOW_CHILD}
      placeholder={t("translation:filter_by_status")}
    />
  );
}
