import { genericGetSingle, genericGetAll } from "../helpers/apiCall";
import { Permission } from "../models";

/**
 * Get all the permissions
 * @param params the query parameters
 * @returns
 */
function getAll(
  params: URLSearchParams = null,
): Promise<{ permissions: Permission[] }> {
  return genericGetAll("permissions", params);
}

/**
 * Function to get the number of permissions
 * @param params query params
 * @returns count of permissions
 */
function getCount(params: URLSearchParams = null): Promise<{ count: number }> {
  params.append("countOnly", "true");
  return genericGetAll("permissions", params);
}
/**
 * Get a single permission
 * @param id the id of the permission
 * @returns
 */
function getSingle(id: number | string): Promise<{ permission: Permission }> {
  return genericGetSingle("permissions", id);
}
export const permissionService = {
  getAll,
  getSingle,
  getCount,
};
