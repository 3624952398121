import { useCallback } from "react";

/**
 *
 * @param param0 cellValue is the value to display in the cell
 * clickFn is the function to call when the cell is clicked
 * @returns returns a clickable cell
 */
export default function ClickableCell({
  cellValue,
  clickFn,
  parameter,
}: {
  cellValue: string;
  clickFn: (param: string | number) => void;
  parameter: string | number;
}) {
  const handleClick = useCallback(() => {
    clickFn(parameter);
  }, [parameter, clickFn]);

  return (
    <div onClick={handleClick} className="clickableCell" aria-hidden>
      {cellValue}
    </div>
  );
}
