import React from "react";

import { buildParams } from "/app/src/helpers";
import { useQuery } from "@tanstack/react-query";
import { statusService } from "/app/src/services";
import DraggableStatusArea from "./draggableStatusArea";
import NewStatus from "./newStatus";

/**
 * Component for managing the custom statuses in Order Builder
 */
export default function OrderBuilderStatuses() {
  const { data: customStatuses } = useQuery({
    queryKey: ["statuses", "custom"],
    queryFn: () => statusService.getAll(buildParams({ name: "[not]Error" })),
    initialData: { statuses: [] },
    select: (data) => data.statuses,
  });
  return (
    <>
      <h2>Order Builder - Custom Statuses</h2>
      <DraggableStatusArea statuses={customStatuses} />
      <div className="newReportColumn">
        <NewStatus />
      </div>
    </>
  );
}
