import {
  genericGetSingle,
  genericGetAll,
  genericUpdateSingle,
} from "../helpers/apiCall";
import { Transaction } from "../models";
import { Error } from "../types";

/**
 * Gets all transactions
 * @param params URLSearchParams
 * @returns Promise<{ transactions: Transaction[] }>
 */
function getAll(
  params: URLSearchParams = null,
): Promise<{ transactions: Transaction[] }> {
  return genericGetAll("transactions", params);
}

// For getting order details
/**
 * Gets a single transaction
 * @param id string
 * @returns Promise<{ transaction: Transaction }>
 */
function getSingle(id: string): Promise<{ transaction: Transaction }> {
  return genericGetSingle("transactions", id);
}

/**
 * Update a single transaction
 * @param id string
 * @param data {}
 * @returns Promise<{ transaction: Transaction } | Error>
 */
function updateSingle(
  id: string,
  data = {},
): Promise<{ transaction: Transaction } | Error> {
  return genericUpdateSingle("transactions", id, data);
}

/**
 * Gets the count of orders
 * @param params URLSearchParams
 * @returns Promise<{ count: number }>
 */
function getCount(params: URLSearchParams = null): Promise<{ count: number }> {
  params.append("countOnly", "true");
  return genericGetAll("transactions", params);
}

export const transactionService = {
  getAll,
  getSingle,
  updateSingle,
  getCount,
};
