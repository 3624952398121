export default function Status(status: number | undefined) {
  switch (status) {
    case 0:
      return "Pending";
    case 1:
      return "Active";
    case 2:
      return "Deactivated";
    case 3:
      return "Application";
    default:
      return "";
  }
}
