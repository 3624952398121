export default function Box({
  children,
  className = "",
  style = {},
}: {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}) {
  return (
    <div
      className={`border border-[#bdbed4] bg-white py-[20px] px-[30px] rounded-[5px] mb-[20px] ${className}`}
      style={style}
    >
      {children}
    </div>
  );
}
