/**
 * Helper function to take a string or number and round it to a certain number of decimal places
 * @param num number to round
 * @param precision how many decimal places to round to
 * @returns rounded number
 */
export function roundDecimal(num: string | number, precision = 2) {
  try {
    num = parseFloat(num as string);
  } catch (e) {
    return num;
  }
  const factor = Math.pow(10, precision);
  return Math.round(num * factor) / factor;
}
