import { Formik } from "formik";
import { Integration } from "/app/src/models";
import { simpleSchemaBuilder } from "/app/src/helpers";

import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import RequestForm from "./requestForm";
import useSetting from "/app/src/components/settings/setting";
import Box from "/app/src/components/generic/components/box";

interface FormValues {
  value: string | undefined;
}

/**
 * Component to display the request settings for a single Data Pull Integration
 * The request settings are the method and body of the request. Current available
 * methods are GET and POST
 */
export default function Request({ integration }: { integration: Integration }) {
  const { t } = useTranslation();

  const { settingValue, createUpdateSetting } = useSetting({
    integrationId: integration.id,
    type: "request",
  });

  const onSubmit = useCallback(
    async (values: FormValues) => {
      await createUpdateSetting({
        ...values,
        type: "request",
        integrationId: integration.id,
      });
    },
    [createUpdateSetting, integration.id],
  );

  return (
    <Box>
      <h1>{t("translation:request")}</h1>
      <Formik
        initialValues={{
          value: settingValue,
        }}
        validationSchema={simpleSchemaBuilder([
          { name: "value", type: "string", required: true },
        ])}
        onSubmit={onSubmit}
        enableReinitialize
      >
        <RequestForm />
      </Formik>
    </Box>
  );
}
