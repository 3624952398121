import { Cascader } from "antd";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import { Integration, ReportColumnType } from "/app/src/models";
import { useQuery } from "@tanstack/react-query";
import { reportColumnTypeService } from "/app/src/services";
import { buildParams } from "/app/src/helpers";
import { cascadeReportColumnTypes } from "/app/src/helpers/cascadeReportColumnTypes";

interface FormValues {
  value: string | undefined;
}

/**
 *
 * @returns DynamicCascader component
 */
export default function DynamicCascader({
  integration,
}: {
  integration: Integration;
}) {
  const { t } = useTranslation();

  const { setFieldValue, values } = useFormikContext<FormValues>();

  const { data: columnTypes } = useQuery({
    queryKey: ["columnTypes", integration.baseTable],
    queryFn: () => {
      const params = { baseTable: integration.baseTable };
      if (integration.baseTable === "Material") {
        params["table"] = "Material";
      }
      return reportColumnTypeService.getAll(buildParams(params));
    },
    enabled: Boolean(integration.baseTable),
    initialData: { report_column_types: [] },
    select: (data: { report_column_types: ReportColumnType[] }) => {
      return data.report_column_types;
    },
  });

  // Function for cascader that adds the report column type id to the value
  const addReportColumnTypeId = useCallback(
    (selectedValues) => {
      const currentValue = values.value ?? "";
      const selectedColumnTypeValue = selectedValues[selectedValues.length - 1];

      setFieldValue("value", `${currentValue}{col:${selectedColumnTypeValue}}`);
    },
    [values.value, setFieldValue],
  );

  return (
    <div style={{ marginTop: "8px" }}>
      <Cascader
        size="large"
        className="larger-cascade"
        options={cascadeReportColumnTypes(columnTypes)}
        placeholder={t("translation:select_column")}
        showSearch
        onChange={addReportColumnTypeId}
        allowClear={false}
      />
    </div>
  );
}
