import {
  genericGetSingle,
  genericCreateSingle,
  genericDeleteSingle,
  genericGetAll,
  genericUpdateSingle,
} from "../helpers/apiCall";
import { View } from "../models";
import { Error } from "../types";

/**
 * Gets all views
 * @param params URLSearchParams
 * @returns Promise<{ views: View[] }>
 */
function getAll(params: URLSearchParams = null): Promise<{ views: View[] }> {
  return genericGetAll("views", params);
}

/**
 * Gets a single view
 * @param id number | string
 * @returns Promise<{ view: View }>
 */
function getSingle(id: number | string): Promise<{ view: View }> {
  return genericGetSingle("views", id);
}

/**
 * Update a single view
 * @param id number | string
 * @param view { [key: string]: string | number | boolean | Date | undefined}
 * @returns Promise<{ view: View } | Error>
 */
function updateSingle(
  id: number | string,
  view: { [key: string]: string | number | boolean | Date | undefined },
): Promise<{ view: View } | Error> {
  return genericUpdateSingle("views", id, view);
}

/**
 * Create a new view
 * @param view { [key: string]: string | number | boolean | Date | undefined}
 * @returns Promise<{ view: View } | Error>
 */
function createSingle(view: {
  [key: string]: string | number | boolean | Date | undefined;
}): Promise<{ view: View } | Error> {
  return genericCreateSingle("views", view);
}

/**
 * Delete a single view
 * @param id number | string
 * @returns Promise<{ deleted: boolean } | { error: string }>
 */
function deleteSingle(
  id: number | string,
): Promise<{ deleted: boolean } | { error: string }> {
  return genericDeleteSingle("views", id);
}

/**
 * Gets the count of views
 * @param params URLSearchParams
 * @returns Promise<{ count: number }>
 */
function getCount(params: URLSearchParams = null): Promise<{ count: number }> {
  params.append("countOnly", "true");
  return genericGetAll("views", params);
}

export const viewService = {
  getAll,
  getSingle,
  updateSingle,
  createSingle,
  deleteSingle,
  getCount,
};
