import { genericGetSingle, genericGetAll } from "../helpers/apiCall";
import { StorageRule } from "../models";

/**
 * Gets all storage rules
 * @param params URLSearchParams
 * @returns Promise<{ storage_rules: StorageRule[] }>
 */
function getAll(
  params: URLSearchParams = null,
): Promise<{ storage_rules: StorageRule[] }> {
  return genericGetAll("storage_rules", params);
}

/**
 * Gets a single storage rule
 * @param id string
 * @returns Promise<{ storage_rule: StorageRule }>
 */
function getSingle(id: string): Promise<{ storage_rule: StorageRule }> {
  return genericGetSingle("storage_rules", id);
}

/**
 * Gets the count of storage rules
 * @param params URLSearchParams
 * @returns Promise<{ count: number }>
 */
function getCount(params: URLSearchParams = null): Promise<{ count: number }> {
  params.append("countOnly", "true");
  return genericGetAll("storage_rules", params);
}

export const storageRuleService = {
  getAll,
  getSingle,
  getCount,
};
