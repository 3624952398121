import React from "react";
import { Divider } from "@nextui-org/react";

interface NextDividerProps {
  orientation?: "horizontal" | "vertical";
  className?: string;
  children?: React.ReactNode;
  displayAtStart?: boolean;
}

/**
 *
 * @param param0 orientation
 * @returns NextUI Divider
 */
const NextDivider: React.FC<NextDividerProps> = ({
  orientation = "horizontal",
  className,
  children,
  displayAtStart,
}) => {
  if (children && displayAtStart) {
    return (
      <>
        <div className="flex items-center py-2">
          <div className="w-[5%]">
            <Divider className={className} />
          </div>
          <span className="mx-2">{children}</span>
          <div className="flex-grow">
            <Divider className={className} orientation={orientation} />
          </div>
        </div>
      </>
    );
  } else if (children) {
    return (
      <div className="flex items-center py-2">
        <>
          <div className="flex-1">
            <Divider className={className} orientation={orientation} />
          </div>
          <span className="mx-2">{children}</span>
          <div className="flex-1">
            <Divider className={className} orientation={orientation} />
          </div>
        </>
      </div>
    );
  } else {
    return <Divider className={className} orientation={orientation} />;
  }
};

export default NextDivider;
