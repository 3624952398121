import { Row, Col, Input } from "antd";
import { useTranslation } from "react-i18next";
import { Integration } from "/app/src/models";
import { integrationService } from "/app/src/services";
import { useCallback, useState } from "react";
import DefaultMapping from "./defaultMapping";
import NextButton from "/app/src/components/NextUi/Button";
import Box from "/app/src/components/generic/components/box";

/**
 * Component to manually pulling data from an integration
 */
export default function Manual({ integration }: { integration: Integration }) {
  const { t } = useTranslation();
  const [testResponse, setTestResponse] = useState<string | null>(null);
  const [data, setData] = useState("");

  /**
   * Test the integration
   */
  const handleTest = useCallback(async () => {
    try {
      const response = await integrationService.runIntegration(integration.id, {
        filterValues: data,
      });
      setTestResponse(JSON.stringify(response?.message));
    } catch (error) {
      setTestResponse(error.message);
    }
  }, [data, integration.id]);

  /**
   * Handle change for manual export input field
   */
  const handleInputChange = useCallback(
    (e) => {
      setData(e.target.value);
    },
    [setData],
  );

  return (
    <Box>
      <Row gutter={16}>
        <Col span={24}>
          <h1>{t("translation:run")}</h1>
          <Input.TextArea
            value={data}
            rows={4}
            onChange={handleInputChange}
            placeholder={t("translation:manual_export_tip")}
            size="large"
          />
          {testResponse && <p>{testResponse}</p>}
        </Col>
      </Row>
      <Row gutter={16}>
        <Col offset={20} span={4}>
          <NextButton
            color="primary"
            fullWidth
            onClick={handleTest}
            isDisabled={!data}
          >
            {t("translation:import")}
          </NextButton>
        </Col>
      </Row>
      <DefaultMapping integration={integration} />
    </Box>
  );
}
