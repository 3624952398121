import React, { useCallback } from "react";
import { Formik } from "formik";
import { Row, Col } from "antd";
import { Form, Select, SubmitButton, Input } from "formik-antd";
import { useNavigate } from "react-router-dom";
import { integrationService, connectionService } from "/app/src/services";
import { useTranslation } from "react-i18next";
import { App, Connection, Integration } from "/app/src/models";
import { buildParams } from "/app/src/helpers/params";
import { useQuery, useMutation } from "@tanstack/react-query";
import { handlePromiseError } from "/app/src/helpers/api";
import { simpleSchemaBuilder } from "/app/src/helpers";
import Box from "/app/src/components/generic/components/box";

function formatForm(values: FormValues, app: App): Integration {
  return {
    name: values.name,
    appId: app.id,
    connectionId: values.connectionId,
    number: values.type,
    baseTable: "History", //for now the only data push integration type is history-based
  };
}
interface FormValues {
  name: string;
  type: string;
  connectionId: number | undefined;
}
export default function NewIntegration({
  app,
  setToggled,
}: {
  app: App;
  setToggled: () => void;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const types = ["JSON", "XML"];

  //useQuery to get connections
  const { data: connections } = useQuery({
    queryKey: ["connections", "REST"],
    queryFn: () => {
      return connectionService.getAll(buildParams({ type: "REST" }));
    },
    initialData: { connections: [] },
    select: (data: { connections: Connection[] }) => {
      return data.connections;
    },
  });

  //useMutation to create integration
  const { mutateAsync: createIntegration } = useMutation({
    mutationFn: (newIntegration: Integration) => {
      return integrationService
        .createSingle(newIntegration)
        .then(handlePromiseError);
    },
  });

  //onSubmit handler, creates new integration and navigates to it
  const onSubmitIntegration = useCallback(
    async (values: FormValues) => {
      const newIntegration = formatForm(values, app);
      const response = await createIntegration(newIntegration);
      navigate(`/apps/${app.id}/integrations/${response.integration.id}`);
      setToggled();
    },
    [app, createIntegration, navigate, setToggled],
  );

  return (
    <Box>
      <div className="newIntegration">
        <Formik
          enableReinitialize
          initialValues={{
            name: "",
            type: "JSON",
            connectionId: undefined,
          }}
          validationSchema={simpleSchemaBuilder([
            { name: "name", type: "string", required: true },
            { name: "connectionId", type: "number", required: true },
          ])}
          onSubmit={onSubmitIntegration}
        >
          {({ dirty }) => (
            <Form layout="vertical">
              <Row justify="start" gutter={16}>
                <Col span={6}>
                  <Form.Item
                    name="name"
                    label={t("translation:integration_name")}
                    hasFeedback={false}
                  >
                    <Input
                      name="name"
                      placeholder={t("translation:enter_name")}
                      size="large"
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="type" label={t("translation:format")}>
                    <Select
                      name="type"
                      size="large"
                      placeholder={t("translation:select_format")}
                      disabled
                    >
                      {types.map((t) => (
                        <Select.Option value={t} key={t}>
                          {t}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="connectionId"
                    label={t("translation:connection")}
                  >
                    <Select
                      name="connectionId"
                      size="large"
                      placeholder={t("translation:select_connection")}
                    >
                      {connections.map((c) => (
                        <Select.Option value={c.id} key={c.id}>
                          {c.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <SubmitButton
                    type="primary"
                    size="large"
                    block
                    disabled={!dirty}
                    style={{ marginTop: "30px" }}
                  >
                    {t("translation:create_integration")}
                  </SubmitButton>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </div>
    </Box>
  );
}
