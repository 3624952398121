import { useTranslation } from "react-i18next";
import { Integration, Mapping } from "/app/src/models";
import NewFilterForm from "./newFilterForm";
import { useQuery } from "@tanstack/react-query";
import { mappingService } from "/app/src/services";
import { buildParams } from "/app/src/helpers/params";
import Filter from "./filter";
import useSettings from "/app/src/components/settings/setting/useSettings";
import Box from "/app/src/components/generic/components/box";

/**
 * Component to display filters for data pull integration
 */
export default function Filters({ integration }: { integration: Integration }) {
  const { t } = useTranslation();
  const {
    settings: filters,
    createSetting,
    updateSetting,
    deleteSetting,
  } = useSettings({ type: "sqlFilter", integrationId: integration.id });

  //get all mappings with the integration id
  const { data: mappings } = useQuery({
    queryKey: ["dataPullMappings", integration.id],
    queryFn: () => {
      return mappingService.getAll(
        buildParams({
          integrationId: integration.id,
        }),
      );
    },
    initialData: { mappings: [] },
    select: (data: { mappings: Mapping[] }) => {
      return data.mappings;
    },
  });

  return (
    <Box>
      <h1>{t("translation:filters")}</h1>
      {filters.map((filter) => (
        <Filter
          filter={filter}
          updateSetting={updateSetting}
          deleteSetting={deleteSetting}
          mappings={mappings}
          key={filter.id}
        />
      ))}
      <NewFilterForm
        integration={integration}
        createSetting={createSetting}
        mappings={mappings}
      />
    </Box>
  );
}
