import React from "react";
import Tip from "/app/src/components/NextUi/Tip";

interface IconBuilderProps {
  icon: React.ElementType;
  size?: number;
  color?: string;
  onClick?: () => void;
  className?: string;
}

interface IconToolTipProps {
  content: string;
  icon: React.ElementType;
  size?: number;
  color?: string;
}

/**
 *
 * @param param0 icon, size and color
 * @returns Icon
 */
const IconBuilder: React.FC<IconBuilderProps> = ({
  icon: Icon,
  size = 24,
  color = "#202023",
  onClick,
  className,
}) => {
  return !onClick ? (
    <div className={className}>
      <Icon color={color} size={size} />
    </div>
  ) : (
    <button className={className} onClick={onClick} onKeyDown={onClick}>
      <Icon color={color} size={size} />
    </button>
  );
};

const IconToolTip: React.FC<IconToolTipProps> = ({
  content,
  icon: Icon,
  size = 24,
  color = "#202023",
}) => {
  return (
    <Tip content={content}>
      <div>
        <Icon color={color} size={size} />
      </div>
    </Tip>
  );
};

export { IconBuilder, IconToolTip };
