export const HistoryTypes = [
  { name: "Not Set (0)", value: 0 },
  { name: "Manual Put (1)", value: 1 },
  { name: "Manual Pick (2)", value: 2 },
  { name: "Order Put (3)", value: 3 },
  { name: "Order Pick (4)", value: 4 },
  { name: "Transfer (5)", value: 5 },
  { name: "Order Count (6)", value: 6 },
  { name: "Context Count (7)", value: 7 },
  { name: "Material Rename (8)", value: 8 },
  { name: "Manual Correction (9)", value: 9 },
  { name: "Context Correction (10)", value: 10 },
  { name: "Cancel Request (11)", value: 11 },
  { name: "Purge (12)", value: 12 },
  { name: "Production (13)", value: 13 },
  { name: "Kit Rename (15)", value: 15 },
];

//   NotSet (0) - Not used
//   ManualPut (1)
//   ManualPick (2)
//   OrderPut (3)
//   OrderPick (4)
//   Transfer (5)
//   OrderCount (6)
//   ContextCount(7) - context driven location count during put/pick
//   such as location empty checking or counting if calculated
//   quantity is below a given threshold
//   MaterialRename (8)
//   ManualCorrection (9)
//   ContextCorrection (10) - automatic stock correction during
//   pick/store if a stock shortage is indicated
//   CancelRequest(11) - Cancel order request
//   Purge (12)
//   Production (13)
//   KitRename (15)

export const ExportStateTypes = [
  { name: "Not Set (0)", value: 0 },
  { name: "Cannot yet be exported (1)", value: 1 },
  { name: "Ready to export (2)", value: 2 },
  { name: "Currently exporting or export canceled (3)", value: 3 },
  { name: "Successfully exported (4)", value: 4 },
  { name: "Export failed (5)", value: 5 },
  { name: "Not to be exported (6)", value: 6 },
  { name: "Internal/Queued (10)", value: 10 },
];

export const MotiveTypes = [
  { name: "NotSet (0)", value: 0 },
  { name: "StockUnavailable (1)", value: 1 },
  { name: "Shortage (2)", value: 2 },
  { name: "SpaceUnavailable (3)", value: 3 },
  { name: "SpaceFull (4)", value: 4 },
  { name: "Deleted (5)", value: 5 },
  { name: "Canceled (6)", value: 6 },
  { name: "RoundUpOverstock (7)", value: 7 },
  { name: "ResolveMissingQty (8)", value: 8 },
  { name: "Trim (9)", value: 9 },
  { name: "CancelError (10)", value: 10 },
];
