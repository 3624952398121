import { ChartData } from "../components/charts/types";
import {
  makeAPIRequest,
  genericCreateSingle,
  genericDeleteSingle,
  genericGetAll,
  genericUpdateSingle,
} from "../helpers/apiCall";
import { WidgetDataset } from "../models";
import { Error } from "../types";

/**
 * Gets all widget_datasets
 * @param params URLSearchParams
 * @returns Promise<{ widget_datasets: WidgetDataset[] }>
 */
function getAll(
  params: URLSearchParams = null,
): Promise<{ widget_datasets: WidgetDataset[] }> {
  return genericGetAll("widget_datasets", params);
}

/**
 * Gets all widget_datasets for a widget
 * @param widgetId number
 * @param params { [key: string]: string | number | boolean | Date | undefined}
 * @returns Promise<{ widget_datasets: WidgetDataset[] }>
 */
function getAllFromWidget(
  widgetId: number,
  params: { [key: string]: string | number | boolean | Date | undefined } = {},
): Promise<{ widget_datasets: WidgetDataset[] }> {
  return makeAPIRequest({
    url: `/widgets/${widgetId}/widget_datasets`,
    params,
    method: "get",
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}
/**
 * Gets all widget_datasets for a widget
 * @param widgetId number
 * @param params { [key: string]: string | number | boolean | Date | undefined}
 * @returns Promise<{ widget_datasets: WidgetDataset[] }>
 */
function getWidgetDatasetData(
  widgetId: number,
  params: { [key: string]: string | number | boolean | Date | undefined } = {},
): Promise<{ data: ChartData }> {
  return makeAPIRequest({
    url: `/widgets/${widgetId}/widget_datasets/data`,
    params,
    method: "get",
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}
/**
 * Create a new widget_dataset
 * @param data { [key: string]: string | number | boolean | Date | undefined}
 * @returns Promise<{ widget_dataset: WidgetDataset } | Error>
 */
function createSingle(data: {
  [key: string]: string | number | boolean | Date | undefined;
}): Promise<{ widget_dataset: WidgetDataset } | Error> {
  return genericCreateSingle("widget_datasets", data);
}

/**
 * Update a single widget_dataset
 * @param id number | string
 * @param widgetDataset {}
 * @returns Promise<{ widget_dataset: WidgetDataset } | Error>
 */
function updateSingle(
  id: number | string,
  widgetDataset = {},
): Promise<{ widget_dataset: WidgetDataset } | Error> {
  return genericUpdateSingle("widget_datasets", id, widgetDataset);
}

/**
 * Delete a single widget_dataset
 * @param id number | string
 * @returns Promise<{ deleted: boolean } | { error: string }>
 */
function deleteSingle(
  id: number | string,
): Promise<{ deleted: boolean } | { error: string }> {
  return genericDeleteSingle("widget_datasets", id);
}

/**
 * Gets the count of widget_datasets
 * @param params URLSearchParams
 * @returns Promise<{ count: number }>
 */
function getCount(params: URLSearchParams = null): Promise<{ count: number }> {
  params.append("countOnly", "true");
  return genericGetAll("widget_datasets", params);
}

export const widgetDatasetService = {
  getAll,
  getAllFromWidget,
  getWidgetDatasetData,
  createSingle,
  updateSingle,
  deleteSingle,
  getCount,
};
