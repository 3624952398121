import React from "react";
import { Route, Routes } from "react-router-dom";

import SingleApp from "./single/app";
import AppList from "./appList";

/**
 * Component containing the routes for the apps and apps page
 */
export default function Apps() {
  return (
    <Routes>
      <Route path=":appId/*" element={<SingleApp />} />
      <Route path="/" element={<AppList />} />
    </Routes>
  );
}
