import { Formik, FormikProps } from "formik";
import { Connection, Setting } from "/app/src/models";
import { useCallback } from "react";
import { Form, Input, SubmitButton } from "formik-antd";
import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import PasswordField from "/app/src/components/generic/components/passwordField";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { settingService } from "/app/src/services";
import { buildParams, simpleSchemaBuilder } from "/app/src/helpers";
import { handlePromiseError } from "/app/src/helpers/api";

interface FormValues {
  clientKey?: string;
  clientSecret?: string;
  resourceOwnerKey?: string;
  resourceOwnerSecret?: string;
}

/**
 * Component to display the OAuth1 connection types.
 * Authentication settings are controlled in this component.
 */
export default function OAuth1({ connection }: { connection: Connection }) {
  const queryClient = useQueryClient();
  //list of setting names that are already saved
  const { data: oAuthSettings, isFetching } = useQuery({
    queryKey: ["oAuthSettings", connection.id],
    queryFn: () => {
      return settingService.getAll(
        buildParams({ connectionId: connection.id, type: "[or]password;text" }),
      );
    },
    initialData: { settings: [] },
    select: (data) => {
      // return list of setting names
      return data.settings;
    },
  });

  const { mutateAsync: updateSetting } = useMutation({
    mutationFn: (setting: Setting) => {
      return settingService
        .updateSingle(setting.id, setting)
        .then(handlePromiseError);
    },
    onSuccess: (response) => {
      queryClient.setQueryData(
        ["oAuthSettings", connection.id],
        (oldData: { settings: Setting[] }) => {
          return {
            settings: oldData.settings.map((s) => {
              if (s.id === response.setting.id) {
                return response.setting;
              }
              return s;
            }),
          };
        },
      );
    },
  });

  const { mutateAsync: createSetting } = useMutation({
    mutationFn: (setting: Setting) => {
      return settingService.createSingle(setting).then(handlePromiseError);
    },
    onSuccess: (response) => {
      queryClient.setQueryData(
        ["oAuthSettings", connection.id],
        (oldData: { settings: Setting[] }) => {
          return {
            settings: [...oldData.settings, response.setting],
          };
        },
      );
    },
  });

  const createOrSaveSetting = useCallback(
    async (setting) => {
      //get setting from oAuthSettings by name
      const foundSetting = oAuthSettings.find((s) => s.name === setting.name);
      //if found, update setting
      if (foundSetting) {
        await updateSetting({ id: foundSetting.id, ...setting });
      } else {
        //else create setting
        await createSetting(setting);
      }
    },
    [createSetting, oAuthSettings, updateSetting],
  );

  const handleSubmit = useCallback(
    async (values: FormValues) => {
      //loop through values and save each one if it is not empty
      await Object.keys(values).forEach(async (key) => {
        if (values[key]) {
          await createOrSaveSetting({
            connectionId: connection.id,
            name: key,
            value: values[key],
            type: key === "realm" ? "text" : "password",
          });
        }
      });
      queryClient.invalidateQueries({
        queryKey: ["oAuthSettings", connection.id],
      });
    },
    [connection.id, createOrSaveSetting, queryClient],
  );
  const { t } = useTranslation();
  const settingsForm: (props: FormikProps<FormValues>) => JSX.Element =
    useCallback(
      ({ dirty, isValid }) => (
        <Form layout="vertical">
          <h3>{t("translation:authentication_settings")}</h3>
          <Row justify="start" gutter={16}>
            <Col span={12}>
              <Form.Item name="clientKey" label={t("translation:client_key")}>
                <PasswordField
                  name="clientKey"
                  passwordExists={Boolean(
                    oAuthSettings.find((s) => s.name === "clientKey"),
                  )}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="clientSecret"
                label={t("translation:client_secret")}
              >
                <PasswordField
                  name="clientSecret"
                  passwordExists={Boolean(
                    oAuthSettings.find((s) => s.name === "clientSecret"),
                  )}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="resourceOwnerKey"
                label={t("translation:resource_owner_key")}
              >
                <PasswordField
                  name="resourceOwnerKey"
                  passwordExists={Boolean(
                    oAuthSettings.find((s) => s.name === "resourceOwnerKey"),
                  )}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="resourceOwnerSecret"
                label={t("translation:resource_owner_secret")}
              >
                <PasswordField
                  name="resourceOwnerSecret"
                  passwordExists={Boolean(
                    oAuthSettings.find((s) => s.name === "resourceOwnerSecret"),
                  )}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="realm" label={t("translation:realm")}>
                <Input suffix name="realm" className="user" size="large" />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="start" gutter={16}>
            <Col span={24}>
              <SubmitButton
                type="primary"
                size="large"
                block
                disabled={!dirty || !isValid}
              >
                {t("translation:save")}
              </SubmitButton>
            </Col>
          </Row>
        </Form>
      ),
      [oAuthSettings, t],
    );
  if (isFetching) {
    return <div>loading</div>;
  } else {
    return (
      <Formik
        component={settingsForm}
        initialValues={{
          resourceOwnerKey: "",
          resourceOwnerSecret: "",
          clientKey: "",
          clientSecret: "",
          realm: oAuthSettings.find((s) => s.name === "realm")?.value,
        }}
        enableReinitialize
        onSubmit={handleSubmit}
        validationSchema={simpleSchemaBuilder([
          { name: "resourceOwnerKey", type: "string", required: true },
          { name: "resourceOwnerSecret", type: "string", required: true },
          { name: "clientKey", type: "string", required: true },
          { name: "clientSecret", type: "string", required: true },
          { name: "realm", type: "string", required: true },
        ])}
      />
    );
  }
}
