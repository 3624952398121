import deleteIcon from "/app/src/components/generic/title/deleteIcon.svg";
import NextButton from "/app/src/components/NextUi/Button";
import NextModal from "/app/src/components/NextUi/Modal";
import ModalBuilder from "/app/src/components/NextUi/helpers/ModalBuilder";
import { useDisclosure } from "@nextui-org/react";

const DeleteButton = ({
  onClick,
  disabled,
  modalHeader,
  modalBody,
}: {
  onClick: () => void;
  disabled?: boolean;
  modalHeader?: string;
  modalBody?: string;
}) => {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  return modalHeader ? (
    <>
      <button className="deleteButton" onClick={onOpen} disabled={disabled}>
        <img src={deleteIcon} alt="Delete" />
      </button>
      <NextModal
        disableAnimation={false}
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        placement="top"
      >
        {ModalBuilder({
          warning: true,
          modalHeader,
          modalBody,
          modalFooter: (
            <>
              <NextButton
                size="sm"
                color="default"
                variant="bordered"
                onClick={onOpenChange}
              >
                Cancel
              </NextButton>
              <NextButton size="sm" color="red" onClick={onClick}>
                Delete
              </NextButton>
            </>
          ),
        })}
      </NextModal>
    </>
  ) : (
    <button className="deleteButton" onClick={onClick} disabled={disabled}>
      <img src={deleteIcon} alt="Delete" />
    </button>
  );
};

export default DeleteButton;
