import React, { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import NotificationCard from "/app/src/components/NextUi/Notification";

/**
 *
 * @param param0 versionChanged
 * @returns Notification for new version
 */
export default function NewVersionStatus({
  versionChanged,
}: {
  versionChanged: boolean;
}) {
  const { t } = useTranslation();
  const [openNotification, setOpenNotification] = useState(versionChanged);

  const reloadClick = useCallback(() => {
    window.location.reload();
  }, []);

  return (
    <NotificationCard
      headerText={t("translation:license_version")}
      bodyText={t("translation:license_version_message")}
      openNotification={openNotification}
      setOpenNotification={setOpenNotification}
      onClick={reloadClick}
    />
  );
}
