import React from "react";
import { useParams } from "react-router-dom";
import { useAuthState } from "/app/src/contexts/authentication";
import { AccessProvider } from "/app/src/contexts/AccessContext";
import useAccessPermission from "/app/src/hooks/useAccessPermission";
import LockedAccess from "../generic/components/permissions/lockedAccess";
import LockedReport from "./lockedReport";

/**
 * Wrapper to add Access context to the report component. If the user
 * does not have access to view the report, a locked access component will be displayed
 */
export default function ReportsAccessWrapper() {
  const { reportId } = useParams();
  const { user } = useAuthState();
  const { canView, canEdit, canDelete } = useAccessPermission(
    user.roleId,
    "REPO",
    "Report",
    reportId,
  );

  return (
    <AccessProvider value={{ canEdit, canDelete, canView }}>
      {canView ? (
        <LockedReport reportId={parseInt(reportId)} />
      ) : (
        <LockedAccess />
      )}
    </AccessProvider>
  );
}
