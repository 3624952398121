import React from "react";
import { Routes, Route } from "react-router-dom";

import Integration from "./integration";
import NewIntegrationForm from "./newIntegrationForm";
import IntegrationsList from "/app/src/components/generic/components/integration/integrationsView";
import Controls from "/app/src/components/generic/components/integration/_controls";
import { useToggle } from "/app/src/hooks";
import { App } from "/app/src/models";
import { epicorThemes } from "/app/src/constants/themes";

export default function EpicorApp({ app }: { app: App }) {
  const types = [
    { name: "Export", value: "Export" },
    { name: "Import", value: "Import" },
  ];
  const [toggled, setToggled] = useToggle();

  return (
    <Routes>
      <Route
        path={"/"}
        element={
          <Controls
            app={app}
            name="epicor_app"
            toggled={toggled}
            setToggled={setToggled}
          >
            <NewIntegrationForm
              app={app}
              themes={epicorThemes}
              setToggled={setToggled}
            />
          </Controls>
        }
      >
        <Route
          path="integrations/:integrationId/*"
          element={<Integration app={app} />}
        />
        <Route
          path="/"
          element={
            <IntegrationsList app={app} themes={epicorThemes} types={types} />
          }
        />
      </Route>
    </Routes>
  );
}
