import { genericGetSingle, genericGetAll } from "../helpers/apiCall";
import { StorageUnit } from "../models";

/**
 * Gets all storage units
 * @param params URLSearchParams
 * @returns Promise<{ storage_units: StorageUnit[] }>
 */
function getAll(
  params: URLSearchParams = null,
): Promise<{ storage_units: StorageUnit[] }> {
  return genericGetAll("storage_units", params);
}

/**
 * Gets a single storage unit
 * @param id string
 * @returns Promise<{ storage_unit: StorageUnit }>
 */
function getSingle(id: string): Promise<{ storage_unit: StorageUnit }> {
  return genericGetSingle("storage_units", id);
}

/**
 * Gets the count of storage units
 * @param params URLSearchParams
 * @returns Promise<{ count: number }>
 */
function getCount(params: URLSearchParams = null): Promise<{ count: number }> {
  params.append("countOnly", "true");
  return genericGetAll("storage_units", params);
}

export const storageUnitService = {
  getAll,
  getSingle,
  getCount,
};
