import React from "react";
import { Helmet } from "react-helmet";
import { Routes, Route } from "react-router-dom";
import { Row, Col } from "antd";
import Menu from "./_menu";
import Controls from "./_controls";
import General from "./general";
import Kardex from "./kardex";
import App from "./app";
import Apps from "./apps";
import Connections from "./connections";
import CustomColumnTypes from "./customColumnTypes";
import { useQuery } from "@tanstack/react-query";
import { appService } from "/app/src/services";
import { buildParams } from "/app/src/helpers/params";
import { App as AppType } from "/app/src/models";
import { useAuthState } from "/app/src/contexts/authentication";

export default function Settings() {
  const { user } = useAuthState();
  const { data: availableAppSettings } = useQuery({
    queryKey: [
      "appSettings",
      "[or]Philips Hue;Variance;Epicor;Order Builder",
      "[nor]0;3",
    ],
    queryFn: () => {
      return appService.getAll(
        buildParams({
          name: "[or]Philips Hue;Variance;Epicor;Order Builder",
          status: "[nor]0;3",
        }),
      );
    },
    initialData: { apps: [] },
    select: (data: { apps: AppType[] }) => {
      return data.apps;
    },
  });

  const { data: availableAppSettingsCount } = useQuery({
    queryKey: [
      "appSettingsCount",
      "[or]Philips Hue;Variance;Epicor;Order Builder",
      "[nor]0;3",
    ],
    queryFn: () => {
      return appService.getCount(
        buildParams({
          name: "[or]Philips Hue;Variance;Epicor;Order Builder",
          status: "[nor]0;3",
        }),
      );
    },
    initialData: { count: 0 },
    select: (data: { count: number }) => {
      return data.count;
    },
  });

  return (
    <div className="settings">
      <Helmet>
        <title>Settings - ItemPath</title>
      </Helmet>
      <Controls title="Settings" />
      <Row gutter={20}>
        <Col span={4}>
          <Menu appSettingsCount={availableAppSettingsCount} />
        </Col>
        <Col span={20}>
          <Routes>
            <Route element={<General />} path="/" />
            <Route element={<Kardex />} path="kardex" />
            <Route element={<Connections />} path="connections" />
            {user?.username === "ItemPathAdmin" && (
              <Route
                element={<CustomColumnTypes />}
                path="report_column_types"
              />
            )}
            <Route
              element={<Apps availableAppSettings={availableAppSettings} />}
              path="apps"
            />
            <Route element={<App />} path="apps/:appId/*" />
          </Routes>
        </Col>
      </Row>
    </div>
  );
}
