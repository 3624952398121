import { genericGetSingle, genericGetAll } from "../helpers/apiCall";
import { Revision } from "../models";

/**
 * Get all revisions
 * @param params URLSearchParams
 * @returns Promise<{ revisions: Revision[] }>
 */
function getAll(
  params: URLSearchParams = null,
): Promise<{ revisions: Revision[] }> {
  return genericGetAll("revisions", params);
}

/**
 * Get a single revision
 * @param id number | string
 * @returns Promise<{ revision: Revision }>
 */
function getSingle(id: number | string): Promise<{ revision: Revision }> {
  return genericGetSingle("revisions", id);
}

/**
 * Gets the count of revisions
 * @param params URLSearchParams
 * @returns Promise<{ count: number }>
 */
function getCount(params: URLSearchParams = null): Promise<{ count: number }> {
  params.append("countOnly", "true");
  return genericGetAll("revisions", params);
}

export const revisionService = {
  getAll,
  getSingle,
  getCount,
};
