import { CartesianMarkerProps, DatumValue } from "@nivo/core";
import { Setting } from "/app/src/models";

/**
 * Helper function to convert a list of settings to a list of Nivo markers
 * @param settings the list of settings to convert to markers
 * @returns the list of markers
 */
export default function settingsToMarkers(settings: Setting[]) {
  const trends: CartesianMarkerProps<DatumValue>[] = [];
  settings.forEach((trendLine) => {
    const color = JSON.parse(trendLine.value).color;
    const number: number = JSON.parse(trendLine.value).value;
    trends.push({
      axis: "y",
      value: Number(number),
      lineStyle: { stroke: color, strokeWidth: 2 },
      legend: trendLine.name,
      legendOrientation: "horizontal",
      textStyle: { fontSize: 10 },
      legendPosition: "bottom-right",
    });
  });
  return trends;
}
