import React, { useState, useEffect, useCallback } from "react";
import { Layout, Layouts, Responsive, WidthProvider } from "react-grid-layout";
import { Status } from "/app/src/models";
import { DraggableStatus } from "./draggableStatus";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { statusService } from "/app/src/services";
import { handlePromiseError } from "/app/src/helpers/api";
const ResponsiveReactGridLayout = WidthProvider(Responsive);

/**
 * Component for containing the draggable statuses in Order Builder
 */
export default function DraggableStatusArea({
  statuses,
}: {
  statuses: Status[];
}) {
  const compactType = "vertical";
  const [isMounted, setIsMounted] = useState(false);
  const [layouts, setLayouts] = useState<Layouts>();

  const cols = { lg: 1, md: 1, sm: 1, xs: 1, xxs: 1 };

  useEffect(() => {
    setIsMounted(true);
  }, []);

  const queryClient = useQueryClient();

  const { mutateAsync: updateStatus } = useMutation({
    mutationFn: (values: Status) => {
      return statusService
        .updateSingle(values.id, {
          ...values,
        })
        .then(handlePromiseError);
    },
    onSuccess: (response) => {
      queryClient.setQueryData(
        ["statuses", "custom"],
        (oldData: { statuses: Status[] }) => {
          return {
            statuses: oldData.statuses.map((status) => {
              if (status.id === response.status.id) {
                return response.status;
              }
              return status;
            }),
          };
        },
      );
    },
  });

  const updateStatusPosition = useCallback(
    (value, position) => {
      updateStatus({
        id: Number(value.i),
        position: position.toString(),
      });
      value.y = position;
    },
    [updateStatus],
  );

  const onLayoutChange = useCallback(
    (layout: Layout[], layouts: Layouts) => {
      if (layout) {
        setLayouts(layouts);
        //convert the object into array
        const objectArray = Object.entries(layout);
        objectArray.forEach(([_key, value]) => {
          let position = value.y;

          //update position for status object in front end
          for (const col of statuses) {
            const id = parseInt(value.i);
            const pos = col?.position ? parseInt(col.position) : -1;

            if (col.id === id && pos !== position) {
              if (col.type === "custom" && position > statuses.length - 3) {
                position = statuses.length - 3;
                value.y = position;
              }

              if (col.type === "default") {
                if (col.name === "Sent to Power Pick") {
                  updateStatusPosition(value, statuses.length - 1);
                } else if (col.name === "Ready to be Sent to Power Pick") {
                  updateStatusPosition(value, statuses.length - 2);
                } else {
                  updateStatusPosition(value, position);
                }
              } else if (col.type === "custom") {
                updateStatusPosition(value, position);
              }
            }
          }
        });
      }
    },
    [statuses, updateStatusPosition],
  );

  const children = React.useMemo(() => {
    return statuses.map((status) => {
      const grid = {
        w: 1,
        h: 1,
        x: 0,
        y: statuses.length - 1,
        isResizable: false,
        isDraggable: status.type === "custom",
      };

      if (status?.position) {
        grid["y"] = parseInt(status.position);
      }

      return (
        <div key={status.id} className="reportColumn" data-grid={grid}>
          <DraggableStatus
            key={status.id}
            status={status}
            updateStatus={updateStatus}
          />
        </div>
      );
    });
  }, [statuses, updateStatus]);

  return (
    <div className="layout">
      <ResponsiveReactGridLayout
        className="layout"
        rowHeight={60}
        onLayoutChange={onLayoutChange}
        cols={cols}
        layouts={layouts}
        measureBeforeMount={false}
        useCSSTransforms={isMounted}
        compactType={compactType}
        preventCollision={!compactType}
        isResizable={false}
        isDraggable
      >
        {children}
      </ResponsiveReactGridLayout>
    </div>
  );
}
