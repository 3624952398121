import React, { useCallback } from "react";
import { useFormikContext } from "formik";
import { Select } from "antd";
import { useTranslation } from "react-i18next";

interface FormValues {
  mapppingType: string;
}

/**
 *
 * @returns MappingType component
 */
export default function MappingType() {
  const { setFieldValue } = useFormikContext<FormValues>();
  const { t } = useTranslation();

  const onChangeMappingType = useCallback(
    (e) => {
      setFieldValue("mappingType", e);
    },
    [setFieldValue],
  );

  return (
    <Select className="w-full" onChange={onChangeMappingType} size="large">
      <Select.Option value="Not Mapped" key={"Not Mapped"}>
        {t("translation:not_mapped")}
      </Select.Option>
      <Select.Option value="Static Text" key={"Static Text"}>
        {t("translation:static_text")}
      </Select.Option>
      <Select.Option value="Report Column" key={"Report Column"}>
        {t("translation:report_column")}
      </Select.Option>
    </Select>
  );
}
