import { useEffect, useState } from "react";
import { Row, Col, Collapse } from "antd";
import {
  CheckOutlined,
  CloseOutlined,
  RightOutlined,
  DownOutlined,
} from "@ant-design/icons";

import { processService } from "/app/src/services";
import { useTranslation } from "react-i18next";
import { useNumberId } from "/app/src/hooks/useNumberId";
import NextButton from "/app/src/components/NextUi/Button";

export default function SerialNumbers({
  index,
  serialNumberGroupings,
  currentGrouped,
  setGrouping,
}: {
  index: number;
  currentGrouped: number;
  setGrouping: (grouping: number) => void;
  serialNumberGroupings: { name: string; numbers: number[] };
}) {
  const { t } = useTranslation();
  const { processId } = useNumberId();
  const [status, setStatus] = useState("queued");
  const [title, setTitle] = useState("");
  const [selectedSerialNumbers, setSelectedSerialNumbers] = useState<
    { quantity: number; serialNumber: number }[]
  >([]);
  const [skippedNumbers, setSkippedNumbers] = useState<
    { quantity: number; serialNumber: number }[]
  >([]);
  const [currentProcess, setCurrentProcess] = useState<number | undefined>(
    undefined,
  );
  const [selectedCount, setSelectedCount] = useState(0);
  const [activeKey, setActiveKey] = useState(0);
  useEffect(() => {
    if (index === currentGrouped) {
      if (serialNumberGroupings.name) {
        setActiveKey(1);
      } else {
        // Do not set as active if there is no location
        setGrouping(index + 1);
      }
    } else {
      setActiveKey(0);
    }
  }, [currentGrouped, setGrouping, serialNumberGroupings, index]);

  useEffect(() => {
    if (currentProcess) {
      if (
        skippedNumbers.length > 0 &&
        selectedSerialNumbers.length - skippedNumbers.length === 0
      ) {
        setStatus("skipped");
      } else if (selectedSerialNumbers.length - skippedNumbers.length === 0) {
        setStatus("done");
      }
    }
  }, [skippedNumbers, selectedSerialNumbers, currentProcess]);

  useEffect(() => {
    const length = serialNumberGroupings.numbers.length;
    const numbers = [];
    for (let i = 0; i < length; i++) {
      numbers.push({
        serialNumber: serialNumberGroupings.numbers[i],
        quantity: 1,
      });
    }
    setSelectedSerialNumbers(numbers);
    setSelectedCount(length);
  }, [serialNumberGroupings]);

  const toggleSelectedSerialNumber = (number: number, status: number) => {
    removeNumber(number);
    addNumber(number, status);
    if (status) {
      setSelectedCount(selectedCount + 1);
    } else {
      setSelectedCount(selectedCount - 1);
    }
  };

  const sendToPowerPick = (
    line: { quantity: number; serialNumber: number },
    operation: string,
  ) => {
    if (operation === "skip") {
      line["quantity"] = 0;
    }

    return processService.updateSingle(processId as string, { lines: [line] });
  };

  const confirm = async (operation: string) => {
    //process orderlines here,
    //if no errors then go next grouped
    setStatus("processing");

    for (const line of selectedSerialNumbers) {
      setCurrentProcess(line["serialNumber"]);
      await sendToPowerPick(line, operation).then((response) => {
        // if any of the serial numbers are deselected(skipped),
        // the ui should show which got skipped
        if (line["quantity"] === 0) {
          if (response) {
            setSkippedNumbers((skippedNumbers) => [...skippedNumbers, line]);
            return line;
          }
          console.log(skippedNumbers);
        } else {
          removeNumber(line["serialNumber"]);
        }
      });
    }
    setGrouping(index + 1);
  };

  const addNumber = (number: number, status: number) => {
    const newObj = { serialNumber: number, quantity: status };
    setSelectedSerialNumbers((numbers) => [...numbers, newObj]);
  };

  const removeNumber = (number: number) => {
    setSelectedSerialNumbers((lines) =>
      lines.filter((c) => c.serialNumber !== number),
    );
  };

  useEffect(() => {
    setTitle(getGroupingTitle(serialNumberGroupings.numbers));
  }, [serialNumberGroupings]);

  switch (status) {
    case "done":
      return null;

    case "processing":
      return (
        <Row align="middle">
          <Col span={24} className="processing">
            <Col span={20}>
              {t("translation:powerpick_is_processing")}{" "}
              {currentProcess ? currentProcess : "..."}
            </Col>
          </Col>
        </Row>
      );

    case "skipped":
      return (
        <Col span={24} className="skipped">
          <Row align="middle">
            <Col span={20}>{`Skipped ${title}`}</Col>
          </Row>
        </Col>
      );

    case "queued":
      return (
        <Row align="middle">
          <Col span={24}>
            <Collapse
              activeKey={activeKey}
              className={
                activeKey === 1 ? "serialNumbersSelected" : "serialNumbers"
              }
            >
              <Collapse.Panel
                showArrow={false}
                className="panel"
                header={
                  <Row gutter={[0, 15]}>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 16 }}
                      lg={{ span: 18 }}
                    >
                      {activeKey === 1 ? <DownOutlined /> : <RightOutlined />}
                      <span style={{ fontSize: "16px" }}>
                        {title}{" "}
                        <span style={{ fontSize: "10px;", fontWeight: "bold" }}>
                          ({selectedCount} selected)
                        </span>{" "}
                        -{" "}
                        {serialNumberGroupings.name ? (
                          <span style={{ fontSize: "14px" }}>
                            {serialNumberGroupings.name}
                          </span>
                        ) : (
                          <span style={{ fontSize: "14px", color: "red" }}>
                            not allocated
                          </span>
                        )}
                      </span>
                    </Col>
                    <Col
                      style={{
                        marginLeft: "auto",
                        marginRight: "0",
                      }}
                    >
                      {activeKey === 1 && serialNumberGroupings.name ? (
                        <>
                          <NextButton
                            onClick={() => confirm("skip")}
                            className="mr-[10px]"
                            size="sm"
                          >
                            {t("translation:skip")}
                          </NextButton>
                          <NextButton
                            onClick={() => confirm("confirm")}
                            color="primary"
                            size="sm"
                          >
                            {t("translation:confirm")} {selectedCount}
                          </NextButton>
                        </>
                      ) : null}
                    </Col>
                  </Row>
                }
                key="1"
              >
                {selectedSerialNumbers.length > 0 && (
                  <Row gutter={[10, 0]}>
                    {serialNumberGroupings.numbers.map((number) => (
                      <Col key={number}>
                        <span style={{ fontSize: "16px" }}>{`${number} `}</span>
                        {selectedSerialNumbers.find(
                          (element) => element.serialNumber === number,
                        )?.quantity === 1 ? (
                          <CheckOutlined
                            onClick={() => {
                              toggleSelectedSerialNumber(number, 0);
                            }}
                            style={{
                              color: "green",
                              fontSize: "20px",
                              marginBottom: "-2px",
                            }}
                          />
                        ) : (
                          <CloseOutlined
                            onClick={() => {
                              toggleSelectedSerialNumber(number, 1);
                            }}
                            style={{ color: "red", fontSize: "20px" }}
                          />
                        )}
                      </Col>
                    ))}
                  </Row>
                )}
              </Collapse.Panel>
            </Collapse>
          </Col>
        </Row>
      );
    default:
      return null;
  }
}

function getGroupingTitle(serialNumbers: number[]) {
  if (serialNumbers.length > 1) {
    const string = `${serialNumbers[0]} - ${
      serialNumbers[serialNumbers.length - 1]
    }`;
    return string;
  } else {
    return String(serialNumbers[0]);
  }
}
