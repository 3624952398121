import React, { useCallback } from "react";
import { Row, Col, Input } from "antd";
import { useTranslation } from "react-i18next";
import StatusFilter from "/app/src/components/generic/components/statusFilter";
interface Option {
  value: string | number;
  label: string;
  children?: Option[];
}
const options: Option[] = [
  {
    label: "All",
    value: "All",
    children: [
      {
        label: "Success",
        value: 1,
      },
      {
        label: "Error",
        value: 0,
      },
    ],
  },
];

/**
 * Component for displaying the controls for the logs table
 * Controls are search, date range, and status
 */
export default function Controls({
  setSearchString,
  setStatus,
}: {
  setSearchString: (searchString: string) => void;
  setStatus: React.Dispatch<React.SetStateAction<string>>;
}) {
  const { t } = useTranslation();
  const onChange = useCallback(
    (value) => {
      // if length of array is 0 or 2, set status to empty string
      // if length of array is 1, set status to value[0] - integer value of 0 or 1
      if (value.length === 0 || value.length === 2) {
        setStatus("");
      }
      if (value.length === 1) {
        setStatus(value[0][1]);
      }
    },
    [setStatus],
  );
  return (
    <Row gutter={[10, 10]} style={{ paddingBottom: "10px" }}>
      <Col
        xs={{ span: 24, order: 1 }}
        sm={{ span: 24, order: 1 }}
        md={{ span: 12, order: 1 }}
        lg={{ span: 12, order: 1 }}
      >
        <StatusFilter
          setStatus={setStatus}
          statusOptions={options}
          onChange={onChange}
        />
      </Col>
      <Col
        xs={{ span: 24, order: 1 }}
        sm={{ span: 24, order: 1 }}
        md={{ span: 12, order: 1 }}
        lg={{ span: 12, order: 1 }}
      >
        <Input.Search
          onSearch={setSearchString}
          size="large"
          placeholder={t("translation:search")}
        />
      </Col>
    </Row>
  );
}
