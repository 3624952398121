import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Log as LogType } from "/app/src/models";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";

/**
 * Map the status of a log to a string. Defaults to empty string
 */
function status(value: number, mappings: { 1?: string; 2?: string } = {}) {
  if (value in mappings) {
    return mappings[value];
  }
  return "";
}

/**
 * @returns Default columns for the log table
 */
export function DefaultColumns(): ColumnDef<LogType>[] {
  const { t } = useTranslation();

  const columnHelper = createColumnHelper<LogType>();
  const columns = useMemo(
    () => [
      columnHelper.accessor("status", {
        id: "status",
        cell: (info) => (
          <p>
            {status(info.getValue(), {
              1: t("translation:success_created"),
              2: t("translation:failed_created"),
            })}
          </p>
        ),
        header: () => t("translation:type"),
      }),
      columnHelper.accessor("description", {
        id: "description",
        cell: (info) => info.getValue(),
        header: () => t("translation:description"),
      }),
      columnHelper.accessor("error", {
        id: "error",
        cell: (info) => info.getValue(),
        header: () => t("translation:errors"),
      }),
    ],
    [columnHelper, t],
  );
  return columns;
}
