import React, { useCallback, useState } from "react";
import { Row, Col, Select } from "antd";
import NextDivider from "/app/src/components/NextUi/Divider";
import { useTranslation } from "react-i18next";
import AppMapper from "./appMapper";
import { WorkflowAction, App, Report } from "/app/src/models";

export default function Action({
  action = {},
  apps,
  isNew,
  addWorkflowAction,
  updateAction,
  removeWorkflowAction,
  reports,
}: {
  action?: WorkflowAction;
  apps: App[];
  isNew: boolean;
  addWorkflowAction: (action: WorkflowAction) => Promise<any>;
  updateAction: (action: WorkflowAction) => Promise<any>;
  removeWorkflowAction: (action: WorkflowAction) => Promise<any> | undefined;
  reports: Report[];
}) {
  const { t } = useTranslation();
  const [selectedApp, setSelectedApp] = useState(null);

  const handleOnChange = useCallback((value) => {
    setSelectedApp(value);
  }, []);

  const resetSelectedApp = useCallback(() => {
    setSelectedApp(null);
  }, []);
  return (
    <div className="workflowAction">
      {isNew && <h3>{t("translation:add_action")}</h3>}
      <Row justify="start" gutter={16}>
        <Col span={4}>
          {isNew ? (
            <Select
              style={{ width: "100%" }}
              size="large"
              placeholder={t("translation:select_app")}
              onChange={handleOnChange}
              value={selectedApp}
            >
              {apps.map((c) => (
                <Select.Option key={c.id} value={JSON.stringify(c)}>
                  {c.name}
                </Select.Option>
              ))}
            </Select>
          ) : (
            <h3>{action.appName}</h3>
          )}
        </Col>
        <Col span={20}>
          <AppMapper
            app={
              isNew && selectedApp
                ? JSON.parse(selectedApp)
                : { name: action.appName, id: action.appId }
            }
            action={action}
            isNew={isNew}
            addWorkflowAction={addWorkflowAction}
            updateAction={updateAction}
            removeWorkflowAction={removeWorkflowAction}
            reports={reports}
            resetSelectedApp={resetSelectedApp}
          />
        </Col>
      </Row>
      <NextDivider>{t("translation:then")}</NextDivider>
    </div>
  );
}
