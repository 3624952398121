import React from "react";
import { ConnectionState } from "./types";

const ConnectionsStatusContext = React.createContext<ConnectionState>({
  powerPick: 1,
  webServices: 1,
});

export const ConnectionsStatusProvider = ConnectionsStatusContext.Provider;
export const ConnectionsStatusConsumer = ConnectionsStatusContext.Consumer;

export default ConnectionsStatusContext;
