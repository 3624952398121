import { string, object } from "yup";
import i18next from "/app/src/locales/config";
import { commaSeparationValidation } from "/app/src/schemas/regularExpressions/regexPatterns";

export const sendTransactionsSchema = object().shape({
  data: string()
    .matches(
      commaSeparationValidation,
      i18next.t("translation:values_separated"),
    )
    .required(i18next.t("translation:required"))
    .trim(),
});
