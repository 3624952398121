import { Helmet } from "react-helmet";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import Controls from "./controls";
import { integrationService } from "/app/src/services";
import { Integration as IntegrationType, App } from "/app/src/models";
import DisplayRouter from "./displayRouter";
import { useQuery } from "@tanstack/react-query";
import { useNumberId } from "/app/src/hooks/useNumberId";

export default function GenericDisplay({ app }: { app: App }) {
  const { integrationId } = useNumberId();
  const handle = useFullScreenHandle();

  const { data: display } = useQuery({
    queryKey: ["integration", integrationId],
    queryFn: () => {
      return integrationService.getSingle(integrationId);
    },
    initialData: { integration: {} },
    select: (data: { integration: IntegrationType }) => {
      return data.integration;
    },
  });

  return (
    <div className="app" id="displays">
      {!display ? null : (
        <div className="integration">
          <Helmet>
            <title>{display?.name}</title>
          </Helmet>

          <FullScreen handle={handle} className="genericDisplay">
            <div className="fullscreenDiv">
              <Controls
                integration={display}
                app={app}
                buttonVisible
                handler={handle}
              />
              <DisplayRouter display={display} />
            </div>
          </FullScreen>
        </div>
      )}
    </div>
  );
}
