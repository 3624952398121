import { Helmet } from "react-helmet";
import { Route, Routes } from "react-router-dom";
import { Row, Col } from "antd";
import { integrationService } from "/app/src/services";
import { useQuery } from "@tanstack/react-query";
import SideMenuBox from "/app/src/components/generic/components/sideMenuBox";
import Settings from "./generalSettings";
import Mappings from "./mappings";
import { App, Integration as IntegrationType } from "/app/src/models";
import { useNumberId } from "/app/src/hooks/useNumberId";
import Connections from "./connection/connections";

export default function Integration({ app }: { app: App }) {
  const { integrationId } = useNumberId();
  // Get settings

  // Get integration
  const { data: integration } = useQuery({
    queryKey: ["integration", integrationId],
    queryFn: () => {
      return integrationService.getSingle(integrationId);
    },
    initialData: { integration: {} },
    select: (data: { integration: IntegrationType }) => {
      return data.integration;
    },
  });

  const links = [
    {
      path: `/apps/${app.id}/integrations/${integration.id}`,
      label: "settings",
    },
    {
      path: `/apps/${app.id}/integrations/${integration.id}/connections`,
      label: "connections",
    },
    {
      path: `/apps/${app.id}/integrations/${integration.id}/mappings`,
      label: "mappings",
    },
  ];

  return (
    <div className="integration">
      <Helmet>
        <title>{integration?.name}</title>
      </Helmet>

      {/* <Controls app={app} integration={integration} /> */}
      <Row gutter={20}>
        <Col span={6}>
          <SideMenuBox links={links} />
        </Col>
        <Col span={18}>
          {integration.id && (
            <Routes>
              <Route
                path="/"
                element={<Settings integration={integration} />}
              />
              <Route
                element={<Connections integration={integration} />}
                path="connections"
              />
              <Route
                path="mappings"
                element={<Mappings integration={integration} />}
              />
            </Routes>
          )}
        </Col>
      </Row>
    </div>
  );
}
