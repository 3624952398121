import { object, string } from "yup";
import i18next from "/app/src/locales/config";
import { backslashValidation } from "/app/src/schemas/regularExpressions/regexPatterns";

export const settingsSchema = object().shape({
  license: string().trim().required(i18next.t("translation:required")),
});

export const settingSchema = object().shape({
  value: string()
    .url(i18next.t("translation:invalid_url"))
    .matches(backslashValidation, i18next.t("translation:url_slash"))
    .trim()
    .required(i18next.t("translation:required")),
});
