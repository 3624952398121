import { Routes, Route } from "react-router-dom";
import ReportsAccessWrapper from "../report/reportAccessWrapper";
import ReportList from "./reportList";
import { useContextForPermission } from "../../hooks/usePermission";

export default function Reports() {
  const { canView } = useContextForPermission("REPO");

  return (
    <Routes>
      <Route path=":reportId/*" element={<ReportsAccessWrapper />} />
      <Route path="/" element={<ReportList hasPermission={canView} />} />
    </Routes>
  );
}
