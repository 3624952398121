import { useCallback } from "react";
import { Formik, FormikProps } from "formik";
import { Row, Col } from "antd";
import { Form, SubmitButton } from "formik-antd";
import { useTranslation } from "react-i18next";
import { materialPropertyService, settingService } from "/app/src/services";
import { MaterialProperty } from "/app/src/models";
import { SearchFilter } from "/app/src/components/generic/components/searchFilter";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { buildParams } from "/app/src/helpers";
import { genericMapper } from "../../generic/formatting/generic";
import { handlePromiseError } from "/app/src/helpers/api";

/**
 * Component to display the forms for updating the default material property
 */
export default function DefaultMaterialProperty() {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  //useQuery for getting the default material property
  const { data: defaultMaterialProperty } = useQuery({
    queryKey: ["defaultMaterialProperty"],
    queryFn: () => {
      return settingService
        .getAll(
          buildParams({
            number: 108,
            integrationId: null,
            name: "defaultMaterialProperty",
          }),
        )
        .then((response) => {
          return response.settings[0];
        });
    },
  });

  //useQuery for getting the list of material properties
  const { data: materialProperties } = useQuery({
    queryKey: ["materialProperties"],
    queryFn: () => {
      return materialPropertyService.getAll();
    },
    initialData: { material_properties: [] },
    select: (data: { material_properties: MaterialProperty[] }) => {
      return data.material_properties;
    },
  });
  //useMutation for updating the default material property
  const { mutateAsync: updateDefaultMaterialProperty } = useMutation({
    mutationFn: (values: { defaultMaterialProperty: string }) => {
      return settingService
        .updateSingle(defaultMaterialProperty.id, {
          value: values.defaultMaterialProperty,
        })
        .then(handlePromiseError);
    },
    onSuccess: (data) => {
      queryClient.setQueryData(["defaultMaterialProperty"], data.setting);
    },
  });

  //onSubmit handler for updating the default material property
  const onSubmitDefaultMaterialProperty = useCallback(
    async (
      { value: defaultMaterialProperty }: { value: string },
      {
        setFieldError,
      }: { setFieldError: (field: string, value: string) => void },
    ): Promise<void> => {
      await updateDefaultMaterialProperty({
        defaultMaterialProperty,
      }).catch((error) => {
        return setFieldError("defaultMaterialProperty", error.errors[0]);
      });
    },
    [updateDefaultMaterialProperty],
  );

  /**
   * Form to update the default Material Property setting
   */
  const updateDefaultMaterialPropertyForm: (
    props: FormikProps<{ value: string }>,
  ) => JSX.Element = useCallback(
    ({ dirty }) => (
      <Form layout="vertical">
        <Row justify="start" gutter={16}>
          <Col span={8}>
            <h3>{t("translation:default_material_property")}</h3>
            <p>{t("translation:default_material_property_description")}</p>
          </Col>
          <Col span={13}>
            <Form.Item
              name="value"
              label={t("translation:default_material_property")}
            >
              <SearchFilter
                list={materialProperties}
                name="value"
                placeholder={t("translation:select_default_material_property")}
                sort
                mapOptionsFn={genericMapper}
              />
            </Form.Item>
          </Col>
          <Col span={3}>
            <SubmitButton
              className="no-label" // skipcq: JS-0394
              type="primary"
              size="large"
              block
              disabled={!dirty}
            >
              {t("translation:save")}
            </SubmitButton>
          </Col>
        </Row>
      </Form>
    ),
    [t, materialProperties],
  );

  return (
    <Formik
      component={updateDefaultMaterialPropertyForm}
      initialValues={{
        value: defaultMaterialProperty ? defaultMaterialProperty.value : "",
      }}
      enableReinitialize
      onSubmit={onSubmitDefaultMaterialProperty}
    />
  );
}
