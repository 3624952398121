import { useCallback } from "react";
import { Formik, FormikProps } from "formik";
import { Row, Col } from "antd";
import { Form, SubmitButton, Input } from "formik-antd";

import { settingService } from "/app/src/services";
import { useTranslation } from "react-i18next";
import { App as AppType, Setting as SettingType } from "/app/src/models";
import { buildParams } from "/app/src/helpers/params";
import { simpleSchemaBuilder } from "/app/src/helpers";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { handlePromiseError } from "/app/src/helpers/api";

interface FormValues {
  hue_app_id: string | undefined;
  hue_client_id: string | undefined;
  hue_client_secret: string | undefined;
}

export default function Hue({ app }: { app: AppType }) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { data: clientId } = useQuery({
    queryKey: ["hueQuery", app.id],
    queryFn: () => {
      return settingService
        .getAll(buildParams({ number: 1, appId: app.id }))
        .then((response) => {
          if (response.settings.length > 0) {
            return response.settings[0];
          }
          return { value: "" };
        });
    },
    initialData: {},
    select: (data: SettingType) => {
      return data;
    },
  });

  const { data: clientSecret } = useQuery({
    queryKey: ["clientSecret", app.id],
    queryFn: () => {
      return settingService
        .getAll(buildParams({ number: 2, appId: app.id }))
        .then((response) => {
          if (response.settings.length > 0) {
            return response.settings[0];
          }
          return { value: "" };
        });
    },
    initialData: {},
    select: (data: SettingType) => {
      return data;
    },
  });

  const { data: hueAppId } = useQuery({
    queryKey: ["hueAppId", app.id],
    queryFn: () => {
      return settingService
        .getAll(buildParams({ number: 3, appId: app.id }))
        .then((response) => {
          if (response.settings.length > 0) {
            return response.settings[0];
          }
          return { value: "" };
        });
    },
    initialData: {},
    select: (data: SettingType) => {
      return data;
    },
  });

  const { mutateAsync: createSetting } = useMutation({
    mutationFn: (values: SettingType) => {
      return settingService.createSingle(values).then(handlePromiseError);
    },
  });
  const { mutateAsync: updateSetting } = useMutation({
    mutationFn: (values: SettingType) => {
      return settingService
        .updateSingle(values.id, values)
        .then(handlePromiseError);
    },
  });

  const invalidateQueries = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: ["hueQuery", app.id] });
    queryClient.invalidateQueries({ queryKey: ["clientSecret", app.id] });
    queryClient.invalidateQueries({ queryKey: ["hueAppId", app.id] });
  }, [app.id, queryClient]);

  const handleSubmit = useCallback(
    async (values: FormValues) => {
      const hue_client_id = {
        number: 1,
        name: "client_id",
        appId: app.id,
        value: values.hue_client_id,
      };
      const hue_client_secret = {
        number: 2,
        name: "client_secret",
        appId: app.id,
        value: values.hue_client_secret,
      };
      const hue_app_id = {
        number: 3,
        name: "app_id",
        appId: app.id,
        value: values.hue_app_id,
      };
      if (hueAppId?.id) {
        await updateSetting({
          id: hueAppId.id,
          ...hue_app_id,
        });
      } else {
        await createSetting(hue_app_id);
      }
      if (clientId?.id) {
        await updateSetting({
          id: clientId.id,
          ...hue_client_id,
        });
      } else {
        await createSetting(hue_client_id);
      }
      if (clientSecret?.id) {
        await updateSetting({
          id: clientSecret.id,
          ...hue_client_secret,
        });
      } else {
        await createSetting(hue_client_secret);
      }
      invalidateQueries();
    },
    [
      app.id,
      clientId.id,
      clientSecret.id,
      createSetting,
      hueAppId.id,
      invalidateQueries,
      updateSetting,
    ],
  );

  const hueSettingsForm: (props: FormikProps<FormValues>) => JSX.Element =
    useCallback(
      ({ dirty }) => (
        <Form layout="vertical">
          <h3>
            {app.name} - {t("translation:authentication")}
          </h3>
          <Row justify="start" gutter={16}>
            <Col span={12}>
              <Form.Item
                name="hue_client_id"
                label={t("translation:client_id")}
              >
                <Input name="hue_client_id" className="user" size="large" />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="hue_client_secret"
                label={t("translation:client_secret")}
              >
                <Input
                  name="hue_client_secret"
                  className="token"
                  size="large"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="start" gutter={16}>
            <Col span={12}>
              <Form.Item name="hue_app_id" label={t("translation:app_id")}>
                <Input name="hue_app_id" className="user" size="large" />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="start" gutter={16}>
            <Col span={24}>
              <SubmitButton type="primary" size="large" block disabled={!dirty}>
                {t("translation:save")}
              </SubmitButton>
            </Col>
          </Row>
        </Form>
      ),
      [app.name, t],
    );
  return (
    <div className="appSettings">
      <Formik
        component={hueSettingsForm}
        initialValues={{
          hue_client_id: clientId.value,
          hue_client_secret: clientSecret.value,
          hue_app_id: hueAppId.value,
        }}
        enableReinitialize
        validationSchema={simpleSchemaBuilder([
          { name: "hue_client_id", type: "string", required: true },
          { name: "hue_client_secret", type: "string", required: true },
          { name: "hue_app_id", type: "string", required: true },
        ])}
        onSubmit={handleSubmit}
      />
    </div>
  );
}
