import { genericGetSingle, genericGetAll } from "../helpers/apiCall";
import { Shelf } from "../models";

/**
 * Get all shelves
 * @param params URLSearchParams
 * @returns Promise<{ shelves: Shelf[] }>
 */
function getAll(params: URLSearchParams = null): Promise<{ shelves: Shelf[] }> {
  return genericGetAll("shelves", params);
}

/**
 * Get a single shelf
 * @param id string
 * @returns Promise<{ shelf: Shelf }>
 */
function getSingle(id: string): Promise<{ shelf: Shelf }> {
  return genericGetSingle("shelves", id);
}

/**
 * Gets the count of shelves
 * @param params URLSearchParams
 * @returns Promise<{ count: number }>
 */
function getCount(params: URLSearchParams = null): Promise<{ count: number }> {
  params.append("countOnly", "true");
  return genericGetAll("shelves", params);
}

export const shelfService = {
  getAll,
  getSingle,
  getCount,
};
