import { Connection, ConnectionTest } from "../models";
import { Error } from "../types";
import {
  makeAPIRequest,
  genericGetSingle,
  genericCreateSingle,
  genericDeleteSingle,
  genericGetAll,
  genericUpdateSingle,
} from "../helpers/apiCall";

/**
 * Fetches all connections
 * @param params Query parameters
 */
function getAll(
  params: URLSearchParams = null,
): Promise<{ connections: Connection[] }> {
  return genericGetAll("connections", params);
}

/**
 * Fetches a single connection
 * @param id Connection ID
 */
function getSingle(id: number | string): Promise<{ connection: Connection }> {
  return genericGetSingle("connections", id);
}

/**
 * Tests a connection
 * @param id Connection ID to test
 * @param params Query parameters
 */
function testSingle(
  id: number | string,
  params: { [key: string]: string | number | boolean | Date | undefined } = {},
): Promise<ConnectionTest> {
  return makeAPIRequest({
    url: `/connections/${id}/test`,
    params,
    method: "get",
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

/**
 * Updates a single connection
 * @param id id of connection to update
 * @param connection connection object to update
 */
function updateSingle(
  id: number | string,
  connection: Connection,
): Promise<{ connection: Connection } | Error> {
  return genericUpdateSingle("connections", id, connection);
}

/**
 * Creates a single connection
 * @param connection connection object to create
 */
function createSingle(connection: {
  [key: string]: string | number | boolean | Date | undefined;
}): Promise<{ connection: Connection } | Error> {
  return genericCreateSingle("connections", connection);
}

/**
 * Deletes a single connection
 * @param id id of connection to delete
 */
function deleteSingle(id: number | string): Promise<{ deleted: boolean }> {
  return genericDeleteSingle("connections", id);
}

/**
 * Gets the count of connections
 * @param params the query parameters
 */
function getCount(params: URLSearchParams = null): Promise<{ count: number }> {
  params.append("countOnly", "true");
  return genericGetAll("connections", params);
}

export const connectionService = {
  getAll,
  getSingle,
  testSingle,
  updateSingle,
  createSingle,
  deleteSingle,
  getCount,
};
