import React, { useCallback } from "react";
import copyIcon from "/app/src/components/generic/title/copyIcon.svg";
import { reportService } from "/app/src/services";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { buildParams } from "/app/src/helpers/params";
import { useQueryClient } from "@tanstack/react-query";
import NextButton from "/app/src/components/NextUi/Button";
import NextModal from "/app/src/components/NextUi/Modal";
import ModalBuilder from "/app/src/components/NextUi/helpers/ModalBuilder";
import { useDisclosure } from "@nextui-org/react";

/**
 * Returns a button that duplicates a report
 * @param param0 Id of report to duplicate
 */
export default function DuplicateReport({ reportId }: { reportId: number }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  /**
   * Duplicate a report, invalidate the cache and navigate to the new report
   */
  const duplicateReport = useCallback(() => {
    reportService
      .duplicateReport({ reportId }, buildParams({ copy: "True" }))
      .then((response) => {
        queryClient.invalidateQueries({ queryKey: ["reports"] });
        queryClient.invalidateQueries({ queryKey: ["reportsCount"] });
        navigate(`/reports/${response.report.id}`);
      });
  }, [reportId, queryClient, navigate]);
  return (
    <>
      <button className="buttonless" onClick={onOpen} onKeyDown={onOpen}>
        <img style={{ marginRight: "10px" }} src={copyIcon} alt="Copy" />
      </button>
      <NextModal
        disableAnimation={false}
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        placement="top"
      >
        {ModalBuilder({
          warning: true,
          modalHeader: t("translation:confirm_copy_report"),
          modalFooter: (
            <>
              <NextButton
                size="sm"
                color="default"
                variant="bordered"
                onClick={onOpenChange}
              >
                {t("translation:do_not_copy")}
              </NextButton>
              <NextButton size="sm" color="primary" onClick={duplicateReport}>
                {t("translation:copy")}
              </NextButton>
            </>
          ),
        })}
      </NextModal>
    </>
  );
}
