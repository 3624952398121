import { ResponsiveBar, BarDatum, ComputedDatum } from "@nivo/bar";
import { CartesianMarkerProps, DatumValue } from "@nivo/core";
import { useMemo } from "react";
import { BarChartData } from "./types";

/**
 * getColor - gets the colour of the passed in bar
 * @param bar the bar to get the colour for
 * @returns colour of the bar
 */
function getColor(bar: ComputedDatum<BarDatum>) {
  const colorKey = `${bar.id}Color`;
  return bar.data[colorKey] as string;
}

/**
 * component to display a Nivo Bar Chart
 * @param data the data to display
 * @param trendLines the trendlines(Nivo markers) to display
 * @param isStacked whether the chart is stacked or grouped
 */
export default function BarChart({
  data,
  trendLines,
  isStacked,
}: {
  data: BarChartData;
  trendLines: CartesianMarkerProps<DatumValue>[];
  isStacked: boolean;
}) {
  const maxFoundValue = useMemo(() => {
    //find the highest value in the data
    let highestValue = 0;
    for (const datum of data.data) {
      for (const key of data.keys) {
        if ((datum[key] as number) > highestValue) {
          highestValue = datum[key] as number;
        }
      }
    }
    //see if any of the trendlines are higher
    trendLines.forEach((trendLine) => {
      if ((trendLine.value as number) > highestValue) {
        highestValue = trendLine.value as number;
      }
    });
    return highestValue;
  }, [data.data, data.keys, trendLines]);

  return (
    <div className="chart">
      <ResponsiveBar
        data={data.data}
        keys={data.keys}
        colors={
          data?.groupingType === "subgrouped" ? { scheme: "paired" } : getColor
        }
        maxValue={maxFoundValue}
        groupMode={isStacked ? "stacked" : "grouped"}
        legends={
          data?.groupingType === "subgrouped"
            ? [
                {
                  dataFrom: "keys",
                  anchor: "bottom-right",
                  direction: "column",
                  justify: false,
                  translateX: 120,
                  translateY: 0,
                  itemsSpacing: 2,
                  itemWidth: 100,
                  itemHeight: 20,
                  itemDirection: "left-to-right",
                  symbolSize: 20,
                },
              ]
            : []
        }
        indexBy={"label"}
        enableGridY
        gridYValues={[0]}
        padding={0.3}
        margin={
          data.groupingType === "subgrouped"
            ? { top: 5, right: 70, bottom: 85, left: 50 }
            : { top: 5, right: 0, bottom: 85, left: 50 }
        }
        borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
        axisBottom={{
          tickSize: 10,
          tickPadding: 10,
          tickRotation: -35,
        }}
        markers={trendLines}
        theme={{
          axis: {
            ticks: {
              text: {
                fontSize: "10px",
              },
            },
          },
        }}
      />
    </div>
  );
}
