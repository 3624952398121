import { Input, Form } from "formik-antd";

/**
 *
 * @returns ReportColumn component
 */
export default function ReportColumn() {
  return (
    <Form.Item className="w-full !mb-0 " name="value1" hasFeedback={false}>
      <Input size="large" name="value1" placeholder="" />
    </Form.Item>
  );
}
