import React from "react";
import { TimeZoneState } from "./types";

const TimeZoneContext = React.createContext<TimeZoneState>({
  userTimeZone: undefined,
  globalTimeZone: undefined,
  timeZone: undefined,
  powerPickTimeZone: undefined,
});

export const TimeZoneProvider = TimeZoneContext.Provider;
export const TimeZoneConsumer = TimeZoneContext.Consumer;

export default TimeZoneContext;
