import React from "react";
import { LicenseType } from "./types";

const LicenseContext = React.createContext<LicenseType>({
  license: undefined,
  powerPickVersion: undefined,
});

export const LicenseProvider = LicenseContext.Provider;
export const LicenseConsumer = LicenseContext.Consumer;

export default LicenseContext;
