import { settingService } from "/app/src/services";
import { Setting as SettingType } from "/app/src/models";
import { buildParams } from "/app/src/helpers/params";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { handlePromiseError } from "/app/src/helpers/api";

/**
 *
 * @param params
 * @returns Settings
 */
export default function useSettings(params) {
  const queryClient = useQueryClient();

  const { data: settings } = useQuery({
    queryKey: ["settings", params],
    queryFn: () => {
      return settingService
        .getAll(buildParams(params))
        .then(handlePromiseError);
    },
    initialData: { settings: [] as SettingType },
    select: (data) => {
      return data.settings;
    },
  });

  const { mutateAsync: createSetting } = useMutation({
    mutationFn: (values: SettingType) => {
      return settingService.createSingle(values).then(handlePromiseError);
    },
    onSuccess: (response) => {
      queryClient.setQueryData(
        ["settings", params],
        (oldData: { settings: SettingType[] }) => {
          return { settings: [...oldData.settings, response.setting] };
        },
      );
    },
  });

  const { mutateAsync: updateSetting } = useMutation({
    mutationFn: (values: SettingType) => {
      return settingService
        .updateSingle(values.id, values)
        .then(handlePromiseError);
    },
    onSuccess: (response) => {
      queryClient.setQueryData(
        ["settings", params],
        (oldData: { settings: SettingType[] }) => {
          const newSettings = oldData.settings.map((setting) => {
            if (setting.id === response.setting.id) {
              return response.setting;
            }
            return setting;
          });
          return { settings: newSettings };
        },
      );
    },
  });

  const { mutateAsync: deleteSetting } = useMutation({
    mutationFn: (id: number) => {
      return settingService
        .deleteSingle(id)
        .then(handlePromiseError)
        .then(() => {
          return { setting: { id } };
        });
    },
    onSuccess: (response) => {
      queryClient.setQueryData(
        ["settings", params],
        (oldData: { settings: SettingType[] }) => {
          return {
            settings: oldData.settings.filter(
              (setting) => setting.id !== response.setting.id,
            ),
          };
        },
      );
    },
  });

  return {
    settings,
    createSetting,
    updateSetting,
    deleteSetting,
  };
}
