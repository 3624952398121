import {
  genericGetSingle,
  genericCreateSingle,
  genericDeleteSingle,
  genericGetAll,
  genericUpdateSingle,
} from "../helpers/apiCall";
import { Trigger } from "../models";
import { Error } from "../types";

/**
 * Gets all triggers
 * @param params URLSearchParams
 * @returns Promise<{ triggers: Trigger[] }>
 */
function getAll(
  params: URLSearchParams = null,
): Promise<{ triggers: Trigger[] }> {
  return genericGetAll("triggers", params);
}

/**
 * Gets a single trigger
 * @param id number | string
 * @returns Promise<{ trigger: Trigger }>
 */
function getSingle(id: number | string): Promise<{ trigger: Trigger }> {
  return genericGetSingle("triggers", id);
}

/**
 * Update a single trigger
 * @param id number | string
 * @param trigger { [key: string]: string | number | boolean | Date | undefined}
 * @returns Promise<{ trigger: Trigger } | Error>
 */
function updateSingle(
  id: number | string,
  trigger: { [key: string]: string | number | boolean | Date | undefined },
): Promise<{ trigger: Trigger } | Error> {
  return genericUpdateSingle("triggers", id, trigger);
}

/**
 * Create a new trigger
 * @param trigger { [key: string]: string | number | boolean | Date | undefined}
 * @returns Promise<{ trigger: Trigger } | Error>
 */
function createSingle(trigger: {
  [key: string]: string | number | boolean | Date | undefined;
}): Promise<{ trigger: Trigger } | Error> {
  return genericCreateSingle("triggers", trigger);
}

/**
 * Delete a single trigger
 * @param id number | string
 * @returns Promise<{ deleted: boolean } | { error: string }>
 */
function deleteSingle(
  id: number | string,
): Promise<{ deleted: boolean } | { error: string }> {
  return genericDeleteSingle("triggers", id);
}

/**
 * Gets the count of triggers
 * @param params URLSearchParams
 * @returns Promise<{ count: number }>
 */
function getCount(params: URLSearchParams = null): Promise<{ count: number }> {
  params.append("countOnly", "true");
  return genericGetAll("triggers", params);
}

export const triggerService = {
  getAll,
  getSingle,
  updateSingle,
  createSingle,
  deleteSingle,
  getCount,
};
