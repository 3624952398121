import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { Helmet } from "react-helmet";
import { userService } from "/app/src/services";
import { Row, Col, message } from "antd";
import { Formik, FormikProps } from "formik";
import { Form, SubmitButton, Input } from "formik-antd";
import { newFirstUserSchema } from "/app/src/schemas";
import { useTranslation } from "react-i18next";
import { handleSubmissionErrors } from "/app/src/helpers/forms";
import { useQueryClient } from "@tanstack/react-query";

interface FormValues {
  username: string;
  email: string;
  password: string;
  key: string;
  license: string;
}

/**
 * Component for creating the first user. Also includes fields for the license and recovery key
 */
export default function NewSignup() {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const handleSubmit = useCallback(
    (values, actions) => {
      userService.createSingle(values).then((response) => {
        //check if creation successful
        if ("errors" in response) {
          handleSubmissionErrors(response.errors, actions.setFieldError);
          actions.setSubmitting(false);
        } else {
          message.success(t("translation:user_created"));
          //update the user count so the user does not get redirected back
          //to the new user form
          const userCount: number = queryClient.getQueryData(["userCount"]);
          queryClient.setQueryData(["userCount"], userCount + 1);
          navigate("/login");
        }
      });
    },
    [navigate, queryClient, t],
  );

  /**
   * Form for formik component - contains all the fields for the new user form
   */
  const newUserForm: (props: FormikProps<FormValues>) => JSX.Element =
    useCallback(
      ({ errors, touched, isValid, dirty }) => (
        <Form layout="vertical">
          <Form.Item name="username" label={t("translation:username")}>
            <Input
              suffix
              name="username"
              size="large"
              className={
                errors.username && touched.username ? "input-error" : undefined
              }
            />
          </Form.Item>
          <Form.Item
            name="email"
            label={t("translation:email_address")}
            className={
              errors.email && touched.email ? "input-error" : undefined
            }
          >
            <Input suffix name="email" size="large" />
          </Form.Item>
          <Form.Item name="password" label={t("translation:password")}>
            <Input.Password
              name="password"
              size="large"
              className={
                errors.password && touched.password ? "input-error" : undefined
              }
            />
          </Form.Item>
          <Form.Item
            name="key"
            label={t("translation:recovery_key")}
            className={errors.key && touched.key ? "input-error" : undefined}
          >
            <Input suffix name="key" size="large" />
          </Form.Item>
          <Form.Item name="license" label={t("translation:license")}>
            <Input suffix name="license" size="large" />
          </Form.Item>
          <SubmitButton
            type="primary"
            size="large"
            disabled={!(dirty && isValid)}
          >
            {t("translation:sign_up")}
          </SubmitButton>
        </Form>
      ),
      [t],
    );

  return (
    <div className="login">
      <Helmet>
        <title>{t("translation:sign_up")} - ItemPath</title>
      </Helmet>
      <Row>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 20, offset: 2 }}
          lg={{ span: 8, offset: 8 }}
        >
          <div className="box mt-4">
            <h3 style={{ textAlign: "center", margin: "20px 0 30px" }}>
              ItemPath {t("translation:user")} {t("translation:sign_up")}
            </h3>
            <Formik
              enableReinitialize
              component={newUserForm}
              validationSchema={newFirstUserSchema}
              initialValues={{
                username: "",
                email: "",
                password: "",
                key: "",
                license: "",
              }}
              onSubmit={handleSubmit}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
}
