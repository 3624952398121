import { useContext, useEffect, useState } from "react";
import ConnectionsStatusContext from "../../contexts/ConnectionsStatusContext";
import { useQuery } from "@tanstack/react-query";
import { reportService } from "/app/src/services";
import { Report as ReportType } from "/app/src/models";
import { Helmet } from "react-helmet";
import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import Report from ".";

type LockedString = "Locked" | "Unlocked" | "Loading";

/**
 * @param param reportId
 * @returns Report component if the report is unlocked, otherwise a message saying the report is locked
 */
export default function LockedReport({ reportId }: { reportId: number }) {
  const { powerPick } = useContext(ConnectionsStatusContext);
  const [isLocked, setIsLocked] = useState<LockedString>("Loading");
  const { t } = useTranslation();

  const { data: report, isFetching } = useQuery({
    queryKey: ["report", reportId],
    queryFn: () => {
      return reportService.getSingle(reportId);
    },
    initialData: {
      report: { columns: [], snapshots: [], favouritedUsers: [] },
    },
    enabled: Boolean(reportId),
    select: (data: { report: ReportType }) => data.report,
  });

  useEffect(() => {
    if (!isFetching) {
      if (
        !powerPick &&
        !["Log", "APICall", "Task"].includes(report.baseTable)
      ) {
        setIsLocked("Locked");
      } else {
        setIsLocked("Unlocked");
      }
    }
  }, [isFetching, powerPick, report]);

  if (isLocked === "Loading") {
    return <div>Loading...</div>;
  } else if (isLocked === "Locked") {
    return (
      <div className="login">
        <Helmet>
          <title>
            {t("translation:report")} {t("translation:disabled")} - ItemPath
          </title>
        </Helmet>
        <Row>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 20, offset: 2 }}
            lg={{ span: 10, offset: 7 }}
          >
            <div className="box mt-4">
              <h3 style={{ textAlign: "center", margin: "20px 0 30px" }}>
                {`${t("translation:powerpick_database_connection_failed")} ${t(
                  "translation:viewing_report_details_disabled",
                )}`}
              </h3>
            </div>
          </Col>
        </Row>
      </div>
    );
  } else {
    return <Report reportId={reportId} />;
  }
}
