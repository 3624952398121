import React from "react";
import { useTranslation } from "react-i18next";

/**
 * Simple component to display the title of the app list page
 */
export default function Controls() {
  const { t } = useTranslation();
  return (
    <div className="controls">
      <h1>{t("translation:available_apps")}</h1>
    </div>
  );
}
