import React, { useCallback } from "react";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { Row, Col } from "antd";
import { Form, Input, SubmitButton } from "formik-antd";
import { useTranslation } from "react-i18next";
import { Status as StatusT } from "/app/src/models";
import { statusService } from "/app/src/services";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { handlePromiseError } from "/app/src/helpers/api";
import { simpleSchemaBuilder } from "/app/src/helpers";

interface FormValues {
  name: string | undefined;
  description: string | undefined;
  type: "custom";
}

/**
 * Component for adding a new status to Order Builder
 */
export default function NewStatus() {
  const { t } = useTranslation();

  const queryClient = useQueryClient();

  const { mutateAsync: addStatus } = useMutation({
    mutationFn: (values: StatusT) => {
      return statusService.createSingle(values).then(handlePromiseError);
    },
    onSuccess: (data) => {
      queryClient.setQueryData(
        ["statuses", "custom"],
        (oldData: { statuses: StatusT[] }) => {
          // Sort the statuses by position
          const updatedStatuses = [...oldData.statuses].sort(
            (a, b) => Number(a.position) - Number(b.position),
          );
          const newPosition = updatedStatuses.length - 2;
          //the new status position will be the 3rd last position, as the two default statuses
          //are always the last two positions - Ready to be sent and Sent to Power Pick
          if (updatedStatuses.length >= 2) {
            updatedStatuses[updatedStatuses.length - 1] = {
              ...updatedStatuses[updatedStatuses.length - 1],
              position:
                updatedStatuses[updatedStatuses.length - 1].position + 1,
            };
            updatedStatuses[updatedStatuses.length - 2] = {
              ...updatedStatuses[updatedStatuses.length - 2],
              position:
                updatedStatuses[updatedStatuses.length - 2].position + 1,
            };
          }

          return {
            statuses: [
              ...updatedStatuses,
              {
                ...data.status,
                position: newPosition,
              },
            ],
          };
        },
      );
    },
  });

  const addColumnHandler = useCallback(
    async (values: FormValues, actions: FormikHelpers<FormValues>) => {
      await addStatus(values).then(() => {
        actions.resetForm();
      });
    },
    [addStatus],
  );

  const newColumnForm: (props: FormikProps<FormValues>) => JSX.Element =
    useCallback(
      ({ dirty, isSubmitting }) => (
        <Form>
          <Row justify="start" gutter={16}>
            <Col span={8}>
              <Form.Item name="name" hasFeedback={false}>
                <Input
                  name="name"
                  placeholder={t("translation:name")}
                  size="large"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="description" hasFeedback={false}>
                <Input
                  name="description"
                  placeholder={t("translation:description")}
                  size="large"
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <SubmitButton
                type="primary"
                size="large"
                block
                disabled={!dirty || isSubmitting}
              >
                {t("translation:add_status")}
              </SubmitButton>
            </Col>
          </Row>
        </Form>
      ),
      [t],
    );
  return (
    <Formik
      component={newColumnForm}
      initialValues={{
        name: "",
        description: "",
        type: "custom",
      }}
      onSubmit={addColumnHandler}
      validationSchema={simpleSchemaBuilder([
        { name: "name", required: true, type: "string" },
        { name: "description", required: false, type: "string" },
      ])}
    />
  );
}
