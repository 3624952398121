import React from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "antd";
import { integrationService } from "/app/src/services";
import { useQuery } from "@tanstack/react-query";
import { Integration } from "/app/src/models";
import { isHTTPSuccess } from "/app/src/helpers/api";
import Spin from "/app/src/components/NextUi/Spin";

/**
 * Component to allow the user to send many transactions at once using a CSV string
 */
export default function HistoryRecord({
  transactionId,
  integration,
}: {
  transactionId: string;
  integration: Integration;
}) {
  const { t } = useTranslation();
  const runIntegrationQuery = useQuery({
    queryKey: ["runIntegration", transactionId],
    queryFn: () => {
      return integrationService.runIntegration(integration.id, {
        historyId: transactionId,
      });
    },
    initialData: { data: "", received: "" },
  });

  if (runIntegrationQuery.isLoading || runIntegrationQuery.isFetching) {
    return (
      <Row className="transaction-row" gutter={20}>
        <Col span={4}>{transactionId}</Col>
        <Col span={16}>
          <Spin size="sm" />
        </Col>
        <Col span={4} />
      </Row>
    );
  } else {
    return (
      <Row className="transaction-row" gutter={20}>
        <Col span={3}>{transactionId}</Col>
        {runIntegrationQuery.data?.error ? (
          <Col span={9}>{runIntegrationQuery.data?.error}</Col>
        ) : (
          <Col span={9}>
            {JSON.stringify(runIntegrationQuery.data?.data, null, 2)}
          </Col>
        )}
        {runIntegrationQuery.data?.received ? (
          <Col span={9}>{runIntegrationQuery.data?.received}</Col>
        ) : (
          <Col span={9} />
        )}
        <Col span={3}>
          {isHTTPSuccess(runIntegrationQuery.data?.code) ? (
            <span style={{ color: "green" }}>{t("translation:success")}</span>
          ) : (
            <span style={{ color: "red" }}>{t("translation:failed")}</span>
          )}
        </Col>
      </Row>
    );
  }
}
