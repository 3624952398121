import { useQuery } from "@tanstack/react-query";
import { buildParams } from "/app/src/helpers";
import { taskService } from "/app/src/services";
import { Task } from "/app/src/models";

/**
 * Hook to get logs, logsCount, and isLoading for a given set of query params
 */
export function useTasks(
  queryParams: { [key: string]: string | number },
  queryCountParams: { [key: string]: string | number },
  refresh,
) {
  const { data: tasks, isLoading } = useQuery({
    queryKey: ["tasks", queryParams],
    queryFn: () => {
      return taskService.getAll(buildParams(queryParams));
    },
    initialData: { tasks: [] },
    select: (data: { tasks: Task[] }) => {
      return data.tasks;
    },
    refetchInterval: refresh ? 10000 : false,
  });

  const { data: tasksCount } = useQuery({
    queryKey: ["tasksCount", queryCountParams],
    queryFn: () => {
      return taskService.getCount(buildParams(queryCountParams));
    },
    initialData: { count: 0 },
    select: (data: { count: number }) => {
      return data.count;
    },
  });

  return { tasks, isLoading, tasksCount };
}
