import { Col, Row } from "antd";
import { Form, Input } from "formik-antd";
import { useTranslation } from "react-i18next";

/**
 * SQL Table
 * @returns SQL Table input
 */
export default function SQLTable() {
  const { t } = useTranslation();
  return (
    <Row justify="start" gutter={16} className="flex items-center">
      <Col span={8}>
        <h3>{t("translation:sql_table")}</h3>
        <p>{t("translation:sql_table_description")}</p>
      </Col>
      <Col span={16}>
        <Form.Item
          name="sqlTable"
          label={t("translation:sql_table")}
          hasFeedback={false}
        >
          <Input name="sqlTable" size="large" />
        </Form.Item>
      </Col>
    </Row>
  );
}
