import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter } from "react-router-dom";
import { AxiosWrapper } from "./AxiosWrapper";
import RollbarWrapper from "./rollbarWrapper";
import AuthWrappers from "./authWrappers";
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
    },
  },
});
/**
 * Wraps the the App with the following:
 * - BrowserRouter
 * - AuthWrappers
 * - AxiosWrapper
 * - RollbarWrapper
 * - QueryClientProvider
 */
export default function AppWrappers({ children }: { children: JSX.Element[] }) {
  return (
    <BrowserRouter>
      <AuthWrappers>
        <AxiosWrapper />
        <RollbarWrapper>
          <QueryClientProvider client={queryClient}>
            {children}
          </QueryClientProvider>
        </RollbarWrapper>
      </AuthWrappers>
    </BrowserRouter>
  );
}
