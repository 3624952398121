import { genericGetSingle, genericGetAll } from "../helpers/apiCall";
import { Station } from "../models";

/**
 * Gets all stations
 * @param params URLSearchParams
 * @returns Promise<{ stations: Station[] }>
 */
function getAll(
  params: URLSearchParams = null,
): Promise<{ stations: Station[] }> {
  return genericGetAll("stations", params);
}

/**
 * Gets a single station
 * @param id string
 * @returns Promise<{ station: Station }>
 */
function getSingle(id: string): Promise<{ station: Station }> {
  return genericGetSingle("stations", id);
}

/**
 * Gets the count of stations
 * @param params URLSearchParams
 * @returns Promise<{ count: number }>
 */
function getCount(params: URLSearchParams = null): Promise<{ count: number }> {
  params.append("countOnly", "true");
  return genericGetAll("stations", params);
}

export const stationService = {
  getAll,
  getSingle,
  getCount,
};
