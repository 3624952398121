import {
  makeAPIRequest,
  genericGetSingle,
  genericCreateSingle,
  genericDeleteSingle,
  genericGetAll,
  genericUpdateSingle,
} from "../helpers/apiCall";
import { Report } from "../models";

/**
 * Gets all reports
 * @param params URLSearchParams
 * @returns Promise<{ reports: Report[] }>
 */
function getAll(
  params: URLSearchParams = null,
): Promise<{ reports: Report[] }> {
  return genericGetAll("reports", params);
}

/**
 * Gets a single report
 * @param id number | string
 * @returns Promise<{ report: Report }>
 */
function getSingle(id: number | string): Promise<{ report: Report }> {
  return genericGetSingle("reports", id);
}

/**
 * Creates a single report
 * @param report { [key: string]: string | number | boolean | Date | undefined}
 * @returns Promise<{ report: Report }>
 */
function createSingle(report: {
  [key: string]: string | number | boolean | Date | undefined;
}): Promise<{ report: Report }> {
  return genericCreateSingle("reports", report);
}

/**
 * Exports a single report
 * @param id number | string
 * @param params URLSearchParams
 */
function exportSingle(id: number | string, params: URLSearchParams = null) {
  /**
   * Exports the report of the passed in report id.
   * @param {!number | string} id id of the model to be fetched.
   * @param {!URLSearchParams} params URLSearchParams to be converted to query string.
   * @return {Promise<any>} Returns the promise containing data of API response.
   */
  return makeAPIRequest({
    url: `/reports/${id}`,
    method: "get",
    params,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}
/**
 * Imports a single report
 * @param report { [key: string]: string | number | boolean | Date | undefined}
 * @param params URLSearchParams
 * @returns Promise<{ report: Report }>
 */
function importReport(
  report: { [key: string]: string | number | boolean | Date | undefined },
  params: URLSearchParams = null,
) {
  /**
   * Imports the report of the passed in report id.
   * @param {{ [key: string]: string | number | boolean | Date | undefined }} report report to be imported.
   * @param {URLSearchParams} params URLSearchParams to be converted to query string.
   * @return {Promise<any>} Returns the promise containing data of API response.
   */
  return makeAPIRequest({
    url: "/reports",
    method: "post",
    params,
    body: report,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

/**
 * Duplicates a single report
 * @param report { [key: string]: string | number | boolean | Date | undefined}
 * @param params URLSearchParams
 */
function duplicateReport(
  report: { [key: string]: string | number | boolean | Date | undefined },
  params: URLSearchParams = null,
) {
  /**
   * Duplicates the report of the passed in report id.
   * @param {{ [key: string]: string | number | boolean | Date | undefined }} report report to be duplicated.
   * @param {URLSearchParams} params URLSearchParams to be converted to query string.
   * @return {Promise<any>} Returns the promise containing data of API response.
   */
  return makeAPIRequest({
    url: "/reports",
    method: "post",
    params,
    body: report,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

/**
 * Updates a single report
 * @param id number | string
 * @param report { [key: string]: string | number | boolean | Date | undefined}
 * @returns Promise<{ report: Report }>
 */
function updateSingle(
  id: number | string,
  report: { [key: string]: string | number | boolean | Date | undefined },
): Promise<{ report: Report }> {
  return genericUpdateSingle("reports", id, report);
}

/**
 * Deletes a single report
 * @param id number | string
 * @returns Promise<{ deleted: boolean } | { error: string }>
 */
function deleteSingle(
  id: number | string,
): Promise<{ deleted: boolean } | { error: string }> {
  return genericDeleteSingle("reports", id);
}

/**
 * Gets the count of reports
 * @param params URLSearchParams
 * @returns Promise<{ count: number }>
 */
function getCount(params: URLSearchParams = null): Promise<{ count: number }> {
  params.append("countOnly", "true");
  return genericGetAll("reports", params);
}

export const reportService = {
  getAll,
  getSingle,
  createSingle,
  updateSingle,
  deleteSingle,
  exportSingle,
  duplicateReport,
  importReport,
  getCount,
};
