import { makeAPIRequest } from "../helpers/apiCall";
import { UserReport } from "../models";

/**
 * Get the reports that a user has favourited
 * @param userId the id of the user
 * @returns the reports that the user has favourited
 */
function getFavouritedReports(
  userId: number,
  params: URLSearchParams = null,
): Promise<{ reports: UserReport[] }> {
  return makeAPIRequest({
    url: `/users/${userId}/reports`,
    method: "get",
    params,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

/**
 * Function to make API request to favourite a report
 * @param userId the id of the user
 * @param reportId the id of the report to favourite
 */
function favouriteReport(
  userId: number,
  reportId: number,
): Promise<UserReport> {
  return makeAPIRequest({
    url: `/users/${userId}/reports/${reportId}`,
    method: "post",
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

/**
 * Function to make API request to unfavourite a report
 * @param userId the id of the user
 * @param reportId the id of the report to unfavourite
 * @returns Promise<UserReport>
 */
function unfavouriteReport(
  userId: number,
  reportId: number,
): Promise<UserReport> {
  return makeAPIRequest({
    url: `/users/${userId}/reports/${reportId}/delete`,
    method: "delete",
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

/**
 *Count of reports favourited by user
 * @param userId number
 * @param params URLSearchParams
 * @returns Promise<{ count: number }>
 */
function getCount(
  userId: number,
  params: URLSearchParams = null,
): Promise<{ count: number }> {
  params.append("countOnly", "true");
  return makeAPIRequest({
    url: `/users/${userId}/reports`,
    method: "get",
    params,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export const userReportService = {
  getFavouritedReports,
  favouriteReport,
  unfavouriteReport,
  getCount,
};
