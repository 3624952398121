import { genericGetSingle, genericGetAll } from "../helpers/apiCall";
import { WorkflowHistory } from "../models";

/**
 * Gets all workflow histories
 * @param params URLSearchParams
 * @returns Promise<{ workflow_histories: WorkflowHistory[] }>
 */
function getAll(
  params: URLSearchParams = null,
): Promise<{ workflow_histories: WorkflowHistory[] }> {
  return genericGetAll("workflow_histories", params);
}

/**
 * Gets a single workflow history
 * @param id number | string
 * @returns Promise<{ workflow_history: WorkflowHistory }>
 */
function getSingle(
  id: number | string,
): Promise<{ workflow_history: WorkflowHistory }> {
  return genericGetSingle("workflow_histories", id);
}

/**
 * Gets the count of workflow histories
 * @param params URLSearchParams
 * @returns Promise<{ count: number }>
 */
function getCount(params: URLSearchParams = null): Promise<{ count: number }> {
  params.append("countOnly", "true");
  return genericGetAll("workflow_histories", params);
}

export const workflowHistoryService = {
  getAll,
  getSingle,
  getCount,
};
