import StaticText from "./staticText";
import ReportColumn from "./reportColumn";

/**
 *
 * @param param0 status
 * @returns DynamicField component
 */
export default function DynamicField({ status }: { status: string }) {
  if (status === "Static Text") {
    return <StaticText />;
  } else if (status === "Report Column") {
    return <ReportColumn />;
  }
}
