import { useCallback } from "react";
import { Formik, FormikProps } from "formik";
import { Row, Col } from "antd";
import { Form, Select, SubmitButton, Input } from "formik-antd";
import { useNavigate } from "react-router-dom";
import { Integration, App } from "/app/src/models";
import { integrationService } from "/app/src/services";
import { useTranslation } from "react-i18next";
import { useMutation } from "@tanstack/react-query";
import { handlePromiseError } from "/app/src/helpers/api";
import { simpleSchemaBuilder } from "/app/src/helpers";
import Box from "/app/src/components/generic/components/box";

interface FormValues {
  type?: string;
  theme?: string;
  name?: string;
}

function formatForm(values: FormValues, app: App) {
  return {
    name: values.name,
    appId: app.id,
    number: values.type,
    baseTable: values.theme,
  };
}

export default function NewIntegrationForm({
  app,
  themes,
  setToggled,
}: {
  app: App;
  themes: {
    name: string;
    value: string;
  }[];
  setToggled: () => void;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { mutateAsync: addIntegration } = useMutation({
    mutationFn: (integration: Integration) => {
      return integrationService
        .createSingle(integration)
        .then(handlePromiseError);
    },
  });

  const addIntegrationHandler = useCallback(
    async (values: FormValues) => {
      const integration = formatForm(values, app);
      await addIntegration(integration).then((response) => {
        navigate(`/apps/${app.id}/integrations/${response.integration.id}`);
        setToggled();
      });
    },
    [addIntegration, app, navigate, setToggled],
  );

  const newIntegrationForm: (props: FormikProps<FormValues>) => JSX.Element =
    useCallback(
      ({ dirty, isSubmitting }) => (
        <Form layout="vertical">
          <Row justify="start" gutter={16}>
            <Col span={4}>
              <Form.Item name="type" label={t("translation:type")}>
                <Select name="type" size="large">
                  <Select.Option value="Import" key={1}>
                    {t("translation:import")}
                  </Select.Option>
                  <Select.Option value="Export" key={2}>
                    {t("translation:export")}
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="theme" label={t("translation:theme")}>
                <Select name="theme" size="large">
                  {themes.map((theme) => (
                    <Select.Option key={theme.name} value={theme.value}>
                      {theme.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item
                name="name"
                className="name"
                label={t("translation:new_integration")}
                hasFeedback={false}
              >
                <Input
                  name="name"
                  placeholder={t("translation:enter_name")}
                  size="large"
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <SubmitButton
                type="primary"
                size="large"
                className="submit"
                style={{ marginTop: "30px" }}
                disabled={!dirty || isSubmitting}
              >
                {t("translation:create")} {t("translation:integration")}
              </SubmitButton>
            </Col>
          </Row>
        </Form>
      ),
      [t, themes],
    );
  const initialValues: FormValues = {
    type: "Import",
    name: "",
    theme: "MasterOrderLine",
  };
  return (
    <Box>
      <div className="newIntegration">
        <Formik
          component={newIntegrationForm}
          initialValues={initialValues}
          validationSchema={simpleSchemaBuilder([
            { name: "name", type: "string", required: true },
          ])}
          onSubmit={addIntegrationHandler}
        />
      </div>
    </Box>
  );
}
