import { Row, Col } from "antd";
import ConnectionStatus from "./charts/connectionStatus";
import ApiStatus from "./charts/apiStatus";
import RestartButton from "./restartButton";
import GeneralInfo from "./generalInfo";
import Performance from "./performance";
import "./_status.scss";
import { useTranslation } from "react-i18next";

/**
 * Component for displaying status page
 * @returns JSX.Element
 */
export default function StatusPage() {
  const { t } = useTranslation();
  return (
    <div className="status">
      <Row gutter={16}>
        <Col span={24}>
          <h1>{t("translation:connection_status")}</h1>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col lg={18} md={12} sm={24}>
          <ConnectionStatus />
          <h1>{t("translation:api_status")}</h1>
          <ApiStatus />
        </Col>
        <Col lg={6} md={12} sm={24}>
          <RestartButton />
          <h3 className="h3">{t("translation:general_info")}</h3>
          <GeneralInfo />
          <h3>{t("translation:performance")}</h3>
          <Performance />
        </Col>
      </Row>
    </div>
  );
}
