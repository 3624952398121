//Regex to validate basic string value
export const stringValidation = /^[!#$&-;=?-\][_a-z~ ]+$/;

//Regex to validate values separated by comma
export const commaSeparationValidation = /^([^,]+)(,[^,]+)*$/;

//Regex to validate phone number
export const phoneNumberValidation =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

//Regex to validate backslash
export const backslashValidation = /^(?:(?!\/$).)+$/;
