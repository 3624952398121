import { Link } from "react-router-dom";
import { App } from "/app/src/models/app";

import { Col } from "antd";

/**
 * Component to display a single app box in the app list
 */
export default function AppBox({
  app,
  availableApp,
}: {
  app: App;
  availableApp: boolean;
}) {
  const appSelector = `${app.name.replace(" ", "_").toLowerCase()}_app`;

  return (
    <Col
      xs={{ span: 24 }}
      sm={{ span: 24 }}
      md={{ span: 12 }}
      lg={{ span: 8 }}
      xl={{ span: 6 }}
    >
      <div
        className={availableApp ? "appBox" : "appBox disabled_app"}
        id={appSelector}
      >
        <div className="icon" />
        <div className="info">
          {availableApp ? (
            <h3>
              <Link to={`/apps/${app.id}`}>{app.name}</Link>
            </h3>
          ) : (
            <h3 className="disabled">{app.name}</h3>
          )}
          {app.description}
        </div>
      </div>
    </Col>
  );
}
