import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import AppBody from "./appBody";
import "./stylesheets/index.scss";
import AppWrappers from "./appWrappers";

/**
 * The main application component
 */
export default function App() {
  return (
    <AppWrappers>
      <AppBody />
      <ReactQueryDevtools initialIsOpen={false} />
    </AppWrappers>
  );
}
