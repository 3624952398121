import {
  genericGetSingle,
  genericCreateSingle,
  genericDeleteSingle,
  genericGetAll,
  genericUpdateSingle,
} from "../helpers/apiCall";
import { Widget } from "../models";
import { Error } from "../types";

/**
 * Gets all widgets
 * @param params URLSearchParams
 * @returns Promise<{ widgets: Widget[] }>
 */
function getAll(
  params: URLSearchParams = null,
): Promise<{ widgets: Widget[] }> {
  return genericGetAll("widgets", params);
}

/**
 * Gets a single widget
 * @param id number | string
 * @returns Promise<{ widget: Widget }>
 */
function getSingle(id: number | string): Promise<{ widget: Widget }> {
  return genericGetSingle("widgets", id);
}

/**
 * Create a new widget
 * @param widget { [key: string]: string | number | boolean | Date | undefined}
 * @returns Promise<{ widget: Widget } | Error>
 */
function createSingle(widget: {
  [key: string]: string | number | boolean | Date | undefined;
}): Promise<{ widget: Widget } | Error> {
  return genericCreateSingle("widgets", widget);
}

/**
 * Update a single widget
 * @param id number | string
 * @param widget { [key: string]: string | number | boolean | Date | undefined}
 * @returns Promise<{ widget: Widget } | Error>
 */
function updateSingle(
  id: number | string,
  widget: { [key: string]: string | number | boolean | Date | undefined },
): Promise<{ widget: Widget } | Error> {
  return genericUpdateSingle("widgets", id, widget);
}

/**
 * Delete a single widget
 * @param id number | string
 * @returns Promise<{ deleted: boolean } | { error: string }>
 */
function deleteSingle(
  id: number | string,
): Promise<{ deleted: boolean } | { error: string }> {
  return genericDeleteSingle("widgets", id);
}

/**
 * Gets the count of widgets
 * @param params URLSearchParams
 * @returns Promise<{ count: number }>
 */
function getCount(params: URLSearchParams = null): Promise<{ count: number }> {
  params.append("countOnly", "true");
  return genericGetAll("widgets", params);
}

export const widgetService = {
  getAll,
  getSingle,
  createSingle,
  updateSingle,
  deleteSingle,
  getCount,
};
