import { useCallback } from "react";
import { BarDatum, ComputedDatum, ResponsiveBar } from "@nivo/bar";
import DateTime from "/app/src/components/generic/formatting/dateTime";

/**
 * Displays the PowerPickSQL and PowerPick Web services bar chart
 * @param data, disabled
 * @returns ResponsiveBar
 */
export default function PowerPickChart({ data }: { data: BarDatum[] }) {
  /**
   * getColor - gets the colour of the passed in bar
   * @param bar the bar to get the colour for
   * @returns colour of the bar
   */
  const getColor = useCallback((bar: ComputedDatum<BarDatum>) => {
    const colorKey = "statusColor";
    return bar.data[colorKey] as string;
  }, []);

  /**
   * CustomTooltip - renders a customized tooltip for chart bars
   * @param data the data object for the bar
   * @returns a JSX element representing the tooltip
   */
  const CustomTooltip = useCallback(
    ({ data }) => (
      <div className="bg-[rgb(255,255,255)]/80 p-[10px]">
        <DateTime date={data.creationDate} format="MMM DD HH:mm" />
      </div>
    ),
    [],
  );

  return (
    <div className="status-chart">
      <ResponsiveBar
        data={data}
        keys={["status"]}
        colors={getColor}
        groupMode="grouped"
        indexBy="creationDate"
        enableGridY
        gridYValues={[0]}
        padding={0.3}
        borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
        axisBottom={{
          tickSize: 10,
          tickPadding: 10,
          tickRotation: -35,
        }}
        maxValue={5}
        enableLabel={false}
        isInteractive
        tooltip={CustomTooltip}
        theme={{
          axis: {
            ticks: {
              text: {
                fontSize: "10px",
              },
            },
          },
        }}
      />
    </div>
  );
}
