import { useCallback } from "react";
import { APICallBreakdown } from "./apiCallBreakdown";
import { ResponsiveLine } from "@nivo/line";

/**
 * Displays the API Status chart
 * @param param data
 * @returns ResponsiveLine
 */
export default function APIStatusChart({ data }: { data: APICallBreakdown[] }) {
  const getColor = useCallback((line: APICallBreakdown & { color: string }) => {
    return line.color;
  }, []);

  return (
    <div className="line-chart">
      <ResponsiveLine
        data={data}
        margin={{ top: 50, right: 50, bottom: 50, left: 50 }}
        xScale={{ type: "point" }}
        yScale={{
          type: "linear",
          min: "auto",
          max: "auto",
          stacked: false,
          reverse: false,
        }}
        colors={getColor}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          legendPosition: "middle",
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 45,
        }}
        axisLeft={null}
        pointSize={10}
        pointColor={{ theme: "background" }}
        pointBorderWidth={2}
        pointBorderColor={{ from: "serieColor" }}
        pointLabel="y"
        pointLabelYOffset={-12}
        useMesh
        enableArea
        enablePoints={false}
        curve="monotoneX"
        legends={[
          {
            anchor: "bottom-right",
            direction: "column",
            justify: false,
            translateX: 85,
            translateY: 0,
            itemsSpacing: 0,
            itemDirection: "left-to-right",
            itemWidth: 80,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: "circle",
            symbolBorderColor: "rgba(0, 0, 0, .5)",
            effects: [
              {
                on: "hover",
                style: {
                  itemBackground: "rgba(0, 0, 0, .03)",
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
      />
    </div>
  );
}
