import { Input, Switch, Form } from "formik-antd";
import { useTranslation } from "react-i18next";

/**
 *
 * @returns StaticText component
 */
export default function StaticText() {
  const { t } = useTranslation();
  return (
    <>
      <Form.Item className="w-full !mb-0 " name="value" hasFeedback={false}>
        <Input size="large" name="value" placeholder="" />
      </Form.Item>
      <div className="flex">
        <Form.Item name="required">
          {t("translation:required")} <Switch name="required" />
        </Form.Item>
        <div className="ml-3">
          <Form.Item name="viewOnly">
            {t("translation:view_only")} <Switch name="viewOnly" />
          </Form.Item>
        </div>
      </div>
    </>
  );
}
