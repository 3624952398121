import React from "react";
import { Select, Input } from "formik-antd";
import { useTranslation } from "react-i18next";

/**
 * Value Field to display with data pull filters
 */
export function ValueField({ mappingType }: { mappingType: string }) {
  const { t } = useTranslation();
  const dateTimeOptions = [
    { name: "Last Day", value: "{lastDay}" },
    { name: "Last Week", value: "{lastWeek}" },
  ];

  if (mappingType === "DateTime") {
    return (
      <Select name="value" size="large">
        {dateTimeOptions.map((option) => (
          <Select.Option key={option.value} value={option.value}>
            {option.name}
          </Select.Option>
        ))}
      </Select>
    );
  } else {
    return (
      <Input
        name="value"
        placeholder={t("translation:enter_value")}
        size="large"
      />
    );
  }
}
