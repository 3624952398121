import { useCallback, useMemo } from "react";
import { Formik, FormikProps } from "formik";
import { Row, Col } from "antd";
import { Form, Select, SubmitButton, Input } from "formik-antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useQuery, useMutation } from "@tanstack/react-query";

import {
  integrationService,
  settingService,
  reportService,
} from "/app/src/services";
import ToolTip from "/app/src/components/generic/components/toolTip";
import { newIntegrationSchema } from "/app/src/schemas";
import { App, Report, Setting, Integration } from "/app/src/models";
import { buildParams } from "/app/src/helpers/params";
import { handlePromiseError } from "/app/src/helpers/api";
import Box from "/app/src/components/generic/components/box";

interface FormValues {
  name: string;
  report: string;
  appId: number | undefined;
}

export default function NewIntegration({
  app,
  setToggled,
}: {
  app: App;
  setToggled: () => void;
}) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  /**
   * format form values to match the integration model
   * @param values FormValues
   */
  function formatForm(values: FormValues) {
    return {
      name: values.name,
      appId: values.appId,
    };
  }

  const { data: reports } = useQuery({
    queryKey: ["reports", "Material"],
    queryFn: () => {
      return reportService.getAll(buildParams({ baseTable: "Material" }));
    },
    initialData: { reports: [] },
    select: (data: { reports: Report[] }) => {
      return data.reports;
    },
  });

  const { mutateAsync: createIntegration } = useMutation({
    mutationFn: (values: Integration) => {
      return integrationService.createSingle(values).then(handlePromiseError);
    },
  });

  const { mutateAsync: createSetting } = useMutation({
    mutationFn: (values: Setting) => {
      return settingService.createSingle(values).then(handlePromiseError);
    },
  });

  const createIntegrationHandler = useCallback(
    async (values: FormValues) => {
      await createIntegration(formatForm(values)).then((response) => {
        //only create setting if a report is selected
        if (values.report) {
          createSetting({
            number: 11,
            name: JSON.parse(values.report).name,
            value: JSON.parse(values.report).id,
            type: "integration",
            integrationId: response.integration.id,
            appId: app.id,
          });
        }
        navigate(`/apps/${app.id}/integrations/${response.integration.id}`);
        setToggled();
      });
    },
    [app.id, createIntegration, createSetting, navigate, setToggled],
  );

  const labelConent = useMemo(
    () => (
      <>
        {"Report"}
        <ToolTip tip={t("translation:cycle_count_report_tip")} />
      </>
    ),
    [t],
  );

  const newIntegrationForm: (props: FormikProps<FormValues>) => JSX.Element =
    useCallback(
      ({ dirty, isValid }) => (
        <Form layout="vertical">
          <Row justify="start" gutter={16}>
            <Col span={10}>
              <Form.Item name="name" label={t("translation:integration_name")}>
                <Input
                  suffix
                  name="name"
                  placeholder={t("translation:enter_name")}
                  size="large"
                />
              </Form.Item>
            </Col>
            <Col span={9}>
              <Form.Item name="report" label={labelConent}>
                <Select name="report" size="large">
                  {reports.map((report) => (
                    <Select.Option
                      value={JSON.stringify(report)}
                      key={report.id}
                    >
                      {report.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={5}>
              <SubmitButton
                style={{ marginTop: "30px" }}
                type="primary"
                size="large"
                block
                disabled={!(dirty && isValid)}
              >
                {t("translation:create")} {t("translation:integration")}
              </SubmitButton>
            </Col>
          </Row>
        </Form>
      ),
      [t, reports, labelConent],
    );

  return (
    <Box>
      <div className="newIntegration">
        <Formik
          component={newIntegrationForm}
          initialValues={{
            name: "",
            appId: app.id,
            report: "",
          }}
          validationSchema={newIntegrationSchema}
          onSubmit={createIntegrationHandler}
        />
      </div>
    </Box>
  );
}
