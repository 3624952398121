import { Row } from "antd";

import { storageUnitService } from "/app/src/services";
import StorageUnits from "/app/src/components/explorer/storageUnits";
import { buildParams } from "/app/src/helpers/params";
import {
  Warehouse as WarehouseType,
  StorageUnit as StorageUnitType,
} from "/app/src/models";
import { useQuery } from "@tanstack/react-query";
export default function Warehouse({ warehouse }: { warehouse: WarehouseType }) {
  const { data: storageUnits } = useQuery({
    queryKey: ["storageUnitsByWarehouse", warehouse.id],
    queryFn: () => {
      return storageUnitService.getAll(
        buildParams({ warehouseId: warehouse.id }),
      );
    },
    initialData: { storage_units: [] },
    select: (data: { storage_units: StorageUnitType[] }) => {
      return data.storage_units;
    },
  });

  return (
    <div className="warehouse">
      <h3>{warehouse.name}</h3>

      <Row gutter={20}>
        {storageUnits.map((storageUnit) => (
          <StorageUnits storageUnit={storageUnit} key={storageUnit.id} />
        ))}
      </Row>
    </div>
  );
}
