import React from "react";
import { Routes, Route } from "react-router-dom";
import { Row, Col } from "antd";

import Batches from "./batches";
import Batch from "./batches/batch";
import Bins from "./bins";
import Bin from "./bins/bin";
import CostCenters from "./costCenters";
import CostCenter from "./costCenters/costCenter";
import Carrier from "./carriers/carrier";
import Carriers from "./carriers";
import Kits from "./kits";
import StorageUnit from "./storageUnits/storageUnit";
import Locations from "./locations";
import StorageRule from "./storageRules/storageRule";
import StorageRules from "./storageRules";
import Material from "./materials/material";
import Materials from "./materials";
import MaterialProperty from "./materialProperties/materialProperty";
import MaterialProperties from "./materialProperties";

import Order from "./orders/order";
import Orders from "./orders";
import OrderLine from "./orderLines/orderLine";
import OrderLines from "./orderLines";
import Shelves from "./shelves";
import Warehouses from "./warehouses";
import SideMenuBox from "/app/src/components/generic/components/sideMenuBox";
import StationsList from "./stations";
import SingleStation from "./stations/singleStation";
import DynamicFieldsList from "./dynamicFields";
import SingleDynamicField from "./dynamicFields/singleDynamic";
import SingleLocation from "./locations/singleLocation";
import Transactions from "./transactions";
import Transaction from "./transactions/transaction";
import Zones from "./zones";
import Zone from "./zones/zone";
import Peripheral from "./peripherals/peripheral";
import Peripherals from "./peripherals";
import Kit from "./kits/kit";
import Shelf from "./shelves/shelf";
import ParameterValues from "./parameterValues";

export default function Explorer() {
  const links = [
    [{ path: "/explorer", label: "warehouses" }],
    [
      { path: "/explorer/batches", label: "batches" },
      { path: "/explorer/orders", label: "orders" },
      { path: "/explorer/order_lines", label: "order_lines" },
    ],
    [
      { path: "/explorer/bins", label: "bins" },
      { path: "/explorer/carriers", label: "carriers" },
      { path: "/explorer/cost_centers", label: "cost_centers" },
      { path: "/explorer/dynamic_fields", label: "dynamic_fields" },
      { path: "/explorer/kits", label: "kits" },
      { path: "/explorer/locations", label: "locations" },
      { path: "/explorer/materials", label: "materials" },
      { path: "/explorer/material_properties", label: "material_properties" },
      { path: "/explorer/parameter_values", label: "parameter_values" },
      { path: "/explorer/peripherals", label: "peripherals" },
      { path: "/explorer/shelves", label: "shelves" },
      { path: "/explorer/stations", label: "stations" },
      { path: "/explorer/storage_rules", label: "storage_rules" },
      { path: "/explorer/transactions", label: "transactions" },
      { path: "/explorer/zones", label: "zones" },
    ],
  ];

  return (
    <div className="explorer">
      <Row gutter={20}>
        <Col span={6}>
          <SideMenuBox links={links[0]} />
          <SideMenuBox links={links[1]} />
          <SideMenuBox links={links[2]} />
        </Col>
        <Col span={18}>
          <Routes>
            <Route path="batches" element={<Batches />} />
            <Route path="batches/:batchId" element={<Batch />} />
            <Route path="bins" element={<Bins />} />
            <Route path="bins/:binId" element={<Bin />} />
            <Route path="carriers" element={<Carriers />} />
            <Route path="carriers/:carrierId" element={<Carrier />} />
            <Route path="cost_centers" element={<CostCenters />} />
            <Route path="cost_centers/:costCenterId" element={<CostCenter />} />

            <Route path="locations/:locationId" element={<SingleLocation />} />
            <Route path="locations" element={<Locations />} />
            <Route path="kits/:kitId" element={<Kit />} />
            <Route path="kits" element={<Kits />} />
            <Route path="materials/:materialId" element={<Material />} />
            <Route path="materials" element={<Materials />} />
            <Route
              path="material_properties/:materialPropertyId"
              element={<MaterialProperty />}
            />
            <Route
              path="material_properties"
              element={<MaterialProperties />}
            />
            <Route path="orders/:orderId" element={<Order />} />
            <Route path="orders" element={<Orders />} />
            <Route path="order_lines/:orderLineId" element={<OrderLine />} />
            <Route path="order_lines" element={<OrderLines />} />
            <Route path="parameter_values" element={<ParameterValues />} />
            <Route path="shelves/:shelfId" element={<Shelf />} />
            <Route path="shelves" element={<Shelves />} />
            <Route path="storage_rules" element={<StorageRules />} />
            <Route
              path="storage_rules/:storageRuleId"
              element={<StorageRule />}
            />
            <Route
              path="storage_units/:storageUnitId"
              element={<StorageUnit />}
            />
            <Route path="storage_units" element={<Warehouses />} />

            <Route path="stations/:stationId" element={<SingleStation />} />
            <Route path="stations" element={<StationsList />} />
            <Route
              path="dynamic_fields/:fieldId"
              element={<SingleDynamicField />}
            />
            <Route path="dynamic_fields" element={<DynamicFieldsList />} />
            <Route path="cost_centers" element={<CostCenters />} />
            <Route
              path="transactions/:transactionId"
              element={<Transaction />}
            />
            <Route path="transactions" element={<Transactions />} />
            <Route path="zones/:zoneId" element={<Zone />} />
            <Route path="zones" element={<Zones />} />
            <Route path="peripherals/:peripheralId" element={<Peripheral />} />
            <Route path="peripherals" element={<Peripherals />} />
            <Route path="/" element={<Warehouses />} />
          </Routes>
        </Col>
      </Row>
    </div>
  );
}
