import React, { useCallback } from "react";
import { Formik, FormikHelpers } from "formik";
import { Snapshot as SnapshotType, ReportColumn } from "/app/src/models";
import { handleSubmissionErrors } from "/app/src/helpers/forms";
import { snapshotSchema } from "/app/src/schemas/snapshotSchema";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { snapshotService } from "/app/src/services";
import { handlePromiseError } from "/app/src/helpers/api";
import SnapshotForm from "./snapshotForm";

interface FormValues {
  name: string | undefined;
  reportColumnId: number | undefined;
  groupColumnId: number | undefined;
  subGroupColumnId: number | undefined;
  type: string | undefined;
  sortDirection: string | undefined;
  groupRange: string;
}

function formatForm(values: FormValues) {
  //check for groupColumnId, subGroupColumnId as allowClear may remove key from values
  if (!values.groupColumnId) {
    values.groupColumnId = null;
  }
  if (!values.subGroupColumnId) {
    values.subGroupColumnId = null;
  }
  return {
    reportColumnId: values.reportColumnId,
    groupColumnId: values.groupColumnId,
    subGroupColumnId: values.subGroupColumnId,
    name: values.name,
    type: values.type,
    sortDirection: values.sortDirection,
    groupRange: values.groupRange,
  };
}

export default function Snapshot({
  snapshot,
  reportColumns,
}: {
  snapshot: SnapshotType;
  reportColumns: ReportColumn[];
}) {
  const queryClient = useQueryClient();

  const { mutateAsync: updateSnapshot } = useMutation({
    mutationFn: (data: {
      [key: string]: string | number | boolean | Date | undefined;
    }) =>
      snapshotService.updateSingle(snapshot.id, data).then(handlePromiseError),
    onSuccess: (updatedSnapshot) => {
      queryClient.setQueryData(
        ["snapshots", snapshot.reportId],
        (oldData: { snapshots: SnapshotType[] }) => {
          const newSnapshots = oldData.snapshots.map((s) => {
            if (s.id === snapshot.id) {
              return updatedSnapshot.snapshot;
            }
            return s;
          });
          return { snapshots: newSnapshots };
        },
      );
    },
  });

  const updateSnapshotHandler = useCallback(
    async (values: FormValues, actions: FormikHelpers<FormValues>) => {
      if (snapshot.id) {
        const formattedValues = formatForm(values);
        await updateSnapshot(formattedValues).catch((errors) => {
          handleSubmissionErrors(errors, actions.setFieldError);
        });
      }
    },
    [snapshot.id, updateSnapshot],
  );

  return (
    <div className="snapshot">
      <Formik
        enableReinitialize
        validationSchema={snapshotSchema}
        initialValues={{
          name: snapshot.name,
          reportColumnId: snapshot.reportColumnId,
          groupColumnId: snapshot.groupColumnId,
          subGroupColumnId: snapshot.subGroupColumnId,
          type: snapshot.type,
          sortDirection: snapshot.sortDirection,
          groupRange: snapshot.groupRange,
        }}
        onSubmit={updateSnapshotHandler}
      >
        <SnapshotForm reportColumns={reportColumns} snapshot={snapshot} />
      </Formik>
    </div>
  );
}
