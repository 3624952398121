import { Col, Row } from "antd";
import { Integration } from "/app/src/models";
import { useTranslation } from "react-i18next";
import NextDivider from "/app/src/components/NextUi/Divider";
import Box from "/app/src/components/generic/components/box";
import Field from "./field";

/**
 *
 * @param param0 integration
 * @returns  OrderFields component
 */
export default function OrderFields({
  integration,
}: {
  integration: Integration;
}) {
  const { t } = useTranslation();

  return (
    <Box>
      <Row className="pb-3">
        <Col className="text-lg" span={8}>
          {t("translation:order_field")}
        </Col>
      </Row>
      <NextDivider />
      <Field />
    </Box>
  );
}
