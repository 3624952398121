import { useTranslation } from "react-i18next";
import { Integration } from "/app/src/models";
import { useCallback } from "react";
import { Form, Select, SubmitButton } from "formik-antd";
import { Formik, FormikProps } from "formik";
import { Col, Row } from "antd";
import SQLTable from "./sqlTable";

interface ConnectionDetailsFormValues {
  connectionId: number | undefined;
  sqlTable: string | undefined;
}

/**
 *
 * @param param0 integration
 * @returns Connection details for the integration
 */
export default function ConnectionDetails({
  integration,
}: {
  integration: Integration;
}) {
  const { t } = useTranslation();

  const onSubmit = useCallback((values: ConnectionDetailsFormValues) => {
    console.log(values);
  }, []);

  const updateConnectionDetailsForm: (
    props: FormikProps<ConnectionDetailsFormValues>,
  ) => JSX.Element = useCallback(
    ({ dirty, isSubmitting }) => (
      <Form layout="vertical">
        <Row className="pb-3">
          <Col className="text-lg font-semibold" span={8}>
            {t("translation:connection_details")}
          </Col>
          <Col span={8} offset={8}>
            <Row justify="end">
              <SubmitButton
                disabled={!dirty || isSubmitting}
                loading={isSubmitting}
                type="primary"
              >
                {t("translation:save")}
              </SubmitButton>
            </Row>
          </Col>
        </Row>
        <Row justify="start" gutter={16} className="flex items-center">
          <Col span={8}>
            <h3>{t("translation:linked_connection")}</h3>
            <p>
              <h3>{t("translation:linked_connection_description")}</h3>
            </p>
          </Col>
          <Col span={16}>
            <Form.Item
              name="integrationId"
              label="Choose a Connection"
              hasFeedback={false}
            >
              <Select size="large" name="integrationId">
                <Select.Option value={1}>Connection 1</Select.Option>
                <Select.Option value={2}>Connection 2</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <SQLTable />
      </Form>
    ),
    [t],
  );

  return (
    <Formik
      component={updateConnectionDetailsForm}
      initialValues={{
        connectionId: 1,
        sqlTable: "test",
      }}
      onSubmit={onSubmit}
    />
  );
}
