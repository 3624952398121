import {
  genericGetSingle,
  genericGetAll,
  genericUpdateSingle,
  genericCreateSingle,
  genericDeleteSingle,
} from "../helpers/apiCall";
import { Error } from "../types";
import { Status } from "../models";

/**
 * Get all statuss
 * @param params URLSearchParams
 * @returns Promise<{ statuses: status[] }>
 */
function getAll(
  params: URLSearchParams = null,
): Promise<{ statuses: Status[] }> {
  return genericGetAll("statuses", params);
}

/**
 * Get a single status
 * @param id number | string
 * @returns Promise<{ status: status }>
 */
function getSingle(id: number | string): Promise<{ status: Status }> {
  return genericGetSingle("statuses", id);
}

/**
 * Get a single status
 * @param id number | string
 * @param status { [key: string]: string | number | boolean | Date | undefined}
 * @returns Promise<{ status: status } | Error>
 */
function updateSingle(
  id: number | string,
  status: { [key: string]: string | number | boolean | Date | undefined },
): Promise<{ status: Status } | Error> {
  return genericUpdateSingle("statuses", id, status);
}

/**
 * Create a single status
 * @param status { [key: string]: string | number | boolean | Date | undefined}
 * @returns Promise<{ status: status } | Error>
 */
function createSingle(status: {
  [key: string]: string | number | boolean | Date | undefined;
}): Promise<{ status: Status } | Error> {
  return genericCreateSingle("statuses", status);
}

/**
 * Delete a single status
 * @param id number | string
 * @returns Promise<{ deleted: boolean } | { error: string }>
 */
function deleteSingle(
  id: number | string,
): Promise<{ deleted: boolean } | { error: string }> {
  return genericDeleteSingle("statuses", id);
}

/**
 * Gets the count of statuss
 * @param params URLSearchParams
 * @returns Promise<{ count: number }>
 */
function getCount(params: URLSearchParams = null): Promise<{ count: number }> {
  params.append("countOnly", "true");
  return genericGetAll("statuses", params);
}

export const statusService = {
  getAll,
  getSingle,
  updateSingle,
  createSingle,
  deleteSingle,
  getCount,
};
