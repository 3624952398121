import {
  makeAPIRequest,
  genericGetSingle,
  genericCreateSingle,
  genericDeleteSingle,
  genericGetAll,
  genericUpdateSingle,
} from "../helpers/apiCall";
import { Workflow } from "../models";
import { Error } from "../types";

/**
 * Gets all workflows
 * @param params URLSearchParams
 * @returns Promise<{ workflows: Workflow[] }>
 */
function getAll(
  params: URLSearchParams = null,
): Promise<{ workflows: Workflow[] }> {
  return genericGetAll("workflows", params);
}

/**
 * Gets a single workflow
 * @param id number | string
 * @returns Promise<{ workflow: Workflow }>
 */
function getSingle(id: number | string): Promise<{ workflow: Workflow }> {
  return genericGetSingle("workflows", id);
}

/**
 * Tests a single workflow
 * @param id number | string
 * @returns Promise<{ workflow: Workflow }>
 */
function testSingle(id: number | string): Promise<{ workflow: Workflow }> {
  return makeAPIRequest({ url: `/workflows/${id}/test`, method: "get" })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

/**
 * Create a new workflow
 * @param workflow { [key: string]: string | number | boolean | Date | undefined}
 * @returns Promise<{ workflow: Workflow } | Error>
 */
function createSingle(workflow: {
  [key: string]: string | number | boolean | Date | undefined;
}): Promise<{ workflow: Workflow } | Error> {
  return genericCreateSingle("workflows", workflow);
}

/**
 * Update a single workflow
 * @param id number | string
 * @param workflow { [key: string]: string | number | boolean | Date | undefined}
 * @returns Promise<{ workflow: Workflow } | Error>
 */
function updateSingle(
  id: number | string,
  workflow: { [key: string]: string | number | boolean | Date | undefined },
): Promise<{ workflow: Workflow } | Error> {
  return genericUpdateSingle("workflows", id, workflow);
}

/**
 * Delete a single workflow
 * @param id number | string
 * @returns Promise<{ deleted: boolean } | { error: string }>
 */
function deleteSingle(
  id: number | string,
): Promise<{ deleted: boolean } | { error: string }> {
  return genericDeleteSingle("workflows", id);
}

/**
 * Gets the count of workflows
 * @param params URLSearchParams
 * @returns Promise<{ count: number }>
 */
function getCount(params: URLSearchParams = null): Promise<{ count: number }> {
  params.append("countOnly", "true");
  return genericGetAll("workflows", params);
}

export const workflowService = {
  getAll,
  getSingle,
  testSingle,
  createSingle,
  updateSingle,
  deleteSingle,
  getCount,
};
