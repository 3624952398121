import { genericGetSingle, genericGetAll } from "../helpers/apiCall";
import { WorkOrderLine } from "../models";

/**
 * Gets all work order lines
 * @param params URLSearchParams
 * @returns Promise<{ work_order_lines: WorkOrderLine[] }>
 */
function getAll(
  params: URLSearchParams = null,
): Promise<{ work_order_lines: WorkOrderLine[] }> {
  return genericGetAll("work_order_lines", params);
}

// For getting orderline details
/**
 * Provides a single work order line
 * @param id string
 * @returns Promise<{ work_order_line: WorkOrderLine }>
 */
function getSingle(id: string): Promise<{ work_order_line: WorkOrderLine }> {
  return genericGetSingle("work_order_lines", id);
}

/**
 * Gets the count of work order lines
 * @param params URLSearchParams
 * @returns Promise<{ count: number }>
 */
function getCount(params: URLSearchParams = null): Promise<{ count: number }> {
  params.append("countOnly", "true");
  return genericGetAll("work_order_lines", params);
}

export const workOrderLineService = {
  getAll,
  getSingle,
  getCount,
};
