import React from "react";

interface IconProps {
  color?: string;
  size?: number;
}

/**
 *
 * @param param0 color and size
 * @returns ArrowRight icon
 */
const ArrowRight: React.FC<IconProps> = ({ color, size }) => (
  <svg
    width={size || 24}
    height={size || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.675 11.115L14.53 4.97L13.47 6.03L18.69 11.25H3V12.75H18.69L13.47 17.97L14.53 19.03L20.675 12.885C21.16 12.4 21.16 11.605 20.675 11.115Z"
      fill={color}
    />
  </svg>
);

/**
 *
 * @param param0 color and size
 * @returns ArrowCircleRight icon
 */
const ArrowCircleRight: React.FC<IconProps> = ({ color, size }) => (
  <svg
    width={size || 24}
    height={size || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 12C2 6.485 6.485 2 12 2C17.515 2 22 6.485 22 12C22 17.515 17.515 22 12 22C6.485 22 2 17.515 2 12ZM3.5 12C3.5 16.685 7.315 20.5 12 20.5C16.685 20.5 20.5 16.685 20.5 12C20.5 7.315 16.685 3.5 12 3.5C7.315 3.5 3.5 7.315 3.5 12ZM11.97 9.02997L13.03 7.96997L16.175 11.115C16.66 11.605 16.66 12.4 16.175 12.885L13.03 16.03L11.97 14.97L14.19 12.75H7.5V11.25H14.19L11.97 9.02997Z"
      fill={color}
    />
  </svg>
);

/**
 *
 * @param param0 color and size
 * @returns ExclamationCircle icon
 */
const ExclamationCircle: React.FC<IconProps> = ({ color, size }) => (
  <svg
    width={size || 24}
    height={size || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 17C12.5523 17 13 16.5523 13 16C13 15.4477 12.5523 15 12 15C11.4477 15 11 15.4477 11 16C11 16.5523 11.4477 17 12 17Z"
      fill={color}
    />
    <path
      d="M12 21.25C17.1086 21.25 21.25 17.1086 21.25 12C21.25 6.89137 17.1086 2.75 12 2.75C6.89137 2.75 2.75 6.89137 2.75 12C2.75 17.1086 6.89137 21.25 12 21.25Z"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M12 8V13.5"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
  </svg>
);

/**
 *
 * @param param0 color and size
 * @returns Delete icon
 */
const Delete: React.FC<IconProps> = ({ color, size }) => (
  <svg
    width={size || 24}
    height={size || 24}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.25 2.91675H9.33333V2.77091C9.33333 1.88716 8.61292 1.16675 7.72917 1.16675H6.27083C5.38708 1.16675 4.66667 1.88716 4.66667 2.77091V2.91675H1.75V3.79175H2.33333V11.2292C2.33333 12.113 3.05375 12.8334 3.9375 12.8334H10.0625C10.9462 12.8334 11.6667 12.113 11.6667 11.2292V3.79175H12.25V2.91675ZM5.54167 2.77091C5.54167 2.36841 5.86833 2.04175 6.27083 2.04175H7.72917C8.13167 2.04175 8.45833 2.36841 8.45833 2.77091V2.91675H5.54167V2.77091ZM10.7917 11.2292C10.7917 11.6317 10.465 11.9584 10.0625 11.9584H3.9375C3.535 11.9584 3.20833 11.6317 3.20833 11.2292V3.79175H10.7917V11.2292ZM6.125 5.54175H5.25V10.2084H6.125V5.54175ZM7.875 5.54175H8.75V10.2084H7.875V5.54175Z"
      fill={color}
    />
  </svg>
);

export { ArrowRight, ArrowCircleRight, ExclamationCircle, Delete };
