import React from "react";
import { Tooltip } from "@nextui-org/react";

interface TipProps {
  children: React.ReactNode;
  content?: string;
  size?: "sm" | "md" | "lg";
  color?:
    | "default"
    | "primary"
    | "secondary"
    | "success"
    | "warning"
    | "danger";
  radius?: "none" | "sm" | "md" | "lg" | "full";
  shadow?: "sm" | "md" | "lg";
  placement?:
    | "top"
    | "bottom"
    | "right"
    | "left"
    | "top-start"
    | "top-end"
    | "bottom-start"
    | "bottom-end"
    | "right-start"
    | "right-end"
    | "left-start"
    | "left-end";
  delay?: number;
  closeDelay?: number;
  isOpen?: boolean;
  defaultOpen?: boolean;
  offset?: number;
  containerPadding?: number;
  crossOffset?: number;
  showArrow?: boolean;
  shouldFlip?: boolean;
  triggerScaleOnOpen?: boolean;
  isKeyboardDismissDisabled?: boolean;
  isDismissable?: boolean;
  shouldCloseOnBlur?: boolean;
  motionProps?: object;
  portalContainer?: Element;
  updatePositionDeps?: any[];
  isDisabled?: boolean;
  disableAnimation?: boolean;
  classNames?: { [key: string]: string };
  onOpenChange?: () => void;
  shouldCloseOnInteractOutside?: (e: HTMLElement) => boolean;
  onClose?: () => void;
}

/**
 *
 * @param param0 children, content, size, color, radius, shadow, placement, delay, closeDelay, isOpen, defaultOpen, offset, containerPadding, crossOffset, showArrow, shouldFlip, triggerScaleOnOpen, isKeyboardDismissDisabled, isDismissable, shouldCloseOnBlur, motionProps, portalContainer, updatePositionDeps, isDisabled, disableAnimation, classNames, onOpenChange, shouldCloseOnInteractOutside, onClose
 * @returns NextUI Tooltip
 */
const Tip: React.FC<TipProps> = ({
  children,
  content,
  size = "md",
  color = "default",
  radius = "md",
  shadow,
  placement = "top",
  delay = 0,
  closeDelay = 500,
  isOpen,
  defaultOpen,
  offset = 7,
  containerPadding = 12,
  crossOffset = 0,
  showArrow = true,
  shouldFlip = true,
  triggerScaleOnOpen = true,
  isKeyboardDismissDisabled = false,
  isDismissable = false,
  shouldCloseOnBlur = true,
  motionProps,
  portalContainer = document.body,
  updatePositionDeps = [],
  isDisabled = false,
  disableAnimation = false,
  classNames,
  onOpenChange,
  shouldCloseOnInteractOutside,
  onClose,
}) => {
  return (
    <Tooltip
      content={content}
      size={size}
      color={color}
      radius={radius}
      shadow={shadow}
      placement={placement}
      delay={delay}
      closeDelay={closeDelay}
      isOpen={isOpen}
      defaultOpen={defaultOpen}
      offset={offset}
      containerPadding={containerPadding}
      crossOffset={crossOffset}
      showArrow={showArrow}
      shouldFlip={shouldFlip}
      triggerScaleOnOpen={triggerScaleOnOpen}
      isKeyboardDismissDisabled={isKeyboardDismissDisabled}
      isDismissable={isDismissable}
      shouldCloseOnBlur={shouldCloseOnBlur}
      motionProps={motionProps}
      portalContainer={portalContainer}
      updatePositionDeps={updatePositionDeps}
      isDisabled={isDisabled}
      disableAnimation={disableAnimation}
      classNames={classNames}
      onOpenChange={onOpenChange}
      shouldCloseOnInteractOutside={shouldCloseOnInteractOutside}
      onClose={onClose}
    >
      {children}
    </Tooltip>
  );
};

export default Tip;
