import { ResponsiveLine } from "@nivo/line";
import { CartesianMarkerProps, DatumValue } from "@nivo/core";
import { useCallback } from "react";
import { LineChartData } from "./types";

/**
 * Component to display a Nivo Line Chart
 * @param data the data to display
 * @param trendLines the trendlines(Nivo markers) to display
 */
export default function LineChart({
  data,
  trendLines,
}: {
  data: LineChartData;
  trendLines: CartesianMarkerProps<DatumValue>[];
}) {
  const getColor = useCallback((line) => {
    return line.color;
  }, []);
  return (
    <div className="chart time">
      <ResponsiveLine
        data={data.data}
        isInteractive
        curve="linear"
        margin={{ top: 20, right: 50, bottom: 50, left: 40 }}
        enableArea
        axisBottom={{
          tickSize: 10,
          tickPadding: 10,
          tickRotation: -35,
        }}
        xScale={{ type: "point" }}
        yScale={{
          type: "linear",
          min: "auto",
          max: "auto",
          stacked: false,
          reverse: false,
        }}
        markers={trendLines}
        legends={[
          {
            anchor: "top-right",
            direction: "row",
            justify: false,
            translateX: 20,
            translateY: -20,
            itemsSpacing: 0,
            itemDirection: "left-to-right",
            itemWidth: 150,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: "circle",
            symbolBorderColor: "rgba(0, 0, 0, .5)",
            effects: [
              {
                on: "hover",
                style: {},
              },
            ],
          },
        ]}
        useMesh
        pointSize={5}
        pointBorderWidth={2}
        colors={
          data.groupingType === "grouped" ? { scheme: "paired" } : getColor
        }
        pointBorderColor={{ from: "serieColor" }}
      />
    </div>
  );
}
