import {
  makeAPIRequest,
  genericGetSingle,
  genericCreateSingle,
  genericDeleteSingle,
  genericGetAll,
  genericUpdateSingle,
} from "../helpers/apiCall";
import { WorkflowCriteria } from "../models";
import { Error } from "../types";

/**
 * Gets all workflow criteria
 * @param params URLSearchParams
 * @returns Promise<{ workflow_criteria: WorkflowCriteria[] }>
 */
function getAll(
  params: URLSearchParams = null,
): Promise<{ workflow_criteria: WorkflowCriteria[] }> {
  return genericGetAll("workflow_criteria", params);
}

/**
 * Gets all workflow criteria for a workflow
 * @param workflowId number | string
 * @param params URLSearchParams
 */
function getByWorkflow(
  workflowId: number | string,
  params: URLSearchParams = null,
) {
  return makeAPIRequest({
    url: `/workflows/${workflowId}/workflow_criteria`,
    params,
    method: "get",
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

/**
 * Gets a single workflow criteria
 * @param id number | string
 * @returns Promise<{ workflow_criteria: WorkflowCriteria }>
 */
function getSingle(
  id: number | string,
): Promise<{ workflow_criteria: WorkflowCriteria }> {
  return genericGetSingle("workflow_criteria", id);
}

/**
 * Create a new workflow criteria
 * @param workflowCriteria { [key: string]: string | number | boolean | Date | undefined}
 * @returns Promise<{ workflow_criteria: WorkflowCriteria } | Error>
 */
function createSingle(workflowCriteria: {
  [key: string]: string | number | boolean | Date | undefined;
}): Promise<{ workflow_criteria: WorkflowCriteria } | Error> {
  return genericCreateSingle("workflow_criteria", workflowCriteria);
}

/**
 * Update a single workflow criteria
 * @param id number | string
 * @param workflowCriteria { [key: string]: string | number | boolean | Date | undefined}
 * @returns Promise<{ workflow_criteria: WorkflowCriteria } | Error>
 */
function updateSingle(
  id: number | string,
  workflowCriteria: {
    [key: string]: string | number | boolean | Date | undefined;
  },
): Promise<{ workflow_criteria: WorkflowCriteria } | Error> {
  return genericUpdateSingle("workflow_criteria", id, workflowCriteria);
}

/**
 * Delete a single workflow criteria
 * @param id number | string
 * @returns Promise<{ deleted: boolean } | { error: string }>
 */
function deleteSingle(
  id: number | string,
): Promise<{ deleted: boolean } | { error: string }> {
  return genericDeleteSingle("workflow_criteria", id);
}

/**
 * Gets the count of workflow criteria
 * @param params URLSearchParams
 * @returns Promise<{ count: number }>
 */
function getCount(params: URLSearchParams = null): Promise<{ count: number }> {
  params.append("countOnly", "true");
  return genericGetAll("workflowCriteria", params);
}

/**
 * Gets the count of workflow criteria for a workflow
 * @param workflowId Workflow ID
 * @param params URLSearchParams
 */
function getCountByWorkflow(
  workflowId: number | string,
  params: URLSearchParams = null,
) {
  params.append("countOnly", "true");
  return getByWorkflow(workflowId, params);
}

export const workflowCriteriaService = {
  getAll,
  getSingle,
  createSingle,
  updateSingle,
  deleteSingle,
  getByWorkflow,
  getCount,
  getCountByWorkflow,
};
