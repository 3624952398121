import React, { useCallback } from "react";
import { Formik, FormikHelpers, FormikProps, FormikValues } from "formik";
import { Row, Col } from "antd";
import { Form, Select, SubmitButton, InputNumber } from "formik-antd";
import { materialService } from "/app/src/services";
import { useTranslation } from "react-i18next";
import { Material } from "/app/src/models";
import { newOrderLineSchema } from "/app/src/schemas/apps/orderBuilder/newOrderLineSchema";
import { SearchFilter } from "/app/src/components/generic/components/searchFilter";
import { useQuery } from "@tanstack/react-query";
import { OrderBuilderLine } from "./orderLine/type";

export default function NewOrderLine({
  addLine,
}: {
  addLine: (line: OrderBuilderLine) => void;
}) {
  const { t } = useTranslation();

  const { data: materials } = useQuery({
    queryKey: ["materials"],
    queryFn: () => {
      return materialService.getAll();
    },
    initialData: { materials: [] },
    select: (data: { materials: Material[] }) => data.materials,
  });

  const onSubmitHandler = useCallback(
    (values: FormikValues, actions: FormikHelpers<FormikValues>) => {
      addLine({
        materialId: values.materialId,
        quantity: values.quantity,
      });
      actions.resetForm();
    },
    [addLine],
  );

  /**
   * Map materials to select options
   * @param c material
   */
  const mapMaterials = useCallback((c: Material) => {
    return (
      <Select.Option key={c.id} value={c.id}>
        {c.name}
        {c.Info1 !== null ? ` - ${c.Info1}` : ""}
      </Select.Option>
    );
  }, []);

  const newOrderLineForm: (props: FormikProps<FormikValues>) => JSX.Element =
    useCallback(
      ({ dirty, isValid }) => (
        <Form layout="horizontal">
          <Row justify="start" gutter={16}>
            <Col span={16}>
              <Form.Item name="materialId">
                <SearchFilter
                  name="materialId"
                  placeholder={t("translation:find_material")}
                  mapOptionsFn={mapMaterials}
                  list={materials}
                  sort={false}
                  strictFilter
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item name="quantity" hasFeedback={false}>
                <InputNumber name="quantity" placeholder="" size="large" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <SubmitButton
                type="primary"
                size="large"
                block
                disabled={!(dirty && isValid)}
              >
                {t("translation:add")}
              </SubmitButton>
            </Col>
          </Row>
        </Form>
      ),
      [t, mapMaterials, materials],
    );

  return (
    <div className="newOrderLine">
      <Formik
        component={newOrderLineForm}
        enableReinitialize
        initialValues={{
          quantity: 1,
          materialId: "",
        }}
        validationSchema={newOrderLineSchema}
        onSubmit={onSubmitHandler}
      />
    </div>
  );
}
