import { genericGetSingle, genericGetAll } from "../helpers/apiCall";
import { Zone } from "../models";

/**
 * Gets all zones
 * @param params URLSearchParams
 * @returns { Promise<{ zones: Zone[] }>
 */
function getAll(params: URLSearchParams = null): Promise<{ zones: Zone[] }> {
  return genericGetAll("zones", params);
}

/**
 * Gets a single zone
 * @param zoneId string
 * @returns Promise<{ zone: Zone }>
 */
function getSingle(zoneId: string): Promise<{ zone: Zone }> {
  return genericGetSingle("zones", zoneId);
}

/**
 * Gets the count of zones
 * @param params URLSearchParams
 * @returns Promise<{ count: number }>
 */
function getCount(params: URLSearchParams = null): Promise<{ count: number }> {
  params.append("countOnly", "true");
  return genericGetAll("zones", params);
}

export const zoneService = {
  getAll,
  getSingle,
  getCount,
};
