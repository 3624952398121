import { ResponsiveCalendar } from "@nivo/calendar";
import { useMemo } from "react";
import { CalendarChartData } from "./types";

/**
 * Component to display a Nivo Calendar Chart
 * @param data the data to display
 */
export default function CalendarChart({ data }: { data: CalendarChartData }) {
  const normalizedData = useMemo(
    () => data.data.filter((datum) => datum.value !== 0),
    [data.data],
  );
  return (
    <div className="chart time">
      <ResponsiveCalendar
        data={normalizedData}
        from={data.data[0].day}
        to={data.data[data.data.length - 1].day}
        emptyColor="#eeeeee"
        colors={["#b3cde0", "#6497b1", "#005b96", "#03396c", "#011f4b"]}
        margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
        yearSpacing={40}
        monthBorderColor="#ffffff"
        dayBorderWidth={2}
        dayBorderColor="#ffffff"
        legends={[
          {
            anchor: "bottom-right",
            direction: "row",
            translateY: 36,
            itemCount: 4,
            itemWidth: 42,
            itemHeight: 36,
            itemsSpacing: 14,
            itemDirection: "right-to-left",
          },
        ]}
      />
    </div>
  );
}
