import NewDataset from "./newDataset";
import Dataset from "./dataset";
import { snapshotService, widgetDatasetService } from "/app/src/services";
import { Widget } from "/app/src/models";
import { useQuery } from "@tanstack/react-query";
import { Spin } from "antd";

export default function Datasets({ widget }: { widget: Widget }) {
  const { data: datasets, isFetching } = useQuery({
    queryKey: ["widgetDatasets", widget.id],
    queryFn: () => {
      return widgetDatasetService.getAllFromWidget(widget.id);
    },
    select: (response) => {
      return response.widget_datasets;
    },
    initialData: { widget_datasets: [] },
  });
  const { data: snapshots } = useQuery({
    queryKey: ["snapshots"],
    queryFn: () => {
      return snapshotService.getAll();
    },
    select: (response) => {
      return response.snapshots;
    },
    placeholderData: { snapshots: [] },
    keepPreviousData: true,
    staleTime: 10 * 1000,
  });
  return (
    <div className="editDatasets">
      <div className="datasetList">
        {isFetching ? (
          <Spin />
        ) : (
          <>
            {datasets.map((d) => (
              <Dataset key={d.id} dataset={d} />
            ))}
            <NewDataset widget={widget} snapshots={snapshots} />
          </>
        )}
      </div>
    </div>
  );
}
