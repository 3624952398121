import React, { useEffect, useState, useCallback } from "react";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { Row, Col } from "antd";
import { Form, Input } from "formik-antd";
import { useTranslation } from "react-i18next";
import { Order } from "/app/src/models";
import { orderService, transactionService } from "/app/src/services";
import { buildParams } from "/app/src/helpers";

interface FormValues {
  handlingUnit: string;
}

export default function DivertTool() {
  const { t } = useTranslation();
  const [handlingUnit, sethandlingUnit] = useState("");
  const [order, setOrder] = useState<Order>({ storageUnits: [] });
  const [storageUnit, setStorageUnit] = useState("N/A");

  // Get API data
  useEffect(() => {
    if (handlingUnit) {
      setOrder({});
      orderService
        .getAll(buildParams({ state: "queued", handlingUnit: handlingUnit }))
        .then((response) => {
          if (response.orders?.length) {
            setOrder(response.orders[0]);
          } else {
            transactionService
              .getAll(
                buildParams({
                  handlingUnit: handlingUnit,
                  orderby: "creationDate:desc",
                }),
              )
              .then((transactionResponse) => {
                if (transactionResponse.transactions[0]) {
                  orderService
                    .getAll(
                      buildParams({
                        state: "processed",
                        name: transactionResponse.transactions[0].orderName,
                      }),
                    )
                    .then((orderResponse) => {
                      if (orderResponse[0]) {
                        setOrder(response.orders[0]);
                      }
                    });
                }
              });
          }
        });
    }
  }, [handlingUnit]);

  // Get Order logic from SCS
  useEffect(() => {
    if (order) {
      if (order.hasStock === 0 && order.SpecialIncomplete === "TRUE") {
        setStorageUnit("P");
      } else if (
        order.storageUnits &&
        (order.storageUnits.find((u) => u.name === "V1") ||
          order.storageUnits.find((u) => u.name === "V2"))
      ) {
        setStorageUnit("1");
      } else if (
        order.storageUnits &&
        (order.storageUnits.find((u) => u.name === "V3") ||
          order.storageUnits.find((u) => u.name === "V4"))
      ) {
        setStorageUnit("2");
      } else if (order.storageUnits?.find((u) => u.name === "V5")) {
        setStorageUnit("3");
      } else if (order.Info3 === "False") {
        setStorageUnit("S");
      } else if (order.Info3 === "True") {
        setStorageUnit("P");
      }
    } else {
      setStorageUnit("N/A");
    }
  }, [order]);

  const divertToolForm: (props: FormikProps<FormValues>) => JSX.Element =
    useCallback(
      () => (
        <Form layout="vertical">
          <Form.Item name="handlingUnit" label={t("translation:handling_unit")}>
            <Input name="handlingUnit" className="handlingUnit" size="large" />
          </Form.Item>
        </Form>
      ),
      [t],
    );

  const onSubmitHandler = useCallback(
    (values: FormValues, actions: FormikHelpers<FormValues>) => {
      sethandlingUnit(values.handlingUnit);
      actions.resetForm();
    },
    [sethandlingUnit],
  );

  return (
    <div className="display divertTool">
      <Row>
        <Col span={8}>
          <div className="handlingUnitForm">
            <Formik
              component={divertToolForm}
              initialValues={{
                handlingUnit: "",
              }}
              onSubmit={onSubmitHandler}
            />
          </div>
          {order && (
            <div>
              <div className="orderName">
                <h2>{t("translation:order_name")}</h2>
                <span>{order.name}</span>
              </div>
              <div className="storageUnits">
                <h2>{t("translation:storage_units")}</h2>
                {order.storageUnits?.map((unit) => {
                  return <span key={unit.id}>{unit.name}</span>;
                })}
              </div>
            </div>
          )}
        </Col>
        <Col span={16}>
          <div className={`storageUnitInfo color${storageUnit}`}>
            <h1>{storageUnit}</h1>
          </div>
        </Col>
      </Row>
    </div>
  );
}
