import {
  makeAPIRequest,
  genericGetSingle,
  genericCreateSingle,
  genericDeleteSingle,
  genericGetAll,
  genericUpdateSingle,
} from "../helpers/apiCall";
import { File } from "../models";
import { Error } from "../types";

/**
 * Get all files
 * @param params URLSearchParams
 * @returns Promise<{ files: File[] }>
 */
function getAll(params: URLSearchParams = null): Promise<{ files: File[] }> {
  return genericGetAll("files", params);
}

/**
 * Get a single file
 * @param id number | string
 * @returns Promise<{ file: File }>
 */
function getSingle(id: number | string): Promise<{ file: File }> {
  return genericGetSingle("files", id);
}

/**
 * Update a single file
 * @param id: number | string
 * @param file { [key: string]: string | number | boolean | Date | undefined }
 * @returns Promise<{ file: File }>
 */
function updateSingle(
  id: number | string,
  file: { [key: string]: string | number | boolean | Date | undefined },
): Promise<{ file: File } | Error> {
  return genericUpdateSingle("files", id, file);
}

/**
 * Create a single file
 * @param file { [key: string]: string | number | boolean | Date | undefined }
 * @returns Promise<{ file: File }>
 */
function createSingle(file: {
  [key: string]: string | number | boolean | Date | undefined;
}): Promise<{ file: File }> {
  return genericCreateSingle("files", file);
}

/**
 * Delete a single file
 * @param id number | string
 * @returns Promise<{ file: File }>
 */
function deleteSingle(id: number | string): Promise<{ file: File }> {
  return genericDeleteSingle("files", id);
}

/**
 * download a single file
 * @param id number | string
 * @param buffer boolean
 * @returns Promise<any>
 */
function downloadFile(id: number | string, buffer = false): Promise<any> {
  return makeAPIRequest({
    url: `/files/${id}/download`,
    method: "get",
    ...(buffer && { responseType: "arraybuffer" }),
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

/**
 * Gets the count of files
 * @param params URLSearchParams
 * @returns Promise<{ count: number }>
 */
function getCount(params: URLSearchParams = null): Promise<{ count: number }> {
  params.append("countOnly", "true");
  return genericGetAll("files", params);
}

export const fileService = {
  getAll,
  getSingle,
  updateSingle,
  createSingle,
  deleteSingle,
  downloadFile,
  getCount,
};
