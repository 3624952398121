import { string, number, object, mixed } from "yup";

import i18next from "/app/src/locales/config";

export const triggerSchema = object().shape({
  triggerFrequency: string().required(i18next.t("translation:required")),

  triggerInterval: number().required(i18next.t("translation:required")),

  triggerTime: string().when("triggerFrequency", {
    is: (frequency) =>
      frequency === "month" || frequency === "week" || frequency === "day",
    then: (schema) => schema.required(i18next.t("translation:required")),
    otherwise: (schema) => schema.nullable(),
  }),
  triggerNumber: mixed()
    .when("triggerFrequency", {
      is: (frequency) => ["week", "month"].includes(frequency),
      then: (schema) =>
        schema.nonNullable().required(i18next.t("translation:required")),
      otherwise: (schema) => schema.nullable(),
    })
    .transform((value) => {
      if (Array.isArray(value) && value.length === 0) {
        return null;
      }
      return value;
    }),
});
