import { ComputedDatum, ResponsivePie } from "@nivo/pie";
import { useCallback } from "react";
import { PieChartData, PieDatum } from "./types";

/**
 * Component to display a Nivo Pie Chart
 * @param data the data to display
 */
export default function DashboardPieChart({ data }: { data: PieChartData }) {
  const getColor = useCallback((pie: ComputedDatum<PieDatum>) => {
    return pie.data.color;
  }, []);
  return (
    <div className="chart pie">
      <ResponsivePie
        data={data.data}
        colors={
          data.groupingType === "grouped" ? { scheme: "paired" } : getColor
        }
        margin={{ top: 20, right: 0, bottom: 50, left: 0 }}
        borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
        enableArcLinkLabels={false}
        activeOuterRadiusOffset={8}
        legends={[
          {
            anchor: "top-right",
            direction: "column",
            justify: false,
            translateX: 0,
            translateY: 0,
            itemWidth: 100,
            itemHeight: 20,
            itemsSpacing: 0,
            symbolSize: 20,
            itemDirection: "left-to-right",
          },
        ]}
      />
    </div>
  );
}
