import React, { useCallback } from "react";
import { Row, Col, Input, DatePicker } from "antd";
import { useTranslation } from "react-i18next";
import { handleRangePickerChange } from "/app/src/helpers/time";

const { RangePicker } = DatePicker;
type RangeValue = Parameters<
  NonNullable<React.ComponentProps<typeof DatePicker.RangePicker>["onChange"]>
>[0];

/**
 * Component for displaying the controls for the logs table
 * Controls are search, date range, and status
 */
export default function Controls({
  setSearchString,
  setAfterTime,
  setBeforeTime,
}: {
  setSearchString: (searchString: string) => void;
  setAfterTime: (time: string) => void;
  setBeforeTime: (time: string) => void;
}) {
  const { t } = useTranslation();
  //handler for user changing the range of dates
  const onOk = useCallback(
    (value: RangeValue) => {
      handleRangePickerChange(value, setBeforeTime, setAfterTime);
    },
    [setAfterTime, setBeforeTime],
  );
  const handleSearch = useCallback(
    (value) => {
      setSearchString(value);
    },
    [setSearchString],
  );
  const onRangeChange = useCallback(
    (value) => {
      onOk(value);
    },
    [onOk],
  );
  return (
    <Row
      gutter={[10, 10]}
      style={{ paddingBottom: "10px", paddingTop: "10px" }}
    >
      <Col
        xs={{ span: 24, order: 1 }}
        sm={{ span: 24, order: 1 }}
        md={{ span: 8, order: 1 }}
        lg={{ span: 6, order: 1 }}
      >
        <Input.Search
          onSearch={handleSearch}
          size="large"
          placeholder={t("translation:search")}
        />
      </Col>
      <Col
        xs={{ span: 24, order: 1 }}
        sm={{ span: 24, order: 1 }}
        md={{ span: 8, order: 1 }}
        lg={{ span: 6, order: 1 }}
      >
        <RangePicker
          allowClear
          allowEmpty={[true, true]}
          showTime={{ format: "HH:mm" }}
          format="YYYY-MM-DD HH:mm"
          onChange={onRangeChange}
          size="large"
        />
      </Col>
    </Row>
  );
}
