import { useCallback } from "react";
import { Formik, FormikHelpers } from "formik";
import { newOrderSchema } from "/app/src/schemas";
import { Order, Warehouse } from "/app/src/models";
import { useQuery } from "@tanstack/react-query";
import { settingService, warehouseService } from "/app/src/services";
import { buildParams } from "/app/src/helpers";
import OrderHeaderForm from "./orderHeaderForm";

interface FormValues {
  name: string;
  type: string;
  priority: "High" | "Medium" | "Low" | "Hot";
  deadline: string;
  Info1: string;
  Info2: string;
  warehouseName: string;
}

export default function OrderHeader({
  submit,
}: {
  submit: (
    order: Order,
    setFieldError: (field: string, message: string | undefined) => void,
  ) => Promise<any>;
}) {
  //useQuery for getting the default warehouse
  const { data: defaultWarehouse } = useQuery({
    queryKey: ["defaultWarehouseOrder"],
    queryFn: () => {
      return settingService
        .getAll(buildParams({ number: 106 }))
        .then((response) => {
          return response.settings[0];
        });
    },
  });

  //useQuery for getting the list of warehouses
  const { data: warehouses } = useQuery({
    queryKey: ["warehouses"],
    queryFn: () => {
      return warehouseService.getAll();
    },
    initialData: { warehouses: [] },
    select: (data: { warehouses: Warehouse[] }) => {
      return data.warehouses;
    },
  });

  const onSubmitHandler = useCallback(
    async (values: FormValues, actions: FormikHelpers<FormValues>) => {
      if (values.deadline === "") {
        delete values.deadline;
      }
      await submit(values, actions.setFieldError);
    },
    [submit],
  );

  return (
    <div className="newOrder box">
      <Formik
        initialValues={{
          name: "",
          type: "Pick",
          priority: "Medium",
          deadline: "",
          Info1: "",
          Info2: "",
          warehouseName: defaultWarehouse ? defaultWarehouse.value : "",
        }}
        enableReinitialize
        validationSchema={newOrderSchema}
        onSubmit={onSubmitHandler}
        validateOnChange={false}
      >
        <OrderHeaderForm warehouses={warehouses} />
      </Formik>
    </div>
  );
}
