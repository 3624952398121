import DeleteButton from "./buttons/DeleteButton";

/**
 *
 * @param param0 title, deleteFn, modalHeader
 * @returns PageHeader component
 */
export default function PageHeader({
  title,
  deleteFn,
  modalHeader,
}: {
  title: React.ReactNode;
  deleteFn: () => void;
  modalHeader: string;
}) {
  return (
    <div className="flex justify-between pb-2">
      <div className="text-base font-bold">{title}</div>
      <DeleteButton modalHeader={modalHeader} onClick={deleteFn} />
    </div>
  );
}
