import { iLine } from "/app/src/models";

import { useCallback } from "react";
import { Row, Col } from "antd";
import { Form, SubmitButton, Input } from "formik-antd";
import { Formik, FormikProps } from "formik";
import { useTranslation } from "react-i18next";
import { iLineService } from "/app/src/services";
import { useQueryClient } from "@tanstack/react-query";
import Box from "/app/src/components/generic/components/box";

interface FormValues {
  quantity?: number;
  material?: string;
}

/**
 * Formats the form values to be sent to the backend API
 */
function formatForm(values: FormValues) {
  const ret = {
    quantity: values.quantity,
    material: values.material,
  };
  //json stringify the object
  return { data: JSON.stringify(ret) };
}

/**
 * Displays the details of a line in an order created through the Order Builder App
 */
export default function Line({ line }: { line: iLine }) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const handleUpdate = useCallback(
    async (values: FormValues) => {
      await iLineService.updateSingle(line.id, formatForm(values)).then(() => {
        queryClient.invalidateQueries(["order", line.orderId]);
      });
    },
    [line.id, line.orderId, queryClient],
  );
  const lineForm: (props: FormikProps<FormValues>) => JSX.Element = useCallback(
    ({ dirty, isValid }) => (
      <Form layout="vertical">
        <Row justify="start" gutter={16}>
          <Col span={4}>
            <Form.Item name="quantity" label={t("translation:quantity")}>
              <Input suffix name="quantity" size="large" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="materialId"
              hasFeedback={false}
              label={t("translation:material")}
            >
              <Input name="material" suffix size="large" />
            </Form.Item>
          </Col>
          <Col span={4} offset={8}>
            <SubmitButton
              style={{ marginTop: "30px" }}
              type="primary"
              size="large"
              block
              disabled={!(dirty && isValid)}
            >
              {t("translation:save")}
            </SubmitButton>
          </Col>
        </Row>
      </Form>
    ),
    [t],
  );
  const getInitValues = useCallback(() => {
    let lineData: FormValues = {};
    try {
      lineData = JSON.parse(line.data);
      if (typeof lineData === "string") {
        lineData = JSON.parse(lineData);
      }
    } catch (e) {
      lineData = {};
    }
    return {
      material: lineData?.material,
      quantity: lineData?.quantity,
    };
  }, [line]);
  return (
    <Box>
      <div className="newIntegration">
        <Formik
          enableReinitialize
          component={lineForm}
          initialValues={getInitValues()}
          onSubmit={handleUpdate}
        />
      </div>
    </Box>
  );
}
