import { Provider, ErrorBoundary } from "@rollbar/react";
import getEnvironment from "./helpers/environment";

/**
 * Wraps the application with Rollbar. Configures the Rollbar provider with the
 * access token pulled from environment
 */
export default function RollbarWrapper({
  children,
}: {
  children: JSX.Element;
}) {
  // Rollbar does not currently have typescript support
  const rollbarConfig = {
    accessToken: getEnvironment().REACT_APP_ROLLBAR_TOKEN,
    environment: process.env.NODE_ENV,
    enabled: false,
    verbose: true, //console log errors that get sent to rollbar
    ignoredMessages: ["BAD REQUEST", "Failed to fetch"],
  };
  return (
    <Provider config={rollbarConfig}>
      <ErrorBoundary>{children}</ErrorBoundary>
    </Provider>
  );
}
