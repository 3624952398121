import { object, string } from "yup";
import i18next from "/app/src/locales/config";
import { phoneNumberValidation } from "/app/src/schemas/regularExpressions/regexPatterns";

export const smsSchema = object().shape({
  to: string()
    .required(i18next.t("translation:phone_required"))
    .matches(phoneNumberValidation, {
      message: "Please enter valid number.",
      excludeEmptyString: false,
    }),
  message: string().required(i18next.t("translation:text_message_required")),
});
