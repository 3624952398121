import { useCallback } from "react";
import DeleteButtonPopup from "/app/src/components/generic/components/buttons/DeleteButtonPopup";

/**
 * @param param id: number, refresh: () => void
 * @returns Delete Button JSX.Element
 */
export default function DeleteButtonCell({
  id,
  refresh,
  deleteFn,
}: {
  id: number;
  refresh: () => void;
  deleteFn: (id: number) => Promise<any>;
}) {
  const deleteRecord = useCallback(() => {
    return deleteFn(id);
  }, [deleteFn, id]);

  return <DeleteButtonPopup deleteObjectFn={deleteRecord} refresh={refresh} />;
}
