import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { Helmet } from "react-helmet";
import { Row, Col, message } from "antd";
import { Formik, FormikProps } from "formik";
import { Form, SubmitButton, Input } from "formik-antd";
import { licenseSchema } from "/app/src/schemas";
import { useTranslation } from "react-i18next";
import { loginUser, useAuthDispatch } from "/app/src/contexts/authentication";
import { makeAPIRequest } from "../../helpers";
interface FormValues {
  username: string;
  password: string;
  license: string;
}

/**
 * Component for creating the first user. Also includes fields for the license and recovery key
 */
export default function License() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAuthDispatch(); //get the dispatch method from the useDispatch custom hook

  const handleSubmit = useCallback(
    (values, actions) => {
      loginUser(
        dispatch,
        values.username,
        values.password,
        actions.setSubmitting,
      ).then((response) => {
        if (response?.login !== false) {
          //get access token from responsen and add
          makeAPIRequest({
            url: "/settings",
            body: { license: values.license },
            method: "put",
            headers: { Authorization: `Bearer ${response.accessToken}` },
          }).then((resp) => {
            if (resp.data?.licenseUpdated === false) {
              actions.setFieldError("license", resp.data.error);
              actions.setSubmitting(false);
            } else {
              message.success(t("translation:license_updated"));
              navigate("/login");
            }
          });
        } else {
          actions.setFieldError("username", "Invalid username or password");
        }
      });
    },
    [dispatch, navigate, t],
  );

  /**
   * Form for formik component - contains all the fields for the new user form
   */
  const updateLicenseForm: (props: FormikProps<FormValues>) => JSX.Element =
    useCallback(
      ({ errors, touched, isValid, dirty }) => (
        <Form layout="vertical">
          <Form.Item name="username" label={t("translation:username")}>
            <Input
              suffix
              name="username"
              size="large"
              className={
                errors.username && touched.username ? "input-error" : undefined
              }
            />
          </Form.Item>
          <Form.Item name="password" label={t("translation:password")}>
            <Input.Password
              name="password"
              size="large"
              className={
                errors.password && touched.password ? "input-error" : undefined
              }
            />
          </Form.Item>
          <Form.Item name="license" label={t("translation:license")}>
            <Input suffix name="license" size="large" />
          </Form.Item>
          <SubmitButton
            type="primary"
            size="large"
            disabled={!(dirty && isValid)}
          >
            {t("translation:update_license")}
          </SubmitButton>
        </Form>
      ),
      [t],
    );

  return (
    <div className="login">
      <Helmet>
        <title>{t("translation:update_license")} - ItemPath</title>
      </Helmet>
      <Row>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 20, offset: 2 }}
          lg={{ span: 8, offset: 8 }}
        >
          <div className="box mt-4">
            <h3 style={{ textAlign: "center", margin: "20px 0 30px" }}>
              {t("translation:update_license")}
            </h3>
            <Formik
              enableReinitialize
              component={updateLicenseForm}
              validationSchema={licenseSchema}
              initialValues={{
                username: "",
                password: "",
                license: "",
              }}
              onSubmit={handleSubmit}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
}
