import {
  makeAPIRequest,
  genericGetSingle,
  genericCreateSingle,
  genericDeleteSingle,
  genericGetAll,
  genericUpdateSingle,
} from "../helpers/apiCall";
import { WorkflowAction } from "../models";
import { Error } from "../types";

/**
 * Gets all workflow_actions
 * @param workflowId number | string
 * @param params URLSearchParams
 * @returns Promise<{ workflow_actions: WorkflowAction[] }>
 */
function getAll(
  workflowId: number | string,
  params: URLSearchParams = null,
): Promise<{ workflow_actions: WorkflowAction[] }> {
  return makeAPIRequest({
    url: `/workflows/${workflowId}/workflow_actions`,
    params,
    method: "get",
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

/**
 * Gets a single workflow_action
 * @param id number | string
 * @returns Promise<{ workflow_action: WorkflowAction }>
 */
function getSingle(
  id: number | string,
): Promise<{ workflow_action: WorkflowAction }> {
  return genericGetSingle("workflow_actions", id);
}

/**
 * Create a new workflow_action
 * @param workflowAction { [key: string]: string | number | boolean | Date | undefined}
 * @returns Promise<{ workflow_action: WorkflowAction } | Error>
 */
function createSingle(workflowAction: {
  [key: string]: string | number | boolean | Date | undefined;
}): Promise<{ workflow_action: WorkflowAction } | Error> {
  return genericCreateSingle("workflow_actions", workflowAction);
}

/**
 * Update a single workflow_action
 * @param id number | string
 * @param workflowAction { [key: string]: string | number | boolean | Date | undefined}
 * @returns Promise<{ workflow_action: WorkflowAction } | Error>
 */
function updateSingle(
  id: number | string,
  workflowAction: {
    [key: string]: string | number | boolean | Date | undefined;
  },
): Promise<{ workflow_action: WorkflowAction } | Error> {
  return genericUpdateSingle("workflow_actions", id, workflowAction);
}

/**
 * Delete a single workflow_action
 * @param id number | string
 * @returns Promise<{ deleted: boolean } | { error: string }>
 */
function deleteSingle(
  id: number | string,
): Promise<{ deleted: boolean } | { error: string }> {
  return genericDeleteSingle("workflow_actions", id);
}

/**
 * Gets the count of workflow_actions
 * @param workflowId Workflow ID
 * @param params URLSearchParams
 * @returns Promise<{ count: number }>
 */
function getCount(params: URLSearchParams = null): Promise<{ count: number }> {
  params.append("countOnly", "true");
  return genericGetAll("workflows", params);
}

export const workflowActionService = {
  getAll,
  getSingle,
  createSingle,
  updateSingle,
  deleteSingle,
  getCount,
};
