import { Integration } from "/app/src/models";
import Box from "/app/src/components/generic/components/box";
import ConnectionDetails from "./connectionDetails";
import NextDivider from "/app/src/components/NextUi/Divider";
import SQLFiltering from "./sqlFiltering";

/**
 *
 * @param param0 integration
 * @returns Variance connection Settings
 */
export default function Connections({
  integration,
}: {
  integration: Integration;
}) {
  return (
    <Box>
      <ConnectionDetails integration={integration} />
      <NextDivider className="mb-3" />
      <SQLFiltering integration={integration} />
    </Box>
  );
}
