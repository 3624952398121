import { useState } from "react";
import { SortUpgrade } from "../types/sortUpgrade";

//src code: https://www.joshwcomeau.com/snippets/react-hooks/use-toggle/
export function useSortUpgrade(
  initialValue: { id: string; desc: boolean }[] = [],
) {
  const [sort, setSort] = useState<SortUpgrade>(initialValue);

  return [sort, setSort] as const; // https://bobbyhadz.com/blog/typescript-not-all-constituents-of-type-are-callable
}
